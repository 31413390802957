import Axios from "axios";
import { saveMsg, updateMsg, failedMsg } from "../../erpUtil";

import {
  GET_PBL_STUDENT_INFO,
  SET_ADMIN_FORM_FILLUP_SUBJECT,
  UN_SET_ADMIN_FORM_FILLUP_SUBJECT,
  GET_SPECIAL_FORM_FILL_UP_DETAILS,
  UNSET_SPECIAL_FORM_FILL_UP_DETAILS,
  GET_SPECIAL_FORM_FILL_UP_LIST,
  UNSET_SPECIAL_FORM_FILL_UP_LIST
} from "../../mutation-types";

import {
  getPblStudent,
  adminFormFillupSubject,
  formFillUpByAdminUrl,
  getFormFillUpStudentConfirmedDetailsAdminUrl,
  specialFormFillUpByAdminList,
  cancelConfirmationAdmin
} from "../../api";

const state = {
  studentInfo: {},
  schema: {
    form_fill_up_id: null,
    confirm_subjects: [],
    student_info: {
      mobile: null
    }
  },
  admin_form_fill_up_subject: [],
  student_form_fill_up_confirmed_data: {},
  student_form_fill_up_fee_history: {},
  special_form_fill_up_list: {}
};

const getters = {
  getPblStudentInfo(state) {
    return state.studentInfo;
  },
  getAdminFormFillUpSubject(state) {
    return state.admin_form_fill_up_subject;
  },
  formFillUpConfirmByAdmin(state) {
    return state.schema;
  },
  formFillUpByAdminList(state) {
    return state.special_form_fill_up_list;
  }
};

const mutations = {
  [GET_PBL_STUDENT_INFO](state, result) {
    state.studentInfo = result;
  },
  [SET_ADMIN_FORM_FILLUP_SUBJECT](state, result) {
    state.admin_form_fill_up_subject = result;
  },
  [UN_SET_ADMIN_FORM_FILLUP_SUBJECT](state) {
    state.admin_form_fill_up_subject = [];
  },
  [GET_SPECIAL_FORM_FILL_UP_DETAILS](state, data) {
    state.student_form_fill_up_confirmed_data = data;
  },
  [GET_SPECIAL_FORM_FILL_UP_DETAILS](state) {
    state.student_form_fill_up_confirmed_data = {};
  },
  [GET_SPECIAL_FORM_FILL_UP_LIST](state, data) {
    state.special_form_fill_up_list = data;
  },
  [UNSET_SPECIAL_FORM_FILL_UP_LIST](state) {
    state.special_form_fill_up_list = {};
  },
};

const actions = {
  getPblStudentInfo({ commit }, schema) {
    commit("UN_SET_ADMIN_FORM_FILLUP_SUBJECT");
    let data = schema;
    return new Promise(function(resolve, reject) {
      commit("SET_LOADING", { color: "primary" });
      Axios.get(getPblStudent, { params: data })
        .then(function(response) {
          console.log(response);
          let result = response.data.data;
          commit("GET_PBL_STUDENT_INFO", result);
          resolve(response.data);
          commit("UNSET_LOADING");
        })
        .catch(function(error) {
          commit("SET_SNACKBAR", {
            msg: error.response.data.message,
            color: "red"
          });
          commit("GET_PBL_STUDENT_INFO");
          commit("UNSET_LOADING");
          //reject();
        });
    });
  },
  getAdminFormFillUpSubject({ commit, state }, schema) {
    // const self = this
    let data = schema;
    // console.log(url)
    return new Promise(function(resolve, reject) {
      commit("SET_LOADING", { color: "primary" });
      //commit("UN_SET_ADMIN_FORM_FILLUP_SUBJECT");
      Axios.get(adminFormFillupSubject, { params: data })
        .then(function(response) {
          let result = response.data.data;
          localStorage.setItem(
            "admin_form_fill_up_subject",
            JSON.stringify(response.data)
          );
          commit("SET_ADMIN_FORM_FILLUP_SUBJECT", result);
          //commit("UN_SET_ADMIN_FORM_FILLUP_SUBJECT");

          resolve(response.data.data);
        })
        .catch(function(error) {
          commit("UN_SET_ADMIN_FORM_FILLUP_SUBJECT");
          localStorage.removeItem("admin_form_fill_up_subject");
          reject();
        })
        .then(always => {
          commit("UNSET_LOADING");
        });
    });
  },
  getFormFillUpStudentConfirmedDetailsAdmin({ commit, state }, form_fill_up_id) {
    let url = `${getFormFillUpStudentConfirmedDetailsAdminUrl}/${form_fill_up_id}`;
    var params = new URLSearchParams();
    params.append("form_fill_up_id", form_fill_up_id);
    return new Promise(function(resolve, reject) {
      commit("SET_LOADING", { color: "primary" });
      Axios.get(url, params)
        .then(function(response) {
          commit(
            "GET_SPECIAL_FORM_FILL_UP_DETAILS",
            response.data.data
          );
          resolve();
        })
        .catch(function(error) {
          commit("UNSET_SPECIAL_FORM_FILL_UP_DETAILS");
          reject();
        })
        .then(always => {
          commit("UNSET_LOADING");
        });
    });
  },
  formFillUpConfirmAdmin({ commit, state }, formData) {
    let url = `${formFillUpByAdminUrl}`;
    // console.log(formData)
    return new Promise(function(resolve, reject) {
      commit("SET_LOADING", { color: "primary" });
      Axios.post(url, formData)
        .then(function(response) {
          localStorage.removeItem("admin_form_fill_up_subject");
          if (response.data.status === "success") {
            commit("UN_SET_ADMIN_FORM_FILLUP_SUBJECT");
            commit("SET_SNACKBAR", {
              msg: saveMsg,
              color: "green"
            });
            resolve(response);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red"
            });
            reject(response);
          }
        })
        .catch(function(error) {
          reject();
        })
        .then(always => {
          commit("UNSET_LOADING");
        });
    });
  },
  fetchAllSpecialFormFillup({ commit, state }, searchParam) {
    let url = `${specialFormFillUpByAdminList}`;
    return new Promise(function(resolve, reject) {
      commit("SET_LOADING", { color: "primary" });
       Axios.post(url, searchParam)
        .then(function(response) {
          if (response.data.status === "success") {
            commit("GET_SPECIAL_FORM_FILL_UP_LIST",response.data);
            resolve(response);
          } else {

            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red"
            });
            reject(response);
          }
        })
        .catch(function(error) {
          commit("UNSET_SPECIAL_FORM_FILL_UP_LIST",[]);
          reject();
        })
        .then(always => {
          commit("UNSET_LOADING");
        });
    });
  },
  cancelConfirmationAdmin({ commit, state }, option) {
    let url = `${cancelConfirmationAdmin}`;
    return new Promise(function(resolve, reject) {
      commit("SET_LOADING", { color: "primary" });
      Axios.post(url, option)
        .then(function(response) {
          if (response.data.status === "success") {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red"
            });
            resolve(response);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red"
            });
            reject(response);
          }
        })
        .catch(function(error) {
          commit("UNSET_SPECIAL_FORM_FILL_UP_LIST",[]);
          reject();
        })
        .then(always => {
          commit("UNSET_LOADING");
        });
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
