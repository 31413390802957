import Axios from "axios";
import {
  getCollegePaymentSlipUrl,
  collegePaymentSlipDownloadPdfUrl,
  collegePaymentSlipDetails
} from "../../../api";
import {} from "../../../erpUtil";
import { SET_PAYMENT_SLIP, UNSET_PAYMENT_SLIP,SET_PAYMENT_SLIP_DETAILS, UNSET_PAYMENT_SLIP_DETAILS } from "../../../mutation-types";
const state = {
  schema: {
    exam_initiate_id: null,
    trxid:null
  },
  fee_summary: [],
  payment_slip: [],
  payment_slip_details: [],
};
const getters = {
  collegePaymentSlipSchema(state) {
    return state.schema;
  },
  getCollegePaymentSlip(state) {
    return state.payment_slip;
  },
  collegePaymentSlipDetails(state){
    return state.payment_slip_details;
  }
};
const mutations = {
  [SET_PAYMENT_SLIP](state, data) {
    state.payment_slip = data;
  },
  [UNSET_PAYMENT_SLIP](state) {
    state.payment_slip = [];
  },
  [SET_PAYMENT_SLIP_DETAILS](state, data) {
    state.payment_slip_details = data;
  },
  [UNSET_PAYMENT_SLIP_DETAILS](state) {
    state.payment_slip_details = [];
  }
};
const actions = {
  getCollegePaymentSlip({ commit, state }) {
    commit("SET_LOADING", {
      color: "primary"
    });
    var params = new URLSearchParams();
    params.append("exam_initiate_id", state.schema.exam_initiate_id);
    let url = getCollegePaymentSlipUrl;
    return new Promise((resolve, reject) => {
      Axios.get(url, { params: params })
        .then(response => {
          var result = response.data.data;
          commit("SET_PAYMENT_SLIP", result);
          resolve(response.data);
        })
        .catch(error => {
          commit("UNSET_PAYMENT_SLIP");
          reject();
        })
        .then(always => {
          commit("UNSET_LOADING");
        });
    });
  },
  collegePaymentSlipDownloadPdf({ commit, state }, data) {
    commit("SET_LOADING", {
      color: "primary"
    });
    var params = new URLSearchParams();
    let url = `${collegePaymentSlipDownloadPdfUrl}?trxid=${data.trxid}`;
    return new Promise((resolve, reject) => {
      Axios.get(url, data)
        .then(response => {
          // console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {})
        .then(always => {
          commit("UNSET_LOADING");
        });
    });
  },
  collegePaymentSlipDetails({ commit, state }, data) {
    commit("SET_LOADING", {
      color: "primary"
    });
    var params = new URLSearchParams();
    let url = `${collegePaymentSlipDetails}?trxid=${data.trxid}&exam_initiate_id=${data.exam_initiate_id}`;
    return new Promise((resolve, reject) => {
      Axios.get(url, data)
        .then(response => {
          var result = response.data.data;
          commit("SET_PAYMENT_SLIP_DETAILS", result);
          resolve(response)
        })
        .catch(error => {
          commit("UNSET_PAYMENT_SLIP_DETAILS");
        })
        .then(always => {
          commit("UNSET_LOADING");
        });
    });
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
