import { render, staticRenderFns } from "./layoutAdmin.vue?vue&type=template&id=7450f12d&scoped=true&"
import script from "./layoutAdmin.vue?vue&type=script&lang=js&"
export * from "./layoutAdmin.vue?vue&type=script&lang=js&"
import style0 from "./layoutAdmin.vue?vue&type=style&index=0&id=7450f12d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7450f12d",
  null
  
)

export default component.exports