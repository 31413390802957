<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <!-- loging -->

        <!-- <v-expansion-panel popout
        v-model="expansion">
          <v-expansion-panel-content>
            <template v-slot:header>
              <div>Login</div>
        </template>-->
        <v-card class="elevation-12 rounded">
          <v-toolbar color="primary" dark flat>
            <v-layout justify-start>
              <v-toolbar-title>Student Login</v-toolbar-title>
            </v-layout>
            <v-img
              justify-end
              wrap
              :src="require('@/assets/nu_logos/nu_logo_r.png')"
              contain
              height="100"
            ></v-img>
          </v-toolbar>
          <v-card-text class="pa-0 pt-3 pr-4 pl-3">
            <div class="alert error" style="display: none;" id="alert">
              <p
                class="text-md-center"
                id="alert-message"
                style="padding: 5px;color: white;"
              ></p>
            </div>
            <v-form>
              <v-text-field
                v-model="user.username"
                label="Registration Number"
                name="username"
                id="email"
                prepend-icon="person"
                type="text"
              ></v-text-field>
              <v-text-field style="display: none"
                v-model="user.password"
                id="password"
                label="Password"
                name="password"
                prepend-icon="lock"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-layout justify-center class="pb-2">
              <v-btn color="primary" @click="checkUser">
                <v-icon left dark>account_circle</v-icon>Submit
              </v-btn>
              <!-- <a @click="goForgetPassword" class="text-center mt-2 ml-3">
                Forget Password ?
              </a> -->
            </v-layout>
          </v-card-actions>
          <v-flex mb-4></v-flex>
        </v-card>
        <!-- </v-expansion-panel-content>
        </v-expansion-panel>-->

        <!-- <v-layout justify-center>
          <v-btn color="primary" outline @click="goStart">Start</v-btn>
        </v-layout>-->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      expansion: true,

      user: {
        password: "123456",
        username: ""
      }
    };
  },
  components: {},
  methods: {
    goStart() {
      this.$router.push("/");
    },
    goForgetPassword() {
      this.$router.push("/student-forget-password");
    },
    goHome() {
      this.$router.push("/student/dashboard");
    },
    checkUser() {
      var email = document.getElementById("email");
      var password = document.getElementById("password");
      if (email.value.trim() == "") {
        alert("'REG NO' is required");
      } else if (password.value.trim() == "") {
        alert("Password Field is required");
      } else {
        this.$store
          .dispatch("authenticateStudent", this.user)
          .then(() => {
            this.$router.push("/student/dashboard");
          })
          .catch((e) => {
            document.getElementById("alert").style.display = "block";
            document.getElementById("alert-message").innerHTML =
              "Invalid login info";
          });
      }
    },
    async recaptcha() {
      let vm = this
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('stdlogin').then((res) => {
        if (res) {
          vm.user.recaptchaToken = res;
          this.checkUser();
        }
      })
    }
  },
  mounted() {
    localStorage.clear();
  }
};
</script>

<style scoped>
.rounded {
  border-radius: 15px;
}
</style>
