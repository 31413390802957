import Axios from "axios";
import {
    postAttachment
} from "../../api";
import {GET_ATTACHMENTS_DATA,} from "../../mutation-types";

const state = {
atachmentsdATA : [],
};
const getters = {
  getatachments() {
    return state.atachmentsdATA;
  },
};
const mutations = {
  [GET_ATTACHMENTS_DATA](state, response) {
    return state.atachmentsdATA = response;
  },
};
const actions = {
    fetchAtachments({ commit }, searchParam) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", {
          color: "primary"
        });
        // loading  Opening
        Axios.get(postAttachment, {
          params: searchParam,
        })
          .then(function (response) {
            commit("UNSET_LOADING"); // loading Closing
            var result = response.data.data;
            console.log("Tanvir, this is Atachment list ->", result);
            commit("GET_ATTACHMENTS_DATA", result);
            resolve(response.data);
          })
          .catch(function (error) {
            commit("UNSET_LOADING"); // loading Closing
            console.log(error);
            reject();
          });
      });

    },
    attachmentsfile({ commit }, searchParam){
        console.log("This is from api", searchParam);
        commit("SET_ACTION_PROCESS");
        return new Promise((resolve, reject) => {
          commit("SET_LOADING", {
            color: "Primary"
          });
          const formData = new FormData();
            for (let i = 0; i < searchParam.files.length; i++) {
                formData.append(`files[${i}]`, searchParam.files[i]);
            }
            formData.append('exam_code', searchParam.exam_code);
            formData.append('exam_year', searchParam.exam_year);
            formData.append('exam_initiate_id', searchParam.exam_initiate_id);
            formData.append('center_code', searchParam.center_code);
            formData.append('subject', searchParam.subject);
            formData.append('description', searchParam.description);
            formData.append('user_type', searchParam.user_type);
          Axios.post(postAttachment, formData)
            .then((response) => {
              commit("UNSET_LOADING");
              if (response.data.status == "success") {
                console.log("Tanvir, Attachments saved successfully!!");
                commit("SET_SNACKBAR", {
                  msg: response.data.message,
                  color: "green"
                });
                commit("UNSET_FORMNOTIFY");
                resolve(response.data.message);
              } else {
                commit("SET_SNACKBAR", {
                  msg: response.data.message,
                  color: "red",
                  timeout: 10000
                });
                commit("UNSET_FORMNOTIFY", {
                  type: "error",
                  msg: response.data.message
                });
                reject(response.data.message);
              }
            })
            .catch((error) => {
              commit("UNSET_LOADING");
              commit("SET_SNACKBAR", {
                msg: "Attachments save not Success !!!",
                color: "red"
              });
              reject(error)
            });
        });
    }
};


export default {
    state,
    getters,
    actions,
    mutations
  };