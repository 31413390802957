import Axios from "axios";
import {dailyAttandancePresentListRoomWise} from "../../api";
import {GET_DAILY_PRESENT_LIST_ROOM_WISE , GET_DAILY_PRESENT_LIST_ROOM_WISE_PDF } from "../../mutation-types";


const state = {
    roomWisePresentListData: [],
    roomWisePresentListDataPDF: [],
};

const getters = {
    GetRoomWiseAttandanceListPresent(state){
        return state.roomWisePresentListData;
    },
    GetRoomWiseAttandanceListPresentPDF(state){
        return state.roomWisePresentListDataPDF;
    }
};

const mutations = {
    [GET_DAILY_PRESENT_LIST_ROOM_WISE](state, response){
        state.roomWisePresentListData = response;
    },

    [GET_DAILY_PRESENT_LIST_ROOM_WISE_PDF](state, response){
        state.roomWisePresentListDataPDF = response;
    },

};

const actions = {
    fatchRoomWiseAttandanceListPresent({commit}, peram){
        return Axios.get(dailyAttandancePresentListRoomWise, {
            params:{
                exam_initiate_id: peram.exam_initiate_id,
                center_code: peram.center_code,
                attendance_date: peram.attendance_date,
                room_no: peram.room_no,
                download: peram.download,
            }
        }).then((response)=>{
            var data = response.data.data;
            if(peram.download == 0){
                commit("GET_DAILY_PRESENT_LIST_ROOM_WISE", data);
            }else{
                console.log("TANVIR PDF 2========>", data);
                commit("GET_DAILY_PRESENT_LIST_ROOM_WISE_PDF", data);
            }
            return data;
        })

    }
};


export default {
    state,
    getters,
    actions,
    mutations
};