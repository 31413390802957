import {
    collegeCorePaperUrl,
    collegeCoreSubjectUrl,
    collegeCoreExamUrl,
    CollegeCoreExamYearsUrl,
    fetchCollegeAdmitCardDataBySubjectUrl,
    downloadAdmitCollege,
    downloadExamWiseAttendanceSheet,
    ExamRoutineCollegeUrl,
    fetchCollegeIndividualSpecialAdmitCardUrl
} from "../../api";
import { saveMsg, updateMsg, deleteMsg, failedMsg } from "../../erpUtil";
import Axios from "axios";
import {
} from "../../mutation-types";
const state = {};
const getters = {};
const mutations = {};
const actions = {
    collegeCorePaper({ commit }, searchParam) {
        return new Promise(function(resolve, reject) {
          Axios.get(collegeCorePaperUrl, {
            params: searchParam,
          })
            .then(function(response) {
              //console.log("response that came", response.data )
              var result = response.data.data;
              //// console.log("result", result)
              commit("SET_PAPER_DATA", result);
              resolve(result);
            })
            .catch(function(error) {
              // handle error
              // // console.log(error);
              reject();
            });
        });
      },
      CollegeCoreSubjects({ commit }, searchParam) {
        console.log("fdfdfd", JSON.stringify(searchParam));
        return new Promise((resolve, reject) => {
          commit("SET_LOADING", {
            color: "primary",
          });
          // loading  Opening
          Axios.get(collegeCoreSubjectUrl, {
            params: searchParam,
          })
            .then(function(response) {
              commit("UNSET_LOADING"); // loading Closing
              var result = response.data.data;
              // console.log(JSON.stringify(response.data.data));
              commit("SET_SUBJECT_DATA", result);
              resolve(response.data);
            })
            .catch(function(error) {
              commit("UNSET_LOADING"); // loading Closing
              console.log(error);
              reject();
            });
        });
      },
      CollegeCoreExams({ commit }, searchParam) {
        return new Promise(function(resolve, reject) {
          Axios.get(collegeCoreExamUrl, {
            params: searchParam
          })
            .then(function(response) {
              var result = response.data.data;
              commit("SET_EXAM_DATA", result);
              resolve(response.data);
            })
            .catch(function(error) {
              console.log(error);
              reject();
            });
        });
      },
      CollegeCoreExamYears({ commit }, searchParam) {
        return new Promise(function(resolve, reject) {
          Axios.get(CollegeCoreExamYearsUrl, {
            params: searchParam,
          })
            .then(function(response) {
              var result = response.data;
              commit("SET_ALL_YEARS", result.data);
              localStorage.setItem("allYears", JSON.stringify(result.data));
              // console.log("in fetchYearData", result);
              resolve();
            })
            .catch(function(error) {
              // handle error
              // console.log(error);
              reject();
            });
        });
      },
      fetchCollegeAdmitCardDataBySubject({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
          commit("SET_LOADING", {
            color: "primary"
          });
          // loading  Opening
          Axios.get(fetchCollegeAdmitCardDataBySubjectUrl, {
            params: searchParam
          })
            .then(function(response) {
              commit("UNSET_LOADING"); // loading Closing
              var result = response.data.data;
              console.log("AdmitCardUrl_BySubject", response.data.data);
              commit("SET_ADMIT_CARD_DATA_BY_SUBJECT", result);
              resolve(response.data);
            })
            .catch(function(error) {
              commit("UNSET_LOADING"); // loading Closing
              console.log(error);
              reject();
            });
        });
      },
      fetchCollegeIndividualSpecialAdmitCard({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
          commit("SET_LOADING", {
            color: "primary"
          });
          // loading  Opening
          Axios.get(fetchCollegeIndividualSpecialAdmitCardUrl, {
            params: searchParam
          })
            .then(function(response) {
              commit("UNSET_LOADING"); // loading Closing
              var result = response.data;
              commit("SET_ADMIT_CARD_DATA_BY_INDIVIDUAL", result);
              resolve(response.data);
            })
            .catch(function(error) {
              commit("UNSET_LOADING"); // loading Closing
              console.log(error);
              reject();
            });
        });
      },
      downloadAdmitCollegewiseCollegePanel({ commit }, item) {
        return new Promise((resolve, reject) => {
          commit("SET_LOADING", {
            color: "primary",
          });
          // loading  Opening
          Axios.get(downloadAdmitCollege, {
            params: item,
          })
            .then(function(response) {
              commit("UNSET_LOADING"); // loading Closing
              var result = response.data.data;
              console.log("responseresponse", response.data.status);
              if (response.data.status === "success") {
                commit("SET_SNACKBAR", {
                  msg: response.data.message,
                  color: "green",
                });
                // resolve(result);
              } else {
                commit("SET_SNACKBAR", {
                  msg: response.data.message,
                  color: "red",
                });
                // reject();
              }
              resolve(result);
            })
            .catch(function(error) {
              commit("UNSET_LOADING"); // loading Closing
              reject();
            });
        });
      },
      downloadExamWiseAttendanceSheetCollegePanel({ commit }, item) {
        return new Promise((resolve, reject) => {
          commit("SET_LOADING", {
            color: "primary"
          });
          // loading  Opening
          Axios.get(downloadExamWiseAttendanceSheet, {
            params: item
          })
            .then(function(response) {
              commit("UNSET_LOADING");
              var result = response.data.data;
              if (response.data.status === "success") {
                commit("SET_SNACKBAR", {
                  msg: response.data.message,
                  color: "green"
                });
                resolve(result);
              } else {
                commit("SET_SNACKBAR", {
                  msg: response.data.message,
                  color: "red"
                });
                // reject();
              }
              resolve(result);
            })
            .catch(function(error) {
              commit("UNSET_LOADING"); // loading Closing
              // console.log(error);
              reject();
            });
        });
      },
      searchExamRoutineDataCollegePanel({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
          commit("SET_LOADING", {
            color: "primary",
          });
          // loading  Opening
          Axios.get(ExamRoutineCollegeUrl, {
            params: searchParam,
          })
            .then(function(response) {
              commit("UNSET_LOADING"); // loading Closing
              var result = response.data.data;
              commit("SET_ROUTINE_DATA", result);
              resolve(response.data);
            })
            .catch(function(error) {
              commit("UNSET_LOADING"); // loading Closing
              console.log(error);
              reject();
            });
        });
      },
      
};
export default {
    state,
    getters,
    actions,
    mutations,
  };