import Axios from "axios";
import {GET_EXAM_STATUS_AT_EXAM_DASHBOARD} from "../../mutation-types";
import {ExamStatusAtExamDashboard} from "../../api";
  

const state = {
ExamStatuatEDs:{},
};
const getters = {
getDataofExamStatus(state){
    return state.ExamStatuatEDs
},
};
const mutations = {
    [GET_EXAM_STATUS_AT_EXAM_DASHBOARD](state, response){
        state.ExamStatuatEDs = response;
    },
};
const actions = {
    getAllStatusofExam({commit}, data){
        return Axios.get(ExamStatusAtExamDashboard, {params:data})
        .then((response)=>{
            var chamar =  response.data;
            commit("GET_EXAM_STATUS_AT_EXAM_DASHBOARD", chamar);
            console.log("Tanvir------------->", chamar)
            return chamar;
        })
    },
};


export default {
    state,
    getters,
    actions,
    mutations,
  };