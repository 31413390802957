import Axios from "axios";
import { GET_HEAD_EXAMINER_WISE_EXAMINER_DATA } from "../../mutation-types";
import {
  examinerHeadExaminerpaperChangeData,
  addNewExaminerData,
  importexaminerdata,
  deleteExaminerData,
  changeExaminerPaper,
} from "../../api";

const state = {
  examinerData: [],
};

const getters = {
  examinerList() {
    return state.examinerData;
  },
};

const mutations = {
  [GET_HEAD_EXAMINER_WISE_EXAMINER_DATA](state, result) {
    state.examinerData = result;
  },
};

const actions = {
  fetchExaminerPaperChangeDataList({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      // loading  Opening Scrutinizer List
      Axios.get(examinerHeadExaminerpaperChangeData, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data.data;
          console.log("Tanvir, this is scrutinizer->", result);
          commit("GET_HEAD_EXAMINER_WISE_EXAMINER_DATA", result);
          resolve(response.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  postNewExaminerData({ commit }, peram) {
    console.log("This is from api", peram);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary",
      });
      Axios.post(addNewExaminerData, peram)
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Tanvir, Examiner added successfully!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green",
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000,
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message,
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Process failed. Please try again !!!",
            color: "red",
          });
          reject(error);
        });
    });
  },

  postNewExaminerData({ commit }, formData) {
    console.log("This is from api", formData);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary",
      });
      Axios.post(importexaminerdata, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Examiner data added successfully!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green",
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000,
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message,
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Process failed. Please try again !!!",
            color: "red",
          });
          reject(error);
        });
    });
  },

  deleteExaminer({ commit }, peram) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      Axios.delete(deleteExaminerData, { data: peram })
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status === "success") {
            console.log("Tanvir! Examiner deleted successfully!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green",
            });
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000,
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Process failed. Please try again !!!",
            color: "red",
          });
          reject(error);
        });
    });
  },

  changeExaminerPapaerData({ commit }, peram) {
    console.log("This is from api", peram);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary",
      });
      Axios.post(changeExaminerPaper, peram)
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Tanvir, Examiner Paper Changed Successfully!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green",
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000,
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message,
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Changing paper failed !!!",
            color: "red",
          });
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
