export const navbarSeeder = [{
    name: 'Admin Module',
    icon: 'account_circle',
    link: '',
    role: [1],
    sl_no: 1,
    children: [{
        name: 'Admin Dashboard',
        icon: 'dashboard',
        link: '/admin-dashboard',
        role: [1],
        sl_no: 1,
    },
    // {
    //     name: 'Exam Dashboard',
    //     icon: 'dashboard',
    //     link: '/exam-dashboard',
    //     role: [1],
    //     sl_no: 6,
    // },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 2,
    },
    {
        name: 'All Users',
        icon: 'people_outline',
        link: '/users',
        role: [1],
        sl_no: 3,
    },
    {
        name: 'User role',
        icon: 'storage',
        link: '/role',
        role: [1],
        sl_no: 4,
    },
    {
        name: 'User role Configure',
        icon: 'keyboard_tab',
        link: '/role-details',
        role: [1],
        sl_no: 5,
    },
    {
        name: 'District Controller',
        icon: 'people_outline',
        link: '/dc-list',
        role: [1],
        sl_no: 7,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 8,
    },
    {
        name: 'Amanuensis List',
        icon: 'clear_all',
        link: '/amanuensis-list',
        role: [1],
        sl_no: 9,
    },
    {
        name: 'Exam Activities ',
        icon: 'clear_all',
        link: '/exam_monitor/exam-activities',
        role: [1],
        sl_no: 9,
    },
    {
        name: 'Exam Initiate',
        icon: 'people_outline',
        link: '/exam-initiate-list',
        role: [1],
        sl_no: 10,
    },
    {
        name: 'Rescrutiner List',
        icon: 'people_outline',
        link: '/rescrutiner-list',
        role: [1],
        sl_no: 10,
    },
    {
        name: 'Exam Routine',
        icon: 'subject',
        link: '/exam-routine-list',
        role: [1],
        sl_no: 11,
        },
    {
        name: 'Exam Routine Schedule Change',
        icon: 'shuffle',
        link: '/schedule-change',
        role: [1],
        sl_no: 12,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 15,
    },

    {
        name: 'Student List',
        icon: 'list',
        link: '/student-list',
        role: [1],
        sl_no: 16,
    },
    {
        name: 'Registration List',
        icon: 'list',
        link: '/registration',
        role: [1],
        sl_no: 17,
    },
    {
        name: 'Final Examinee List',
        icon: 'list',
        link: '/final-examinee-list',
        role: [1],
        sl_no: 18,
    },

    {
        name: 'Attendance List (RAW)',
        icon: 'people_outline',
        link: '/std-attendance',
        role: [1],
        sl_no: 19,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 23,
    },
    {
        name: 'Exam List',
        icon: 'flip_to_front',
        link: '/exam',
        role: [1],
        sl_no: 24,
    },

    {
        name: 'College List',
        icon: 'list',
        link: '/college-list',
        role: [1],
        sl_no: 25,
    },

    {
        name: 'Division List ',
        icon: 'people_outline',
        link: '/division-list',
        role: [1],
        sl_no: 26,
    },
    {
        name: 'District List ',
        icon: 'people_outline',
        link: '/district-list',
        role: [1],
        sl_no: 27,
    },

    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 28,
    },
    {
        name: 'Menus',
        icon: 'list',
        link: '/menu-list',
        role: [1],
        sl_no: 29,
    },
    {
        name: 'Submenus',
        icon: 'list',
        link: '/submenu-list',
        role: [1],
        sl_no: 30,
    },
    {
        name: 'Rescrutiner list',
        icon: 'list',
        link: '/rescrutiner-list',
        role: [1],
        sl_no: 31,
    },
    {
        name: 'Off Day list',
        icon: 'list',
        link: '/offday-list',
        role: [1],
        sl_no: 32,
    },
    {
        name: 'Exam Year List',
        icon: 'list',
        link: '/year-list',
        role: [1],
        sl_no: 32,
    },
    {
        name: 'Published Activity Status',
        icon: 'list',
        link: '/published-activity-status',
        role: [1],
        sl_no: 33,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 40,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 50,
    },
    ],
},
{
    name: 'National University',
    icon: 'account_balance',
    link: '',
    role: [1, 6],
    sl_no: 2,
    children: [{
        name: 'Center Allocation theory',
        icon: 'people_outline',
        link: '/center-allocation/theory',
        role: [1],
        sl_no: 1,
    },
    {
        name: 'Loose Script Collection ( Unused )',
        icon: 'arrow_back',
        link: '/nu/ls-coll-from-reg',
        role: [1],
        sl_no: 4,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 15,
    },
    {
        name: 'Attendance (All)',
        icon: 'spellcheck',
        link: '/std-attendance',
        role: [1, 6],
        sl_no: 5,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 15,
    },
    {
        name: 'Blank Script Distribute',
        icon: 'arrow_back',
        link: '/nu/bs-dist-to-reg',
        role: [1],
        sl_no: 6,
    },
    {
        name: 'Answer Script Receive',
        icon: 'arrow_forward',
        link: '/nu/as-coll-from-reg',
        role: [1],
        sl_no: 7,
    },
    {
        name: 'Answer Script Distribute',
        icon: 'arrow_back',
        link: '/nu/as-dist-to-reg',
        role: [1],
        sl_no: 8,
    },
    {
        name: 'Answer Script Receive Final',
        icon: 'arrow_forward',
        link: '/nu/as-coll-from-reg-final',
        role: [1],
        sl_no: 9,
    },
    {
        name: 'Missing Script List',
        icon: 'arrow_back',
        link: '/missing-script',
        role: [1],
        sl_no: 10,
    },
    {
        name: 'Ans Receive From RC final',
        icon: 'arrow_back',
        link: '/nu/as-coll-from-reg-final',
        role: [1],
        sl_no: 10,
    },
    ],
},
{
    name: 'Center Management Module',
    icon: 'library_books',
    link: '',
    role: [1, 2],
    sl_no: 9,
    children: [{
        name: 'Center Dasboard',
        icon: 'dashboard',
        link: '/center/center-dashboard',
        role: [1, 2],
        sl_no: 0,
    },
    {
        name: 'Exam Summary',
        icon: 'dashboard',
        link: '/center/center-exam-summary',
        role: [1],
        sl_no: 3,
    },
    {
        name: 'Room wise Attendance',
        icon: 'dashboard',
        link: '/center/daily-room-wise-present-list',
        role: [1],
        sl_no: 3,
    },
    {
        name: 'Center Profile',
        icon: 'markunread_mailbox',
        link: '/center/center_profile',
        role: [1, 2],
        sl_no: 2,
    },
    {
        name: 'Center User list',
        icon: 'list',
        link: '/center/center_user',
        role: [1, 2],
        sl_no: 8,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 20,
    },
    {
        name: 'Final Examinee List',
        icon: 'list',
        link: '/final-examinee-list',
        role: [1],
        sl_no: 23,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 30,
    },
    {
        name: 'Attendance List (RAW)',
        icon: 'people_outline',
        link: '/std-attendance',
        role: [1],
        sl_no: 34,
    },
    {
        name: 'Attendance By ExamYear',
        icon: 'markunread_mailbox',
        link: '/attendance-by-exam-year',
        role: [1, 2],
        sl_no: 35,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 40,
    },

    {
        name: 'Invigilator',
        icon: 'perm_identity',
        link: '/invigilator-list',
        role: [1, 2],
        sl_no: 41,
    },
    {
        name: 'Invigilator Access List',
        icon: 'face',
        link: '/invigilator-access',
        role: [1, 2],
        sl_no: 42,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 60,
    },
    {
        name: 'Blank Script Receive ',
        icon: 'arrow_forward',
        link: '/center/bs-coll-from-reg',
        role: [1, 2],
        sl_no: 61,
    },
    {
        name: 'Answer Script Distribute',
        icon: 'arrow_back',
        link: '/center/as-dist-to-reg',
        role: [1, 2],
        sl_no: 62,
    },
    {
        name: 'Unused Blank Script Delivery',
        icon: 'arrow_back',
        link: '/center/ls-dist-to-reg',
        role: [1, 2],
        sl_no: 63,
    },
    {
        name: 'Center Summary',
        icon: 'list_alt',
        link: '/center/center-summary',
        role: [1, 2],
        sl_no: 64,
    },
    {
        name: 'Exam Committee List',
        icon: 'list_alt',
        link: '/exam_committee',
        role: [1, 2],
        sl_no: 65,
    },
    {
        name: 'Treasury Statement',
        icon: 'list_alt',
        link: '/center/treasury',
        role: [1, 2],
        sl_no: 66,
    },
    ],
},
{
    name: 'Center Management Module (NU)',
    icon: 'account_balance',
    link: '',
    role: [1, 6],
    sl_no: 7,
    children: [{
        name: 'Center List ',
        icon: 'people_outline',
        link: '/center/center-list',
        role: [1],
        sl_no: 1,
    },
    {
        name: 'Center Users',
        icon: 'people_outline',
        link: '/center/center_user',
        role: [1],
        sl_no: 3,
    },
    ],
},
{
    name: 'Regional Center',
    icon: 'view_week',
    link: '',
    role: [1, 3],
    sl_no: 10,
    children: [{
        name: 'Regional Center Dashboard',
        icon: 'dashboard',
        link: '/regional-center-dashboard',
        role: [1, 3],
        sl_no: 1,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 10,
    },
    {
        name: 'Regional Center Profile',
        icon: 'person',
        link: '/reg-center/reg-center-profile',
        role: [1, 3],
        sl_no: 12,
    },

    {
        name: 'Regional Center User ',
        icon: 'people',
        link: '/regional_center_user',
        role: [1, 3],
        sl_no: 13,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 20,
    },
    {
        name: 'Blank Script Collection From NU',
        icon: 'arrow_forward',
        link: '/reg-center/bs-coll-from-nu',
        role: [1, 3],
        sl_no: 25,
    },
    {
        name: 'Blank Script Delivery To Center',
        icon: 'arrow_back',
        link: '/reg-center/bs-dist-to-cen',
        role: [1, 3],
        sl_no: 26,
    },

    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 30,
    },
    {
        name: 'Answer Script Collection From Center',
        icon: 'arrow_forward',
        link: '/reg-center/as-coll-from-cen',
        role: [1, 3],
        sl_no: 37,
    },
    {
        name: 'Loose ( Unused ) Script Collection From Center ',
        icon: 'arrow_forward',
        link: '/reg-center/ls-coll-from-cen',
        role: [1, 3],
        sl_no: 38,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 40,
    },
    {
        name: 'Answer Script Delivery To NU',
        icon: 'arrow_back',
        link: '/reg-center/as-dist-to-nu',
        role: [1, 3],
        sl_no: 45,
    },
    {
        name: 'Loose ( Unused ) Script Delivery To NU',
        icon: 'arrow_back',
        link: '/reg-center/ls-dist-to-nu',
        role: [1, 3],
        sl_no: 46,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 50,
    },

    {
        name: 'Answer Script Collection From National University',
        icon: 'arrow_forward',
        link: '/reg-center/as-coll-from-nu',
        role: [1, 3],
        sl_no: 54,
    },
    {
        name: 'Answer Script Delivery To Examiner',
        icon: 'arrow_back',
        link: '/reg-center/as-dist-to-exam',
        role: [1, 3],
        sl_no: 55,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 60,
    },

    {
        name: 'Answer Script Collection From Examiner',
        icon: 'arrow_forward',
        link: '/reg-center/as-coll-from-exam',
        role: [1, 3],
        sl_no: 61,
    },
    {
        name: 'Answer Script Delivery To National University Final-List',
        icon: 'arrow_back',
        link: '/reg-center/as-dist-to-nu-final',
        role: [1, 3],
        sl_no: 62,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 70,
    },

    {
        name: 'Token Verification',
        icon: 'person',
        link: '/token-verify',
        role: [1, 3],
        sl_no: 74,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 80,
    },
    ],
},
{
    name: 'Regional Center Module (NU)',
    icon: 'account_balance',
    link: '',
    role: [1, 6],
    sl_no: 8,
    children: [{
        name: 'Regional Center List',
        icon: 'people_outline',
        link: '/regional_center',
        role: [1],
        sl_no: 2,
    },

    {
        name: 'Regional Center Users',
        icon: 'people_outline',
        link: '/regional_center_user',
        role: [1],
        sl_no: 3,
    },
    {
        name: 'Regional-Center Map',
        icon: 'people_outline',
        link: '/reg-center-map',
        role: [1],
        sl_no: 4,
    },
    ],
},
//Exam  Store
{
    name: 'Exam Store',
    icon: '',
    link: '',
    role: [1, 5],
    sl_no: 28,
    children: [
        {
            name: 'Item List',
            icon: 'dashboard',
            link: '/exam-store/item-list',
            role: [1],
            sl_no: 1,
        },
        {
            name: 'Unit List',
            icon: 'dashboard',
            link: '/exam-store/unit-list',
            role: [1],
            sl_no: 2,
        },
        {
            name: 'Receive Item List',
            icon: 'dashboard',
            link: '/exam-store/receive-list-list',
            role: [1],
            sl_no: 3,
        },
        {
            name: 'Issue To Center',
            icon: 'dashboard',
            link: '/exam-store/issue-to-Center',
            role: [1],
            sl_no: 4,
        },
        {
            name: 'Issued Items Return',
            icon: 'dashboard',
            link: '/exam-store/issued-items-return',
            role: [1],
            sl_no: 5,
        },

    ]
},
{
    name: 'Head Examiner/Examiner',
    icon: 'supervisor_account',
    link: '',
    role: [1, 5],
    sl_no: 11,
    children: [{
        name: 'Examiner Dashboard ',
        icon: 'dashboard',
        link: '/examiner/examiner-dashboard',
        role: [1, 5],
        sl_no: 1,
    },
    {
        name: 'Exam Marks ',
        icon: 'list',
        link: '/examiner/mark',
        role: [1, 5],
        sl_no: 2,
    },
    {
        name: 'Examiner Monitoring',
        icon: 'arrow_forward',
        link: '/examiner/examiner-monitoring',
        role: [1, 5],
        sl_no: 3,
    },
    {
        name: 'Answer Script Collection From Regional Center',
        icon: 'arrow_forward',
        link: '/examiner/as-coll-from-reg',
        role: [1, 5],
        sl_no: 6,
    },
    {
        name: 'Answer Script Delivery to Regional Center',
        icon: 'arrow_back',
        link: '/examiner/as-dist-to-reg',
        role: [1, 5],
        sl_no: 4,
    },

    {
        name: 'Examiner List ',
        icon: 'clear_all',
        link: '/examiner-list',
        role: [1, 5],
        sl_no: 7,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 40,
    },
    {
        name: 'Examiner By Head-Examiner ',
        icon: 'clear_all',
        link: '/examiner-by-head-examiner',
        role: [1, 5],
        sl_no: 8,
    },
    {
        name: 'Examiner-wise Mark',
        icon: 'clear_all',
        link: '/examiner-wise-mark',
        role: [1, 5],
        sl_no: 9,
    },
    ],
},
{
    name: 'Exam Monitor',
    icon: 'supervisor_account',
    link: '',
    role: [1, 5],
    sl_no: 3,
    children: [{
        name: 'Center Allocation Theory',
        icon: 'subject',
        link: '/center-allocation/theory',
        role: [1],
        sl_no: 2,
    },
    {
        name: 'Practical Center Allocation',
        icon: 'subject',
        link: '/center-allocation/practical',
        role: [1],
        sl_no: 3,
    },
    {
        name: 'Viva Center Allocation',
        icon: 'subject',
        link: '/center-allocation/viva',
        role: [1],
        sl_no: 4,
        },
    {
        name: 'PV Allocation',
        icon: 'subject',
        link: '/pv-allocation',
        role: [1],
        sl_no: 5,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 10,
    },
    {
        name: 'Exam Routine',
        icon: 'subject',
        link: '/exam-routine-list',
        role: [1],
        sl_no: 12,
    },

    {
        name: 'Exam Timeline ',
        icon: 'clear_all',
        link: '/exam_monitor/exam-activities-timeline',
        role: [1, 5],
        sl_no: 14,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 20,
    },
    {
        name: 'Question Order',
        icon: 'clear_all',
        link: '/exam_monitor/question-order',
        role: [1, 5],
        sl_no: 26,
    },

    {
        name: 'Ques Statement',
        icon: 'face',
        link: '/exam_monitor/stm-ques',
        role: [1, 5],
        sl_no: 28,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 30,
    },
    {
        name: 'Script Statement',
        icon: 'face',
        link: '/exam_monitor/stm-script',
        role: [1, 5],
        sl_no: 35,
    },
    {
        name: 'Script distribution Statement',
        icon: 'face',
        link: '/exam_monitor/script-distribution',
        role: [1, 5],
        sl_no: 36,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 40,
    },
    {
        name: 'Report / WithHeld',
        icon: 'face',
        link: '/report-withheld',
        role: [1, 5],
        sl_no: 43,
    },
    {
        name: 'Examiner Monitoring',
        icon: 'face',
        link: '/exam_monitor/examiner-script-distribution',
        role: [1, 5],
        sl_no: 44,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 50,
    },
    {
        name: 'Question Receive',
        icon: 'clear_all',
        link: '/exam_monitor/question-receive',
        role: [1, 5],
        sl_no: 45,
    },
    ],
},
{
    name: 'Admit Card',
    icon: 'list',
    link: '',
    role: [1, 5],
    sl_no: 5,
    children: [{
        name: 'Admit Card',
        icon: 'list',
        link: '/admit-card',
        role: [1, 5],
        sl_no: 1,
    },

    {
        name: 'Admit Card (by College)',
        icon: 'list',
        link: '/admitcard-group-by-college',
        role: [1, 5],
        sl_no: 2,
    },
    {
        name: 'Admit Card Generate',
        icon: 'list',
        link: '/admit-card-generate',
        role: [1, 5],
        sl_no: 3,
    },
    
    {
        name: 'Admit Card Count In Depth',
        icon: 'list',
        link: '/admit-card-dd',
        role: [1, 5],
        sl_no: 4,
    },
    ],
},
{
    name: 'Invigilator Panel',
    icon: 'account_circle',
    link: '',
    role: [1, 4],
    sl_no: 12,
    children: [{
        name: 'Invigilator Dashboard',
        icon: 'people_outline',
        link: '/invigilator-dashboard',
        role: [1, 4],
        sl_no: 1,
    },
    {
        name: 'Invigilator Profile',
        icon: 'people_outline',
        link: '/invigilator-profile',
        role: [1, 4],
        sl_no: 1,
    },
    {
        name: 'Attendance By EY',
        icon: 'pan_tool',
        link: '/attendance-by-exam-year',
        role: [1, 4],
        sl_no: 2,
    },
    {
        name: 'Attendance',
        icon: 'pan_tool',
        link: '/std-attendance',
        role: [1, 4],
        sl_no: 2,
    },
    ],
},
{
    name: 'Audit',
    icon: 'visibility',
    link: '',
    role: [1],
    sl_no: 17,
    children: [{
        name: 'Audit filter ',
        icon: 'list',
        link: '/log-data',
        role: [1],
        sl_no: 1,
    },],
},
{
    name: 'Practical Viva Exam Module',
    icon: 'work',
    link: '',
    role: [1],
    sl_no: 13,
    children: [{
        name: 'Practical Exam Marks',
        icon: 'list',
        link: '/practical_viva/pv_exam_list',
        role: [1],
        sl_no: 1,
    },
    {
        name: 'Viva Exam Marks',
        icon: 'list',
        link: '/practical_viva/viva-exam-list',
        role: [1],
        sl_no: 2,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 10,
    },
    {
        name: 'Practical/Viva Routine',
        icon: 'list',
        link: '/practical_viva/pv-routine',
        role: [1],
        sl_no: 11,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 20,
    },
    {
        name: 'Center Allocation Practical Viva',
        icon: 'list',
        link: '/center-allocation/practical',
        role: [1],
        sl_no: 23,
    },
    {
        name: 'Center Allocation Viva',
        icon: 'list',
        link: '/practical_viva/center-allocation-viva',
        role: [1],
        sl_no: 24,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 30,
    },
    {
        name: 'Practical Candidate List',
        icon: 'list',
        link: '/practical_viva/practical-candidate',
        role: [1],
        sl_no: 34,
    },
    {
        name: 'Viva Candidate List',
        icon: 'list',
        link: '/practical_viva/viva-candidate',
        role: [1],
        sl_no: 36,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 40,
    },
    {
        name: 'Practical Wanting List',
        icon: 'list',
        link: '/practical_viva/practical-wanting',
        role: [1],
        sl_no: 45,
    },
    {
        name: 'Viva Wanting List',
        icon: 'list',
        link: '/practical_viva/viva-wanting',
        role: [1],
        sl_no: 47,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 50,
    },
    // {
    //     name: 'Practical Center List',
    //     icon: 'list',
    //     link: '/practical_viva/practical-center',
    //     role: [1],
    //     sl_no: 55,
    // },
    // {
    //     name: 'Viva Center List',
    //     icon: 'list',
    //     link: '/practical_viva/viva-center',
    //     role: [1],
    //     sl_no: 57,
    // },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 60,
    },
    {
        name: 'Reports',
        icon: 'list',
        link: '/practical_viva/report',
        role: [1],
        sl_no: 48,
    },
    ],
},
{
    name: 'Business Intelligence',
    icon: 'note',
    link: '',
    role: [1],
    sl_no: 18,
    children: [{
        name: 'BI Basic',
        icon: 'list',
        link: '/biBasic',
        role: [1],
        sl_no: 1,
    },],
},
{
    name: 'Form Fillup',
    icon: 'web_asset',
    link: '',
    role: [1],
    sl_no: 4,
    children: [{
        name: 'Form Fillup',
        icon: 'view_quilt',
        link: '/form-fillup',
        role: [1, 6],
        sl_no: 1,
    },

    {
        name: 'Form Fill-up College-wise',
        icon: 'view_quilt',
        link: '/form-fillup-group-by-college',
        role: [1],
        sl_no: 2,
    },
    // {
    //     name: '----------',
    //     icon: '',
    //     link: 'divider',
    //     role: [1],
    //     sl_no: 10,
    // },
    {
        name: 'Probable Generate',
        icon: 'open_in_new',
        link: '/prob/exams',
        role: [1],
        sl_no: 14,
    },
    {
        name: 'Probable Slot List',
        icon: 'open_in_new',
        link: '/prob/pbl-slot-wise',
        role: [1],
        sl_no: 15,
    },
    {
        name: 'Probable Student List',
        icon: 'open_in_new',
        link: '/prob/pbl-student-detail',
        role: [1],
        sl_no: 16,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 20,
    },
    {
        name: "Custom Form Fillup Report",
        icon: "open_in_new",
        link: "/prob/custom-form-fillup-report",
        role: [1],
        sl_no: 16
    },
    {
        name: 'Report college-wise',
        icon: 'open_in_new',
        link: '/prob/pbl-college-wise',
        role: [1],
        sl_no: 24,
    },
    {
        name: 'Report subject-wise',
        icon: 'open_in_new',
        link: '/prob/pbl-subject-wise',
        role: [1],
        sl_no: 25,
    },
    {
        name: 'Report paper-wise',
        icon: 'open_in_new',
        link: '/prob/pbl-paper-wise',
        role: [1],
        sl_no: 26,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 30,
    },
    {
        name: 'Roll Generate',
        icon: 'open_in_new',
        link: '/rollGenerate',
        role: [1],
        sl_no: 34,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 40,
    },
    {
        name: 'Form Fill-Up Summary',
        icon: 'view_quilt',
        link: '/fromFillupsummary',
        role: [1],
        sl_no: 10,
    },
    ],
},
{
    name: 'PDF Settings',
    icon: 'supervised_user_circle',
    link: '',
    role: [1],
    sl_no: 19,
    children: [{
        name: 'PDF-Setup',
        icon: 'view_quilt',
        link: '/pdf-setup',
        role: [1, 6],
        sl_no: 2,
    },],
},
{
    name: 'Registration',
    icon: 'face',
    link: '',
    role: [1],
    sl_no: 14,
    children: [{
        name: 'Student List',
        icon: 'list',
        link: '/reg-student',
        role: [1, 6],
        sl_no: 1,
    },
    {
        name: 'Reg. Card Correction',
        icon: 'list',
        link: '/reg-cor',
        role: [1, 6],
        sl_no: 2,
    },
    {
        name: 'Reg. Card Corr. Admin',
        icon: 'list',
        link: '/reg-corr-admin',
        role: [1, 6],
        sl_no: 3,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 30,
    },
    ],
},
{
    name: 'College Affiliation and Information',
    icon: 'meeting_room',
    link: '',
    role: [1],
    sl_no: 20,
    children: [{
        name: 'College List',
        icon: 'meeting_room',
        role: [1, 6],
        link: '/college',
    },
    {
        name: 'Course and Subject List',
        icon: 'meeting_room',
        role: [1, 6],
        link: '/collegecourselist',
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 30,
    },
    ],
},
{
    name: 'Syllabus and Regulation',
    icon: 'face',
    link: '',
    role: [1],
    sl_no: 21,
    children: [{
        name: 'Degree List',
        icon: 'list',
        link: '/syl-reg/degrees',
        role: [1, 6],
        sl_no: 1,
    },
    {
        name: 'Degree Group List',
        icon: 'list',
        link: '/syl-reg/degree-group',
        role: [1, 6],
        sl_no: 2,
    },
    {
        name: 'Subject List',
        icon: 'list',
        link: '/syl-reg/subject',
        role: [1, 6],
        sl_no: 4,
    },
    {
        name: 'Paper List',
        icon: 'list',
        link: '/syl-reg/paper',
        role: [1, 6],
        sl_no: 3,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 30,
    },
    ],
},
{
    name: 'Admin Core',
    icon: 'face',
    link: '',
    role: [1],
    sl_no: 22,
    children: [{
        name: 'College Type List',
        icon: 'list',
        link: '/core/college-type',
        role: [1, 6],
        sl_no: 1,
    },
    {
        name: 'Management Type',
        icon: 'list',
        link: '/core/mgt-type',
        role: [1, 6],
        sl_no: 2,
    },
    {
        name: 'Student Type',
        icon: 'list',
        link: '/core/student-type',
        role: [1, 6],
        sl_no: 3,
    },
    {
        name: 'Paper Type',
        icon: 'list',
        link: '/core/paper-type',
        role: [1, 6],
        sl_no: 4,
    },
        {
            name: 'Paper Group',
            icon: 'list',
            link: '/core/paper-group',
            role: [1, 6],
            sl_no: 7,
        },
    {
        name: 'Exam Type',
        icon: 'list',
        link: '/core/exam-type',
        role: [1, 6],
        sl_no: 6,
    },
    {
        name: 'Exam Fee head',
        icon: 'list',
        link: '/core/exam-fee-head-list',
        role: [1, 6],
        sl_no:5,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 30,
    },
        
    ],
},
{
    name: 'Question Distribution',
    icon: 'account_balance',
    link: '',
    role: [1, 6],
    sl_no: 6,
    children: [{
        name: 'Question Requisition',
        icon: 'dashboard',
        link: '/question-requisition',
        role: [1, 6],
        sl_no: 1,
    },
    {
        name: 'Question Paper Entry',
        icon: 'assignment',
        link: '/question-entry',
        role: [1, 6],
        sl_no: 2,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 30,
    },
    {
        name: 'College Statement (Subject Wise)',
        icon: 'report',
        link: '/exam_monitor/stm-col-sub-wise',
        role: [1, 6],
        sl_no: 31,
    },
    {
        name: 'College Statement (Course Wise)',
        icon: 'report',
        link: '/exam_monitor/stm-col-cour-wise',
        role: [1, 6],
        sl_no: 32,
    },
    {
        name: 'College Statement (Degree Group Wise)',
        icon: 'report',
        link: '/exam_monitor/stm-col-degG-wise',
        role: [1, 6],
        sl_no: 33,
    },
    ],
},

{
    name: 'Expel',
    icon: 'account_balance',
    link: '',
    role: [1, 6],
    sl_no: 16,
    children: [{
        name: 'Expel List',
        icon: 'people_outline',
        link: '/expelList',
        role: [1],
        sl_no: 2,
    },],
},
{
    name: 'Result',
    icon: 'account_balance',
    link: '',
    role: [1, 6],
    sl_no: 15,
    children: [{
        name: 'Result Data',
        icon: 'people_outline',
        link: '/result',
        role: [1],
        sl_no: 1,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 2,
    },
    {
        name: 'Certificates',
        icon: 'view_quilt',
        link: '/certificates',
        role: [1, 6],
        sl_no: 3,
    },

    {
        name: 'Rescrutiny Applicant List',
        icon: 'view_quilt',
        link: '/rescrutiny-applicant-list',
        role: [1, 6],
        sl_no: 4,
    },

    {
        name: 'Transcript List ',
        icon: 'list',
        link: '/transcript',
        role: [1],
        sl_no: 5,
    },
    {
        name: 'Mark-Sheet List ',
        icon: 'list',
        link: '/mark-sheet',
        role: [1],
        sl_no: 6,
    },
    // { //if using same view file & route param pass
    //     name: 'Result Approval Flow',
    //     icon: 'list',
    //     link: '/result-approval/result',
    //     role: [1],
    //     sl_no: 6,
    // },
    {
        name: 'Result Approval Flow',
        icon: 'list',
        link: '/result-approval',
        role: [1],
        sl_no: 6,
    },
    {
        name: '----------',
        icon: '',
        link: 'divider',
        role: [1],
        sl_no: 7,
    },
    {
        name: 'In-Course',
        icon: 'list',
        link: '/in-course',
        role: [1],
        sl_no: 8,
    },
    {
        name: 'Rescrutiny Student List',
        icon: 'list',
        link: '/rescrutiny-std-list',
        role: [1],
        sl_no: 9,
    },
    {
        name: 'Rescrutiny Notice',
        icon: 'list',
        link: '/rescrutiny-notice',
        role: [1],
        sl_no: 10,
    },
    {
        name: 'Rescrutiner List',
        icon: 'list',
        link: '/rescrutiner-list',
        role: [1],
        sl_no: 11,
    },
    {
        name: 'Rescrutiny Monitoring ',
        icon: 'view_quilt',
        link: '/rescrutiny-monitoring',
        role: [1, 6],
        sl_no: 12,
    },
    {
        name: 'Rescrutiny Details ',
        icon: 'view_quilt',
        link: '/rescrutiny-details',
        role: [1, 6],
        sl_no: 13,
    },
    {
        name: 'Online Certificate Add ',
        icon: 'view_quilt',
        link: '/online-certificate-add',
        role: [1, 6],
        sl_no: 14,
    },
    {
        name: 'Online Verified Certificate List ',
        icon: 'view_quilt',
        link: '/certificate-verified-list',
        role: [1, 6],
        sl_no: 15,
    },
    {
        name: 'Overall Absent/Reported/Withheld List ',
        icon: 'view_quilt',
        link: '/absent-reported-withheld-list',
        role: [1, 6],
        sl_no: 16,
    },
    {
        name: 'Overall Wanting List ',
        icon: 'view_quilt',
        link: '/overall-wanting-list',
        role: [1, 6],
        sl_no: 17,
    },
    {
        name: 'Mismatch List ',
        icon: 'view_quilt',
        link: '/mismatch-list',
        role: [1, 6],
        sl_no: 18,
    },
    {
        name: 'Rescrutiny Script Count List ',
        icon: 'view_quilt',
        link: '/rescrutiny-script-count',
        role: [1, 6],
        sl_no: 19,
    },
    {
        name: 'All Marks',
        icon: 'list_alt',
        link: '/all-marks',
        role: [1, 6],
        sl_no: 20,
    },
    {
        name: 'Grace Marks Calculator',
        icon: 'list_alt',
        link: '/grace-calculator',
        role: [1, 6],
        sl_no: 21,
    },
    // { if using same view file & route param pass
    //     name: ' Rescrutiny Result Approval Flow',
    //     icon: 'list',
    //     link: '/result-approval/rescrutiny',
    //     role: [1],
    //     sl_no: 22,
    // },
    {
        name: 'Rescrutiny Result Approval Flow',
        icon: 'list',
        link: '/rescrutiny-approval',
        role: [1],
        sl_no: 22,
    },
        
    ],
},
];