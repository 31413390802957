import Axios from 'axios'
import {ExamStoreItemissuedReturn} from '../../api'
import {GET_ISSUED_ITEM_RETURN_MESSAGE} from '../../mutation-types'
const state={
    returnMess:{},
};

const getters={
    getExamIssuedItemReturnMess(state){
        return state.returnMess;
    }
};

const mutations={
    [GET_ISSUED_ITEM_RETURN_MESSAGE](state,response){
        state.returnMess = response;
    }
};

const actions={
    saveIssuedItemReturn({ commit }, issueItemReturn){
       return Axios.post(ExamStoreItemissuedReturn, issueItemReturn)
       .then((response)=>{
           var  returnmess = response.data
           commit("GET_ISSUED_ITEM_RETURN_MESSAGE",returnmess)
       })   
    }
};

export default{
    state,
    getters, 
    mutations,
    actions
}