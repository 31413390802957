import Axios from "axios";

import {
  centerListForAttendenceReport,
  examRoutineDateForAttReport,
  attendanceReportSummary,
  downloadPracticalMarksData,
  downloadVivaMarksData,
} from "../../api";
import {
  GET_CENTER_LIST_FOR_ATT_REPORT,
  GET_ROUTIN_DATE_LIST_FOR_ATT_REPORT
} from "../../mutation-types";

const state = {
  centerDataList: [],
  routineDateList: []
};

const getters = {
  getCenterData(state) {
    return state.centerDataList;
  },

  getRoutineDate(state) {
    return state.routineDateList;
  }
};

const mutations = {
  [GET_CENTER_LIST_FOR_ATT_REPORT](state, response) {
    state.centerDataList = response;
  },
  [GET_ROUTIN_DATE_LIST_FOR_ATT_REPORT](state, response) {
    state.routineDateList = response;
  }
};

const actions = {
  getCenterData({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      Axios.get(centerListForAttendenceReport, {
        params: searchParam
      })
        .then(function(response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data.data;
          console.log("DA... : ", result);
          commit("GET_CENTER_LIST_FOR_ATT_REPORT", result);
          resolve(response.data);
        })
        .catch(function(error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  getRoutineDate({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      Axios.get(examRoutineDateForAttReport, {
        params: searchParam
      })
        .then(function(response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data.data;
          console.log("DA... : ", result);
          commit("GET_ROUTIN_DATE_LIST_FOR_ATT_REPORT", result);
          resolve(response.data);
        })
        .catch(function(error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  downloadAttendanceReportExl({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      Axios.get(attendanceReportSummary, {
        params: searchParam,
        responseType: "blob" // Set the response type to blob
      })
        .then(response => {
          commit("UNSET_LOADING"); // loading Closing
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "report_attendance_" +
              searchParam.exam_code +
              "_" +
              searchParam.exam_year +
              "_" +
              searchParam.exam_initiate_id +
              ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          resolve(response);
        })
        .catch(function(error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },
  cuWiseIncMarksDownloadPdf({ commit, state }, data) {
    commit("SET_LOADING", {
      color: "primary"
    });
    let url = `${cuWiseIncourse}`;
    return new Promise((resolve, reject) => {
      Axios.get(url, data).then(response => {
        console.log("cu response ", response);
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", "file.pdf"); //or any other extension
        // document.body.appendChild(link);
        // link.click();
      });
      commit("UNSET_LOADING");
    });
  },
  downloadPracticalReportExl({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      Axios.get(downloadPracticalMarksData, {
        params: searchParam,
        responseType: "blob" // Set the response type to blob
      })
        .then(response => {
          commit("UNSET_LOADING"); // loading Closing
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "report_practical_marks_" +
              searchParam.exam_code +
              "_" +
              searchParam.exam_year +
              "_" +
              searchParam.exam_initiate_id +
              ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          resolve(response);
        })
        .catch(function(error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },
  downloadVivaReportExl({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      Axios.get( downloadVivaMarksData, {
        params: searchParam,
        responseType: "blob" // Set the response type to blob
      })
        .then(response => {
          commit("UNSET_LOADING"); // loading Closing
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "report_viva_marks_" +
              searchParam.exam_code +
              "_" +
              searchParam.exam_year +
              "_" +
              searchParam.exam_initiate_id +
              ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          resolve(response);
        })
        .catch(function(error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
