import Axios from 'axios'
import {ExamStoteItemIssueToCenter} from '../../api'
import {GET_EXAM_STORE_ISSUE_ITEM_LIST,GET_ISSUE_ITEM_MESSAGE} from '../../mutation-types'

const state={
    examStoreItemIssueMess:{},
    examStoreItemIssueList:[]
};
const getters={
    getExamStoreItemIssueMess(state){
        return state.examStoreItemIssueMess;
    },
    getExamStoreItemIssueList(state){
        return state.examStoreItemIssueList;
    },
};
const mutations ={
    [GET_ISSUE_ITEM_MESSAGE](state, response){
        state.examStoreItemIssueMess = response;
    },
    [GET_EXAM_STORE_ISSUE_ITEM_LIST](state, response){
        state.examStoreItemIssueList = response;
    },
};
const actions={
    fetchExamStoreItemIssueList({commit}, searchParam){
         return Axios.get(ExamStoteItemIssueToCenter,  {
            params: searchParam,
        })
         .then((response)=>{
            var  issueList  = response.data.data.data;
            commit("GET_EXAM_STORE_ISSUE_ITEM_LIST",issueList)
         })
    },
    postExamStoreItemsissue({ commit }, issueItem){
        return Axios.post(ExamStoteItemIssueToCenter, issueItem)
        .then((response)=>{
            var issueMes = response.data;
            commit("GET_ISSUE_ITEM_MESSAGE",issueMes)
        })

        
    }
}

export default{
    state,
    getters,
    mutations,
    actions
}