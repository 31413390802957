<template>
<div>
    <!--Full width header Start-->
    <Header :fromHome="false"></Header>
    <!--Full width header End-->

    <!-- Main content Start -->
    <div class="main-content">
        <!-- Breadcrumbs Start -->
        <div class="rs-breadcrumbs breadcrumbs-overlay">
            <div class="breadcrumbs-img">
                <img :src="require('@/assets/images/breadcrumbs/1.jpg')" alt="Breadcrumbs Image" />
            </div>
            <div class="breadcrumbs-text white-color">
                <h1 class="page-title">Notice</h1>
                <ul>
                    <li>
                        <a class="active" href="index.html">Home</a>
                    </li>
                    <li>Notice</li>
                </ul>
            </div>
        </div>
        <!-- Breadcrumbs End -->

        <!-- About Section Start -->
        <div id="rs-about" class="rs-about style2 pt-50 pb-50 ">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <div class="sec-title">
                            <!-- <div class="sub-title">About NU (EMS)</div>
                <h3 class="title mb-2">Examination Management System (EMS)</h3>
                <div class=" mb-4">
                  The envisioned Examination Management System hereafter referred to as EMS is to
                  manage the various functions of Examination Division of National University with a
                  vision to enhance the overall efficiency.
                </div> -->
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-12 ">
                        <v-data-table disable-initial-sort :headers="noticeData" :items="getitemData" class="text-center">
                            <template v-slot:items="props">
                                <td>{{props.index + 1}}</td>
                                <td class="text-xs-left">{{ props.item.title }}</td>
                                <td class="text-xs-center">{{ props.item.updated_at }}</td>
                                <td class="text-xs-center">
                                    <button class="btn btn-danger" type="button" @click="viewPDF(props.item.attachment)" style="margin:10px;"><span style="font-size:22px; padding:2px;"><i class="fa fa-file-pdf-o" style="color:#fff;"></i></span></button>
                                </td>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- Main content End -->

    <Footer></Footer>
</div>
</template>

<script>
import Footer from "@/views/util/footer.vue";
import Header from "@/views/util/header.vue";
export default {
    data() {
        return {
            allNotice: [],
            noticeData: [{
                    text: 'SL',
                    align: 'left',
                    sortable: false,
                    value: 'name'
                },
                {
                    text: 'Notice',
                    value: 'title'
                },
                {
                    text: 'Date',
                    value: 'updated_at'
                },
                {
                    text: 'PDF',
                    value: ''
                },
            ],
        }
    },
    components: {
        // carousel
        Footer,
        Header,

    },
    created() {
        this.getNotice();
    },
    computed: {
        getitemData() {
            return this.$store.getters.AllNoticeList;
        },
    },
    mounted() {
        const script0 = document.createElement("script");
        script0.setAttribute(":src", require("@/assets/js/main.js"));
        script0.setAttribute("type", "text/babel");
        script0.async = true;
        document.head.appendChild(script0);
        const script1 = document.createElement("script");
        script1.setAttribute(":src", require("@/assets/js/jquery.min.js"));
        script1.setAttribute("type", "text/babel");
        script1.async = true;
        document.head.appendChild(script1);
        const script2 = document.createElement("script");
        script2.setAttribute(":src", require("@/assets/js/bootstrap.min.js"));
        script2.setAttribute("type", "text/babel");
        script2.async = true;
        document.head.appendChild(script2);
        const script3 = document.createElement("script");
        script3.setAttribute(":src", require("@/assets/js/rsmenu-main.js"));
        script3.setAttribute("type", "text/babel");
        script3.async = true;
        document.head.appendChild(script3);
    },
    methods: {
        goLogin() {
            this.$router.push("/login");
        },
        goAbout() {
            this.$router.push("/about");
        },
        goHome() {
            this.$router.push("/");
        },
        goManual() {
            this.$router.push("/user-manual");
        },
        goContact() {
            this.$router.push("/contact");
        },

        getNotice() {
            this.$store.dispatch('fatchAllNotice')
                .then((response) => response.json())
                .then((json) =>
                    this.allNotice = json.data
                ).then(() => {
                    console.log(this.allNotice, "Tanvir")
                });
        },
        viewPDF(pdf) {
            console.log(pdf)
            window.open(`http://103.113.200.45:8006/${pdf}`);
            
        }
    },
};
</script>

<style scoped src="@/assets/css/bootstrap.min.css"></style>
<style scoped src="@/assets/css/font-awesome.min.css"></style>
<style scoped src="@/assets/css/animate.css"></style>
<style scoped src="@/assets/css/rsmenu-main.css"></style>
<style scoped src="@/assets/css/style.css"></style>
<style scoped src="@/assets/css/responsive.css"></style>
