import Axios from 'axios';
import {
    examinerMonitoring
} from '../../api';
import {
    GET_EXAMINER_MONITORING
} from '../../mutation-types';

const state = {
    monitorExaminer: [],
};

const getters = {
    getMonitorExaminer(state) {
        return state.monitorExaminer;
    },
};

const mutations = {
    [GET_EXAMINER_MONITORING](state, response) {
        state.monitorExaminer = response;
    },
};

const actions = {
    fetchMonitoringExaminer({
        commit
    }, peram) {
        return Axios.get(examinerMonitoring, {
            params: {
                exam_initiate_id: peram.exam_initiate_id,
                subject_code: peram.subject_code,
                paper_code: peram.paper_code,
                tmis_id: peram.tmis_id,
                submited_date:peram.submited_date,
                page:peram.page,
                limit: peram.limit,
            }
        }).then((response) => {
            var data  =  response.data;
            commit("GET_EXAMINER_MONITORING", data);
            return data;
        });
    },
};


export default {
    state,
    getters,
    actions,
    mutations
};