import Axios from "axios";
import {
    getExamineeInfoChengeCenter,
    SaveExamineeChengeCenter,
    downloadExamWiseAttendanceSheetFromCenter
} from "../../api";
import {
    GET_CENTER_CHANGE_EXAMINEE_INFO,
    GET_CENTER_CHANGE_EXAMINEE_LIST
} from "../../mutation-types";


const state = {
    examineeInfoData: [],
    examineeInfoList: [],
};

const getters = {
    CenterTransferExamineeInfoDataSet(state) {
        return state.examineeInfoData;
    },
    CenterTransferExamineeList(state) {
        return state.examineeInfoList;
    },
};

const mutations = {
    [GET_CENTER_CHANGE_EXAMINEE_INFO](state, result) {
        state.examineeInfoData = result;
    },
    [GET_CENTER_CHANGE_EXAMINEE_LIST](state, result) {
        state.examineeInfoList = result;
    },
};

const actions = {
    getCenterTransferExamineeInfo({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary",
            });
            // loading  Opening
            Axios.get(getExamineeInfoChengeCenter, {
                params: searchParam,
            })
                .then(function (response) {
                    commit("UNSET_LOADING"); // loading Closing
                    commit("UNSET_SUMM_ROOM_INVI_WISE_DATA");
                    if (response.data.status == "error") {
                        console.log("....if");
                        resolve(response.data);
                    } else {
                        var result = response.data.data;
                        commit("GET_CENTER_CHANGE_EXAMINEE_INFO", result);
                        resolve(response.data);
                    }
                })
                .catch(function (error) {
                    commit("UNSET_LOADING"); // loading Closing
                    console.log(error);
                    reject();
                });
        });
    },

    saveCenterTransferExaminee({
        commit
    }, peram) {
        console.log("This is from api", peram);
        commit("SET_ACTION_PROCESS");
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "Primary"
            });
            Axios.post(SaveExamineeChengeCenter, peram)
                .then((response) => {
                    commit("UNSET_LOADING");
                    if (response.data.status == "success") {
                        console.log("Tanvir, Center change successfully!!");
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "green"
                        });
                        commit("GET_EXAMINEE_FOR_ABSENT", '');
                        commit("UNSET_FORMNOTIFY");
                        resolve(response.data.message);
                    } else {
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "red",
                            timeout: 10000
                        });
                        commit("UNSET_FORMNOTIFY", {
                            type: "error",
                            msg: response.data.message
                        });
                        reject(response.data.message);
                    }
                })
                .catch((error) => {
                    commit("UNSET_LOADING");
                    commit("SET_SNACKBAR", {
                        msg: "Center change failed. Please try again !!!",
                        color: "red"
                    });
                    reject(error)
                });
        });
    },

    getCenterTransferExamineeList({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary",
            });
            // loading  Opening
            Axios.get(SaveExamineeChengeCenter, {
                params: searchParam,
            })
                .then(function (response) {
                    commit("UNSET_LOADING"); // loading Closing
                    commit("UNSET_SUMM_ROOM_INVI_WISE_DATA");
                    if (response.data.status == "error") {
                        console.log("....if");
                        resolve(response.data);
                    } else {
                        var result = response.data.data;
                        commit("GET_CENTER_CHANGE_EXAMINEE_LIST", result);
                        resolve(response.data);
                    }
                })
                .catch(function (error) {
                    commit("UNSET_LOADING"); // loading Closing
                    console.log(error);
                    reject();
                });
        });
    },
    downloadExamWiseAttendanceSheetAtCenter({ commit }, item) {
        return new Promise((resolve, reject) => {
          commit("SET_LOADING", {
            color: "primary"
          });
          // loading  Opening
          Axios.get(downloadExamWiseAttendanceSheetFromCenter, {
            params: item
          })
            .then(function(response) {
              commit("UNSET_LOADING");
              var result = response.data.data;
              if (response.data.status === "success") {
                commit("SET_SNACKBAR", {
                  msg: response.data.message,
                  color: "green"
                });
                resolve(result);
              } else {
                commit("SET_SNACKBAR", {
                  msg: response.data.message,
                  color: "red"
                });
                // reject();
              }
              resolve(result);
            })
            .catch(function(error) {
              commit("UNSET_LOADING"); // loading Closing
              // console.log(error);
              reject();
            });
        });
      },
    

};

export default {
    state,
    getters,
    actions,
    mutations
};