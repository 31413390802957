import Axios from "axios";
import {
    getManualAbsenList,
    getManualAbsenRollSearch
} from "../../api";
import {
   
   
    GET_MANUAL_ABSENT_LIST,
    GET_EXAMINEE_FOR_ABSENT,
    SET_ACTION_PROCESS,
    SET_LOADING,
    UNSET_LOADING,
    SET_SNACKBAR,
    UNSET_FORMNOTIFY,
} from "../../mutation-types";

const state = {
    absentListData: [],
    examineeForAbsentData: [],
};

const getters = {
    getAbsentList() {
        return state.absentListData;
    },
    getAbsentExaminee() {
        return state.examineeForAbsentData;
    }
};

const mutations = {
    [GET_MANUAL_ABSENT_LIST](state, response) {
        return state.absentListData = response;
    },
    [GET_EXAMINEE_FOR_ABSENT](state, response) {
        return state.examineeForAbsentData = response
    },
};

const actions = {
    fetchMenualAbsentList({
        commit
    }, searchParam) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary"
            });
            // loading  Opening
            Axios.get(getManualAbsenList, {
                    params: searchParam,
                })
                .then(function (response) {
                    commit("UNSET_LOADING"); // loading Closing
                    var result = response.data.data;
                    console.log("Tanvir, this is Absent data->", result);
                    commit("GET_MANUAL_ABSENT_LIST", result);
                    resolve(response.data);
                })
                .catch(function (error) {
                    commit("UNSET_LOADING"); // loading Closing
                    console.log(error);
                    reject();
                });
        });
    },

    fetchExamineeDataByRoll({
        commit
    }, absentParam) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary"
            });
            // loading  Opening
            Axios.post(getManualAbsenRollSearch, absentParam)
                .then(function (response) {
                    commit("UNSET_LOADING"); // loading Closing
                    var result = response.data.data;
                    console.log("Tanvir, this is Absent data->", result);
                    commit("GET_EXAMINEE_FOR_ABSENT", result);
                    resolve(response.data);
                })
                .catch(function (error) {
                    commit("UNSET_LOADING"); // loading Closing
                    console.log(error);
                    reject();
                });
        });    
    },

    saveExamineeAbsentAttandance({
        commit
    }, peram) {
        console.log("This is from api", peram);
        commit("SET_ACTION_PROCESS");
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "Primary"
            });
            Axios.post(getManualAbsenList, peram)
                .then((response) => {
                    commit("UNSET_LOADING");
                    if (response.data.status == "success") {
                        console.log("Tanvir, absent data save successfully!!");
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "green"
                        });
                        commit("GET_EXAMINEE_FOR_ABSENT", '');
                        commit("UNSET_FORMNOTIFY");
                        resolve(response.data.message);
                    } else {
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "red",
                            timeout: 10000
                        });
                        commit("UNSET_FORMNOTIFY", {
                            type: "error",
                            msg: response.data.message
                        });
                        reject(response.data.message);
                    }
                })
                .catch((error) => {
                    commit("UNSET_LOADING");
                    commit("SET_SNACKBAR", {
                        msg: "Subject assigne failed. Please try again !!!",
                        color: "red"
                    });
                    reject(error)
                });
        });
    },

    fetchDeleteAbsentData({ commit }, id){
        commit("SET_ACTION_PROCESS");
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "Primary"
            });
            Axios.delete(getManualAbsenList + '/' + id)
                .then((response) => {
                    commit("UNSET_LOADING");
                    if (response.data.status == "success") {
                        console.log("Tanvir, Absent data deleted successfully!!");
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "green"
                        });
                        commit("UNSET_FORMNOTIFY");
                        resolve(response.data.message);
                    } else {
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "red",
                            timeout: 10000
                        });
                        commit("UNSET_FORMNOTIFY", {
                            type: "error",
                            msg: response.data.message
                        });
                        reject(response.data.message);
                    }
                })
                .catch((error) => {
                    commit("UNSET_LOADING");
                    commit("SET_SNACKBAR", {
                        msg: "Absent deleted. Please try again !!!",
                        color: "red"
                    });
                    reject(error)
                });
        });
    },
    
};

export default {
    state,
    getters,
    actions,
    mutations
};