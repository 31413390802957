import Axios from "axios";
import {
  getEmailSmsRecipents,
  postBulkSmsEmail,
  postBulkSmsEmailOthers,
} from "../../api";
import {
  GET_EMAIL_SMS_RECIPENTS,
} from "../../mutation-types";

const state = {
  recipents: [],
};

const getters = {
  getRecipents() {
    return state.recipents;
  },
};

const mutations = {
  [GET_EMAIL_SMS_RECIPENTS](state, response) {
    return state.recipents = response;
  },
};

const actions = {
  fetchEmailSmsRecipents({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      // loading  Opening
      console.log("Tanvir, this is Recipents Payload ->", searchParam);
      Axios.get(getEmailSmsRecipents, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data.data;
          // console.log("Tanvir, this is Recipents Data ->", result);
          commit("GET_EMAIL_SMS_RECIPENTS", result);
          resolve(response.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });

  },

  sendEmailSms({ commit }, searchParam) {
    console.log("This is from api", searchParam);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary"
      });
      Axios.post(postBulkSmsEmail, searchParam)
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Tanvir, Email sent successfully!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green"
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Email or SMS not sent !!!",
            color: "red"
          });
          reject(error)
        });
    });
  },

  sendEmailSmsOthers({ commit }, searchParam) {
    console.log("This is from api", searchParam);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary"
      });
      Axios.post(postBulkSmsEmailOthers, searchParam)
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Tanvir, Email sent successfully!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green"
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Email or SMS not sent !!!",
            color: "red"
          });
          reject(error)
        });
    });
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};