import Axios from "axios";
import {
    ExamSummaryToday,
    PeparWiseAbsentExamineeList,
    centerChangeAttList,
} from "../../api";
import {
    GET_TODAY_EXAM_SUMMARY,
    GET_PEPAR_WISE_ABSENT_EXAMINEE_LIST,
    GET__PEPAR_WISE_ABSENT_EXAMINEE_LIST_PDF,
    CENTER_CHANGE_DATA,
} from "../../mutation-types";


const state = {
    todayExamSummaryData: [],
    todayExamSummaryDataPDF: [],
    absentListPeparWise: [],
    centerChange:[],
};
const getters = {
    getTodayExamSummaryData(state) {
        return state.todayExamSummaryData;
    },

    getTodayExamSummaryDataPDF(state) {
        return state.todayExamSummaryDataPDF;
    },

    getPeparWiseAbsentExamineeData(state) {
        return state.absentListPeparWise;
    },

    getCenterChangeExamineeAttData(state) {
        return state.centerChange;
    },
};
const mutations = {
    [GET_TODAY_EXAM_SUMMARY](state, response) {
        state.todayExamSummaryData = response;
    },

    [GET__PEPAR_WISE_ABSENT_EXAMINEE_LIST_PDF](state, response) {
        state.todayExamSummaryDataPDF = response;
    },

    [GET_PEPAR_WISE_ABSENT_EXAMINEE_LIST](state, response) {
        state.absentListPeparWise = response;
    },

    [CENTER_CHANGE_DATA](state, response) {
        state.centerChange = response;
    },

};
const actions = {
    fetchTodayExamSummaryData({
        commit
    }, peram) {
       return Axios.get(ExamSummaryToday, {
            params: {
                exam_initiate_id: peram.exam_initiate_id,
                center_code: peram.center_code,
                attendance_date: peram.attendance_date,
                download: peram.download
            }
        }).then((response) => {
            var data = response.data.data;
            if(peram.download == 0){
                commit("GET_TODAY_EXAM_SUMMARY", data);
            }else{
                console.log("TANVIR PDF 2========>", data);
                commit("GET__PEPAR_WISE_ABSENT_EXAMINEE_LIST_PDF", data);
            }
            return data;
        })
    },

    fetchExamineeabsentListPeparwise({
        commit
    }, peram) {
        Axios.get(PeparWiseAbsentExamineeList, {
            params: {
                exam_initiate_id: peram.exam_initiate_id,
                center_code: peram.center_code,
                limit: peram.limit,
                attendance_date: peram.attendance_date,
                download: peram.download,
                subject_code: peram.subject_code,
                paper_code: peram.paper_code,
                page:peram.page
            }
        }).then((response)=>{
            var data = response.data.data;
            commit("GET_PEPAR_WISE_ABSENT_EXAMINEE_LIST",data);

            return data;
        });

    },

    fetchCenterChangeAttListPeparwise({
        commit
    }, peram) {
        Axios.get(centerChangeAttList, {
            params: {
                exam_initiate_id: peram.exam_initiate_id,
                center_code: peram.center_code,
                limit: peram.limit,
                attendance_date: peram.attendance_date,
                paper_code: peram.paper_code,
                page:peram.page,
                exam_code: peram.exam_code,
                exam_year: peram.exam_year,
            }
        }).then((response)=>{
            var data = response.data.data;
            commit("CENTER_CHANGE_DATA",data);

            return data;
        });

    },
};

export default {
    state,
    getters,
    actions,
    mutations
};