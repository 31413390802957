import Axios from "axios";
import {
  
} from "../../mutation-types";
import {
 
} from "../../api";

const state = {
  
};

const getters = {
  
};

const mutations = {
  
};

const actions = {
  
};

export default {
  state,
  getters,
  actions,
  mutations,
};
