/* eslint-disable prettier/prettier */
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const UNSET_LOGGED_IN = "UNSET_LOGGED_IN";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const UNSET_USER_TOKEN = "UNSET_USER_TOKEN";
export const SET_MESSAGE = "SET_MESSAGE";
export const UNSET_MESSAGE = "UNSET_MESSAGE";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const UNSET_AUTH_USER = "UNSET_AUTH_USER";
export const SET_ACTION_PROCESS = "SET_ACTION_PROCESS";
export const UNSET_ACTION_PROCESS = "UNSET_ACTION_PROCESS";

export const UNSET_ALL_LOGIN_STATE = "UNSET_ALL_LOGIN_STATE";

//------------ Student Login  -------------
export const SET_STUDENT_LOGGED_IN = "SET_STUDENT_LOGGED_IN";
export const UNSET_STUDENT_LOGGED_IN = "UNSET_STUDENT_LOGGED_IN";
export const SET_AUTH_STUDENT = "SET_AUTH_STUDENT";
export const UNSET_AUTH_STUDENT = "UNSET_AUTH_STUDENT";
export const SET_STUDENT_TOKEN = "SET_STUDENT_TOKEN";
export const UNSET_STUDENT_TOKEN = "UNSET_STUDENT_TOKEN";
export const SET_AUTH_STUDENT_ACADEMIC_INFO = "SET_AUTH_STUDENT_ACADEMIC_INFO";
export const UNSET_AUTH_STUDENT_ACADEMIC_INFO =
  "UNSET_AUTH_STUDENT_ACADEMIC_INFO";
export const SET_STUDENT_PROFILE = "SET_STUDENT_PROFILE";
export const UNSET_STUDENT_PROFILE = "UNSET_STUDENT_PROFILE";
export const UNSET_ALL_STUDENT_LIST_DATA = "UNSET_ALL_STUDENT_LIST_DATA";
// UNSET_ALL_STUDENT_LIST_DATA
//------------ Table Name Userinfo  -------------
export const USERINFO_UNSET_ALL_STATE = "USERINFO_UNSET_ALL_STATE";
export const USERINFO_SET_MSG = "USERINFO_SET_MSG";
export const USERINFO_UNSET_MSG = "USERINFO_UNSET_MSG";
export const USERINFO_SET_SCHEMA = "USERINFO_SET_SCHEMA";
export const USERINFO_UNSET_SCHEMA = "USERINFO_UNSET_SCHEMA";
export const USERINFO_SET_ACTION_PROCESS = "USERINFO_SET_ACTION_PROCESS";
export const USERINFO_UNSET_ACTION_PROCESS = "USERINFO_UNSET_ACTION_PROCESS";
export const USERINFO_SET_LIST_DATA = "USERINFO_SET_LIST_DATA";
export const USERINFO_UNSET_LIST_DATA = "USERINFO_UNSET_LIST_DATA";
export const USERINFO_SET_ACTION_STATUS = "USERINFO_SET_ACTION_STATUS";
export const USERINFO_UNSET_ACTION_STATUS = "USERINFO_UNSET_ACTION_STATUS";

//------------ Item Js  -------------
export const SET_ITEM_DATA = "SET_ITEM_DATA";
export const ADD_ITEM_DATA = "ADD_ITEM_DATA";
export const EDIT_ITEM_DATA = "EDIT_ITEM_DATA";
export const DELETE_ITEM_DATA = "DELETE_ITEM_DATA";
export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_SCHEMA_DATA = "SET_SCHEMA_DATA";
export const UNSET_SCHEMA_DATA = "UNSET_SCHEMA_DATA";

//chunk
export const ADD_CHUNK_DATA = "ADD_CHUNK_DATA";
export const SET_CHUNK_STUDENT_DATA = "SET_CHUNK_STUDENT_DATA";
export const SET_STUDENT_APPLICATION_DATA = "SET_STUDENT_APPLICATION_DATA";
export const SET_STUDENT_APPLICATION_PAPER_DATA = "SET_STUDENT_APPLICATION_PAPER_DATA";
export const SET_CHUNK_STUDENT_SCHEMA = "SET_CHUNK_STUDENT_SCHEMA";
export const SET_CHUNK_STUDENT_PAPER_SCHEMA = "SET_CHUNK_STUDENT_PAPER_SCHEMA";
export const UNSET_CHUNK_STUDENT_SCHEMA = "UNSET_CHUNK_STUDENT_SCHEMA";
export const UNSET_CHUNK_STUDENT_PAPER_SCHEMA = "UNSET_CHUNK_STUDENT_PAPER_SCHEMA";
export const SET_CATEGORY_DATA = "SET_CATEGORY_DATA";
export const SET_APPLIED_STUDENT_PAPER_SCHEMA = "SET_APPLIED_STUDENT_PAPER_SCHEMA";

//--------invigilator Js-----------
export const SET_INVIGILATOR_DATA = "SET_INVIGILATOR_DATA";
export const ADD_INVIGILATOR_DATA = "ADD_INVIGILATOR_DATA";
export const SET_INVIGILATOR_SCHEMA = "SET_INVIGILATOR_SCHEMA";
export const UNSET_INVIGILATOR_SCHEMA = "UNSET_INVIGILATOR_SCHEMA";

//------------ Center Js  -------------
export const SET_CENTER_DATA = "SET_CENTER_DATA";
export const ADD_CENTER_DATA = "ADD_CENTER_DATA";
export const SET_CENTER_SCHEMA_DATA = "SET_CENTER_SCHEMA_DATA";
export const UNSET_CENTER_SCHEMA_DATA = "UNSET_CENTER_SCHEMA_DATA";
export const SET_CENTER_PROFILE_DATA = "SET_CENTER_PROFILE_DATA";

//------------ Transcript Js  -------------
export const SET_TRANSCRIPT_GEN_STUDENT_LIST_DATA =
  "SET_TRANSCRIPT_GEN_STUDENT_LIST_DATA";
export const ADD_TRANSCRIPT_GEN_STUDENT_DATA =
  "ADD_TRANSCRIPT_GEN_STUDENT_DATA";
export const SET_TRANSCRIPT_LIST_DATA = "SET_TRANSCRIPT_LIST_DATA";
export const SET_TRANSCRIPT_SCHEMA = "SET_TRANSCRIPT_SCHEMA";
export const UNSET_TRANSCRIPT_SCHEMA = "UNSET_TRANSCRIPT_SCHEMA";

//marksheet
export const SET_MARKSHEET_GEN_STUDENT_LIST_DATA =
  "SET_MARKSHEET_GEN_STUDENT_LIST_DATA";
export const SET_MARKSHEET_LIST_DATA = "SET_MARKSHEET_LIST_DATA";
export const ADD_MARKSHEET_GEN_STUDENT_DATA = "ADD_MARKSHEET_GEN_STUDENT_DATA";

//pvdashboard
export const SET_PV_DASH_DATA = "SET_PV_DASH_DATA";
export const SET_PRACTICAL_LIST_SUMMARY_DATA =
  "SET_PRACTICAL_LIST_SUMMARY_DATA";
export const SET_VIVA_DASH_DATA = "SET_VIVA_DASH_DATA";
export const SET_VIVA_LIST_SUMMARY_DATA = "SET_VIVA_LIST_SUMMARY_DATA";

//------------ Certificate generate  -------------
export const SET_CER_GEN_STUDENT_LIST_DATA = "SET_CER_GEN_STUDENT_LIST_DATA";
export const ADD_CER_GEN_STUDENT_DATA = "ADD_CER_GEN_STUDENT_DATA";
export const SET_CERTIFICATE_SCHEMA = "SET_CERTIFICATE_SCHEMA";
export const UNSET_CERTIFICATE_SCHEMA = "UNSET_CERTIFICATE_SCHEMA";
export const SET_CERTIFICATE_LIST_DATA = "SET_CERTIFICATE_LIST_DATA";

//unused script statmet
export const SET_US_STATEMENT_DATA = "SET_US_STATEMENT_DATA";
export const SET_SUMMARY_DATA = "SET_SUMMARY_DATA";

//------------ CenterUser Js  -------------
export const SET_CENTER_USER_DATA = "SET_CENTER_USER_DATA";
export const ADD_CENTER_USER_DATA = "ADD_CENTER_USER_DATA";
export const SET_CENTER_USER_SCHEMA = "SET_CENTER_USER_SCHEMA";
export const UNSET_CENTER_USER_SCHEMA = "UNSET_CENTER_USER_SCHEMA";

export const SET_RESCRUTINER_LIST_DATA = "SET_RESCRUTINER_LIST_DATA";
export const ADD_RESCRUTINER_DATA = "ADD_RESCRUTINER_DATA";
export const SET_RESCRUTINER_SCHEMA = "SET_RESCRUTINER_SCHEMA";
export const UNSET_RESCRUTINER_SCHEMA = "UNSET_RESCRUTINER_SCHEMA";

export const SET_SCRUTINER_LIST_DATA = "SET_SCRUTINER_LIST_DATA";
export const ADD_SCRUTIER_DATA = "ADD_SCRUTIER_DATA";
export const SET_SCRUTINER_SCHEMA = "SET_SCRUTINER_SCHEMA";
export const UNSET_SCRUTINER_SCHEMA = "UNSET_SCRUTINER_SCHEMA";

//------------ Attendance Js  -------------
export const SET_ATTENDANCE_DATA = "SET_ATTENDANCE_DATA";
export const SET_STD_ATTENDANCE_DATA = "SET_STD_ATTENDANCE_DATA";
export const SET_ATTENDANCE_DATA_BY_EY_MAIN = "SET_ATTENDANCE_DATA_BY_EY_MAIN";
export const SET_ATTENDANCE_DATA_BY_EY_DETAILS =
  "SET_ATTENDANCE_DATA_BY_EY_DETAILS";

export const SET_ATTENDANCE_BY_EY_CURRENT_ITEM_DETAILS =
  "SET_ATTENDANCE_BY_EY_CURRENT_ITEM_DETAILS";

export const SET_PRESENT_EXAMINE_LIST = "SET_PRESENT_EXAMINE_LIST";  
export const UN_SET_PRESENT_EXAMINE_LIST = "UN_SET_PRESENT_EXAMINE_LIST";  

//------------ Answer script Collection  -------------
export const SET_ANSCOLLECTION_DATA = "SET_ANSCOLLECTION_DATA";
export const ADD_ANSCOLLECTION_DATA = "ADD_ANSCOLLECTION_DATA";
export const SET_ANSCOLLECTION_SCHEMA = "SET_ANSCOLLECTION_SCHEMA";
export const UNSET_ANSCOLLECTION_SCHEMA = "UNSET_ANSCOLLECTION_SCHEMA";

//------------ Answer script Distribution  -------------
export const SET_ANSDISTRIBUTION_DATA = "SET_ANSDISTRIBUTION_DATA";
export const ADD_ANSDISTRIBUTION_DATA = "ADD_ANSDISTRIBUTION_DATA";
export const SET_ANSDISTRIBUTION_SCHEMA = "SET_ANSDISTRIBUTION_SCHEMA";
export const UNSET_ANSDISTRIBUTION_SCHEMA = "UNSET_ANSDISTRIBUTION_SCHEMA";

//------------ DIVISION  -------------
export const SET_DIVISION_LIST_DATA = "SET_DIVISION_LIST_DATA";
export const ADD_DIVISION_DATA = "ADD_DIVISION_DATA";
export const SET_DIVISION_SCHEMA = "SET_DIVISION_SCHEMA";
export const UNSET_DIVISION_SCHEMA = "UNSET_DIVISION_SCHEMA";
// year
export const SET_YEAR_LIST_DATA = "SET_YEAR_LIST_DATA";
export const ADD_YEAR_DATA = "ADD_YEAR_DATA";
export const SET_YEAR_SCHEMA = "SET_YEAR_SCHEMA";
export const UNSET_YEAR_SCHEMA = "UNSET_YEAR_SCHEMA";
// off day
export const SET_OFFDAY_LIST_DATA = "SET_OFFDAY_LIST_DATA";
export const ADD_OFFDAY_DATA = "ADD_OFFDAY_DATA";
export const SET_OFFDAY_SCHEMA = "SET_OFFDAY_SCHEMA";
export const UNSET_OFFDAY_SCHEMA = "UNSET_OFFDAY_SCHEMA";

//------------ DISTRICT -------------
export const SET_DISTRICT_LIST_DATA = "SET_DISTRICT_LIST_DATA";
export const ADD_DISTRICT_DATA = "ADD_DISTRICT_DATA";
export const SET_DISTRICT_SCHEMA = "SET_DISTRICT_SCHEMA";
export const UNSET_DISTRICT_SCHEMA = "UNSET_DISTRICT_SCHEMA";

//------------ DISTRICT CONTROLLER -------------
export const SET_DISTRICT_CONTROLLER_LIST_DATA =
  "SET_DISTRICT_CONTROLLER_LIST_DATA";
export const ADD_DISTRICT_CONTROLLER_DATA = "ADD_DISTRICT_CONTROLLER_DATA";
export const SET_DISTRICT_CONTROLLER_SCHEMA = "SET_DISTRICT_CONTROLLER_SCHEMA";
export const UNSET_DISTRICT_CONTROLLER_SCHEMA =
  "UNSET_DISTRICT_CONTROLLER_SCHEMA";

//PV Routine
export const SET_PV_ROUTINE_DATA = "SET_PV_ROUTINE_DATA";
export const ADD_PV_ROUTINE_DATA = "ADD_PV_ROUTINE_DATA";
export const SET_PV_ROUTINE_SCHEMA = "SET_PV_ROUTINE_SCHEMA";
export const UNSET_PV_ROUTINE_SCHEMA = "UNSET_PV_ROUTINE_SCHEMA";

//------------ Blank Script Collect Js  -------------
export const SET_BLANK_SCRIPT_COLLECT_DATA = "SET_BLANK_SCRIPT_COLLECT_DATA";
export const ADD_BLANK_SCRIPT_COLLECT_DATA = "ADD_BLANK_SCRIPT_COLLECT_DATA";
export const SET_BLANK_SCRIPT_COLLECT_SCHEMA =
  "SET_BLANK_SCRIPT_COLLECT_SCHEMA";
export const UNSET_BLANK_SCRIPT_COLLECT_SCHEMA =
  "UNSET_BLANK_SCRIPT_COLLECT_SCHEMA";

//------------ Blank Script Distribute Js  -------------
export const SET_BLANK_SCRIPT_DISTRIBUTE_DATA =
  "SET_BLANK_SCRIPT_DISTRIBUTE_DATA";
export const ADD_BLANK_SCRIPT_DISTRIBUTE_DATA =
  "ADD_BLANK_SCRIPT_DISTRIBUTE_DATA";
export const SET_BLANK_SCRIPT_DISTRIBUTE_SCHEMA =
  "SET_BLANK_SCRIPT_DISTRIBUTE_SCHEMA";
export const UNSET_BLANK_SCRIPT_DISTRIBUTE_SCHEMA =
  "UNSET_BLANK_SCRIPT_DISTRIBUTE_SCHEMA";

//------------ Common Api Js  -------------
export const SET_EXAM_DATA = "SET_EXAM_DATA";
export const UN_SET_EXAM_DATA = "UN_SET_EXAM_DATA";

export const SET_COURSE_DATA = "SET_COURSE_DATA";
// export const SET_SUBJECT_DATA = "SET_SUBJECT_DATA";
export const SET_TEACHER_DATA = "SET_TEACHER_DATA";
export const SET_STUDENT_DATA = "SET_STUDENT_DATA";
export const SET_CENTERLIST_DATA = "SET_CENTERLIST_DATA";
export const SET_REGCENTER_DATA = "SET_REGCENTER_DATA";
export const SET_COLLEGE_DATA = "SET_COLLEGE_DATA";
export const SET_DEGREE_DATA = "SET_DEGREE_DATA";
export const SET_PAPER_DATA = "SET_PAPER_DATA";
export const SET_PAPER_BYRESCRUTINER_DATA = "SET_PAPER_BYRESCRUTINER_DATA";
export const SET_EXAM_WISE_PAPER_DATA = "SET_EXAM_WISE_PAPER_DATA";
export const SET_DIVISION_DATA = "SET_DIVISION_DATA";
export const SET_DISTRICT_DATA = "SET_DISTRICT_DATA";
export const SET_SUBJECT_DATA = "SET_SUBJECT_DATA";
export const SET_THANA_DATA = "SET_THANA_DATA";
//------------ Practical Viva Exam  -------------
export const SET_PRACTICALVIVAEXAM_DATA = "SET_PRACTICALVIVAEXAM_DATA";
export const ADD_PRACTICALVIVAEXAM_DATA = "ADD_PRACTICALVIVAEXAM_DATA";
export const SET_PRACTICALVIVAEXAM_SCHEMA = "SET_PRACTICALVIVAEXAM_SCHEMA";
export const UNSET_PRACTICALVIVAEXAM_SCHEMA = "UNSET_PRACTICALVIVAEXAM_SCHEMA";
export const SET_PV_CENTER_DATA = "SET_PV_CENTER_DATA";
export const SET_PRACTICAL_MARK_DATA = "SET_PRACTICAL_MARK_DATA";
export const SET_PRACTICAL_EXAM_DATA = "SET_PRACTICAL_EXAM_DATA";
export const SET_VIVA_MARK_DATA = "SET_VIVA_MARK_DATA";
export const SET_VIVA_EXAM_DATA = "SET_VIVA_EXAM_DATA";
export const UNSET_PRACTICAL_MARK_DATA = "UNSET_PRACTICAL_MARK_DATA";
export const UNSET_VIVA_MARK_DATA = "UNSET_VIVA_MARK_DATA";

//pvmarks log
export const SET_EDITED_LOG_DATA = "SET_EDITED_LOG_DATA";
export const SET_VIVA_EDITED_LOG_DATA = "SET_VIVA_EDITED_LOG_DATA";

//------------ Regional Center  -------------
export const SET_REGIONAL_CENTER_DATA = "SET_REGIONAL_CENTER_DATA";
export const ADD_REGIONAL_CENTER_DATA = "ADD_REGIONAL_CENTER_DATA";
export const SET_REGIONAL_CENTER_SCHEMA = "SET_REGIONAL_CENTER_SCHEMA";
export const UNSET_REGIONAL_CENTER_SCHEMA = "UNSET_REGIONAL_CENTER_SCHEMA";
//grading system
export const SET_GRADING_SYSTEM_DATA = "SET_GRADING_SYSTEM_DATA";
export const ADD_GRADINMG_SYSTEM_DATA = "ADD_GRADINMG_SYSTEM_DATA";
export const SET_GRADINMG_SYSTEM_SCHEMA = "SET_GRADINMG_SYSTEM_SCHEMA";
export const UNSET_GRADINMG_SYSTEM_SCHEMA = "UNSET_GRADINMG_SYSTEM_SCHEMA";


//------------ Regional Center User -------------
export const SET_REGIONAL_CENTER_USER_DATA = "SET_REGIONAL_CENTER_USER_DATA";
export const ADD_REGIONAL_CENTER_USER_DATA = "ADD_REGIONAL_CENTER_USER_DATA";
export const SET_REGIONAL_CENTER_USER_SCHEMA =
  "SET_REGIONAL_CENTER_USER_SCHEMA";
export const UNSET_REGIONAL_CENTER_USER_SCHEMA =
  "UNSET_REGIONAL_CENTER_USER_SCHEMA";

//------------ Blank Script Collect From Reg  -------------
export const SET_BS_COLLECT_FROM_REG_DATA = "SET_BS_COLLECT_FROM_REG_DATA";
export const ADD_BS_COLLECT_FROM_REG_DATA = "ADD_BS_COLLECT_FROM_REG_DATA";
export const SET_BS_COLLECT_FROM_REG_SCHEMA = "SET_BS_COLLECT_FROM_REG_SCHEMA";
export const UNSET_BS_COLLECT_FROM_REG_SCHEMA =
  "UNSET_BS_COLLECT_FROM_REG_SCHEMA";

//------------ Answer Script Distribution To Reg  -------------
export const SET_ANS_DISTRIBUTION_TO_REG_DATA =
  "SET_ANS_DISTRIBUTION_TO_REG_DATA";
export const ADD_ANS_DISTRIBUTION_TO_REG_DATA =
  "ADD_ANS_DISTRIBUTION_TO_REG_DATA";
export const SET_ANS_DISTRIBUTION_TO_REG_SCHEMA =
  "SET_ANS_DISTRIBUTION_TO_REG_SCHEMA";
export const UNSET_ANS_DISTRIBUTION_TO_REG_SCHEMA =
  "UNSET_ANS_DISTRIBUTION_TO_REG_SCHEMA";

//---- Regional center mutations -------//
export const SET_REG_BS_COLL_FROM_NU_DATA = "SET_REG_BS_COLL_FROM_NU_DATA";
export const ADD_REG_BS_COLL_FROM_NU_DATA = "ADD_REG_BS_COLL_FROM_NU_DATA";
export const SET_REG_BS_COLL_FROM_NU_SCHEMA = "SET_REG_BS_COLL_FROM_NU_SCHEMA";
export const UNSET_REG_BS_COLL_FROM_NU_SCHEMA =
  "UNSET_REG_BS_COLL_FROM_NU_SCHEMA";

export const SET_REG_BS_DIST_TO_CEN_DATA = "SET_REG_BS_DIST_TO_CEN_DATA";
export const ADD_REG_BS_DIST_TO_CEN_DATA = "ADD_REG_BS_DIST_TO_CEN_DATA";
export const SET_REG_BS_DIST_TO_CEN_SCHEMA = "SET_REG_BS_DIST_TO_CEN_SCHEMA";
export const UNSET_REG_BS_DIST_TO_CEN_SCHEMA =
  "UNSET_REG_BS_DIST_TO_CEN_SCHEMA";

export const SET_REG_AS_COLL_FROM_CEN_DATA = "SET_REG_AS_COLL_FROM_CEN_DATA";
export const ADD_REG_AS_COLL_FROM_CEN_DATA = "ADD_REG_AS_COLL_FROM_CEN_DATA";
export const SET_REG_AS_COLL_FROM_CEN_SCHEMA =
  "SET_REG_AS_COLL_FROM_CEN_SCHEMA";
export const UNSET_REG_AS_COLL_FROM_CEN_SCHEMA =
  "UNSET_REG_AS_COLL_FROM_CEN_SCHEMA";

export const SET_REG_AS_DIST_TO_NU_DATA = "SET_REG_AS_DIST_TO_NU_DATA";
export const ADD_REG_AS_DIST_TO_NU_DATA = "ADD_REG_AS_DIST_TO_NU_DATA";
export const SET_REG_AS_DIST_TO_NU_SCHEMA = "SET_REG_AS_DIST_TO_NU_SCHEMA";
export const UNSET_REG_AS_DIST_TO_NU_SCHEMA = "UNSET_REG_AS_DIST_TO_NU_SCHEMA";

export const SET_REG_AS_COLL_FROM_NU_DATA = "SET_REG_AS_COLL_FROM_NU_DATA";
export const ADD_REG_AS_COLL_FROM_NU_DATA = "ADD_REG_AS_COLL_FROM_NU_DATA";
export const SET_REG_AS_COLL_FROM_NU_SCHEMA = "SET_REG_AS_COLL_FROM_NU_SCHEMA";
export const UNSET_REG_AS_COLL_FROM_NU_SCHEMA =
  "UNSET_REG_AS_COLL_FROM_NU_SCHEMA";

export const SET_REG_AS_DIST_TO_EXAM_DATA = "SET_REG_AS_DIST_TO_EXAM_DATA";
export const ADD_REG_AS_DIST_TO_EXAM_DATA = "ADD_REG_AS_DIST_TO_EXAM_DATA";
export const SET_REG_AS_DIST_TO_EXAM_SCHEMA = "SET_REG_AS_DIST_TO_EXAM_SCHEMA";
export const UNSET_REG_AS_DIST_TO_EXAM_SCHEMA =
  "UNSET_REG_AS_DIST_TO_EXAM_SCHEMA";

export const SET_REG_AS_COLL_FROM_EXAM_DATA = "SET_REG_AS_COLL_FROM_EXAM_DATA";
export const ADD_REG_AS_COLL_FROM_EXAM_DATA = "ADD_REG_AS_COLL_FROM_EXAM_DATA";
export const SET_REG_AS_COLL_FROM_EXAM_SCHEMA =
  "SET_REG_AS_COLL_FROM_EXAM_SCHEMA";
export const UNSET_REG_AS_COLL_FROM_EXAM_SCHEMA =
  "UNSET_REG_AS_COLL_FROM_EXAM_SCHEMA";

export const SET_REG_AS_DIST_TO_NU_FINAL_DATA =
  "SET_REG_AS_DIST_TO_NU_FINAL_DATA";
export const ADD_REG_AS_DIST_TO_NU_FINAL_DATA =
  "ADD_REG_AS_DIST_TO_NU_FINAL_DATA";
export const SET_REG_AS_DIST_TO_NU_FINAL_SCHEMA =
  "SET_REG_AS_DIST_TO_NU_FINAL_SCHEMA";
export const UNSET_REG_AS_DIST_TO_NU_FINAL_SCHEMA =
  "UNSET_REG_AS_DIST_TO_NU_FINAL_SCHEMA";
//loose
export const SET_REG_LS_DIST_TO_NU_DATA = "SET_REG_LS_DIST_TO_NU_DATA";
export const ADD_REG_LS_DIST_TO_NU_DATA = "ADD_REG_LS_DIST_TO_NU_DATA";
export const SET_REG_LS_DIST_TO_NU_SCHEMA = "SET_REG_LS_DIST_TO_NU_SCHEMA";
export const UNSET_REG_LS_DIST_TO_NU_SCHEMA = "UNSET_REG_LS_DIST_TO_NU_SCHEMA";

//Token Verification
export const SET_TOKEN_VERIFY_DATA = "SET_TOKEN_VERIFY_DATA";
export const ADD_TOKEN_VERIFY_DATA = "ADD_TOKEN_VERIFY_DATA";
export const SET_TOKEN_VERIFY_SCHEMA = "SET_TOKEN_VERIFY_SCHEMA";
export const SET_VARIFIED_EXAMINER = "SET_VARIFIED_EXAMINER";

//---- Center mutations -------//
export const SET_CEN_BS_COLL_FROM_REG_DATA = "SET_CEN_BS_COLL_FROM_REG_DATA";
export const ADD_CEN_BS_COLL_FROM_REG_DATA = "ADD_CEN_BS_COLL_FROM_REG_DATA";
export const SET_CEN_BS_COLL_FROM_REG_SCHEMA =
  "SET_CEN_BS_COLL_FROM_REG_SCHEMA";

export const UNSET_CEN_BS_COLL_FROM_REG_SCHEMA =
  "UNSET_CEN_BS_COLL_FROM_REG_SCHEMA";
export const SET_TOTAL_CENTER_STATUS_IN = "SET_TOTAL_CENTER_STATUS_IN"; //statistics

export const SET_CEN_AS_DIST_TO_REG_DATA = "SET_CEN_AS_DIST_TO_REG_DATA";
export const ADD_CEN_AS_DIST_TO_REG_DATA = "ADD_CEN_AS_DIST_TO_REG_DATA";
export const SET_CEN_AS_DIST_TO_REG_SCHEMA = "SET_CEN_AS_DIST_TO_REG_SCHEMA";
export const UNSET_CEN_AS_DIST_TO_REG_SCHEMA =
  "UNSET_CEN_AS_DIST_TO_REG_SCHEMA";

export const SET_LS_DIST_TO_REG_DATA = "SET_LS_DIST_TO_REG_DATA";
export const ADD_LS_DIST_TO_REG_DATA = "ADD_LS_DIST_TO_REG_DATA";
export const SET_LS_DIST_TO_REG_SCHEMA = "SET_LS_DIST_TO_REG_SCHEMA";
export const UNSET_LS_DIST_TO_REG_SCHEMA = "UNSET_LS_DIST_TO_REG_SCHEMA";

//----NU mutations-----//
export const SET_NU_AS_DIST_TO_REG_DATA = "SET_NU_AS_DIST_TO_REG_DATA";
export const ADD_NU_AS_DIST_TO_REG_DATA = "ADD_NU_AS_DIST_TO_REG_DATA";
export const SET_NU_AS_DIST_TO_REG_SCHEMA = "SET_NU_AS_DIST_TO_REG_SCHEMA";
export const UNSET_NU_AS_DIST_TO_REG_SCHEMA = "UNSET_NU_AS_DIST_TO_REG_SCHEMA";

export const SET_NU_AS_COLL_FROM_REG_FINAL_DATA =
  "SET_NU_AS_COLL_FROM_REG_FINAL_DATA";
export const ADD_NU_AS_COLL_FROM_REG_FINAL_DATA =
  "ADD_NU_AS_COLL_FROM_REG_FINAL_DATA";
export const SET_NU_AS_COLL_FROM_REG_FINAL_SCHEMA =
  "SET_NU_AS_COLL_FROM_REG_FINAL_SCHEMA";
export const UNSET_NU_AS_COLL_FROM_REG_FINAL_SCHEMA =
  "UNSET_NU_AS_COLL_FROM_REG_FINAL_SCHEMA";

//---- Examiner mutations -------//
export const SET_EXAM_AS_COLL_FROM_REG_DATA = "SET_EXAM_AS_COLL_FROM_REG_DATA";
export const ADD_EXAM_AS_COLL_FROM_REG_DATA = "ADD_EXAM_AS_COLL_FROM_REG_DATA";
export const SET_EXAM_AS_COLL_FROM_REG_SCHEMA =
  "SET_EXAM_AS_COLL_FROM_REG_SCHEMA";
export const UNSET_EXAM_AS_COLL_FROM_REG_SCHEMA =
  "UNSET_EXAM_AS_COLL_FROM_REG_SCHEMA";

export const SET_EXAM_AS_DIST_TO_REG_DATA = "SET_EXAM_AS_DIST_TO_REG_DATA";
export const ADD_EXAM_AS_DIST_TO_REG_DATA = "ADD_EXAM_AS_DIST_TO_REG_DATA";
export const SET_EXAM_AS_DIST_TO_REG_SCHEMA = "SET_EXAM_AS_DIST_TO_REG_SCHEMA";
export const UNSET_EXAM_AS_DIST_TO_REG_SCHEMA =
  "UNSET_EXAM_AS_DIST_TO_REG_SCHEMA";

export const SET_TOTAL_STATUS_IN = "SET_TOTAL_STATUS_IN";

// examiner
export const SET_EXAMINER_DATA = "SET_EXAMINER_DATA";
export const SET_EXAMINER_DATA_BY_TYPE = "SET_EXAMINER_DATA_BY_TYPE";

//head examiner
export const SET_REVISED_MARK_REQ_DATA = "SET_REVISED_MARK_REQ_DATA";

// Snackbar
export const SET_SNACKBAR = "SET_SNACKBAR";
export const UNSET_SNACKBAR = "UNSET_SNACKBAR";

// SET_LOADING
export const SET_LOADING = "SET_LOADING";
export const UNSET_LOADING = "UNSET_LOADING";

// SET_NAVBAR_ITEMS
export const SET_NAVBAR_ITEMS = "SET_NAVBAR_ITEMS";
//MARK
export const SET_MARK_DATA = "SET_MARK_DATA";

//BS_DIST_TO_REG_CEN
export const SET_BS_DIST_TO_RC_DATA = "SET_BS_DIST_TO_RC_DATA";
export const SET_BS_DIST_TO_RC_SCHEMA = "SET_BS_DIST_TO_RC_SCHEMA";
export const UNSET_BS_DIST_TO_RC_SCHEMA = "UNSET_BS_DIST_TO_RC_SCHEMA";

//AS_COLL_FROM_RC
export const SET_NU_AS_COLL_FROM_REG_DATA = "SET_NU_AS_COLL_FROM_REG_DATA";
export const SET_NU_AS_COLL_FROM_REG_SCHEMA = "SET_NU_AS_COLL_FROM_REG_SCHEMA";
export const UNSET_NU_AS_COLL_FROM_REG_SCHEMA =
  "UNSET_NU_AS_COLL_FROM_REG_SCHEMA";
export const ADD_NU_AS_COLL_FROM_REG_DATA = "ADD_NU_AS_COLL_FROM_REG_DATA";

//LS_COLL_FROM_RC
export const SET_NU_LS_COLL_FROM_REG_DATA = "SET_NU_LS_COLL_FROM_REG_DATA";
export const SET_NU_LS_COLL_FROM_REG_SCHEMA = "SET_NU_LS_COLL_FROM_REG_SCHEMA";
export const UNSET_NU_LS_COLL_FROM_REG_SCHEMA =
  "UNSET_NU_LS_COLL_FROM_REG_SCHEMA";
export const ADD_NU_LS_COLL_FROM_REG_DATA = "ADD_NU_LS_COLL_FROM_REG_DATA";
//lsCollFromCen
export const SET_LS_COLL_FROM_CEN_DATA = "SET_LS_COLL_FROM_CEN_DATA";
export const SET_LS_COLL_FROM_CENTER_SCHEMA = "SET_LS_COLL_FROM_CENTER_SCHEMA";
export const UNSET_LS_COLL_FROM_CENTER_SCHEMA =
  "UNSET_LS_COLL_FROM_CENTER_SCHEMA";
export const ADD_LS_COLL_FROM_CENTER_DATA = "ADD_LS_COLL_FROM_CENTER_DATA";

//menu and submenu
export const SET_MENU_DATA = "SET_MENU_DATA";
export const ADD_MENU_DATA = "ADD_MENU_DATA";
export const SET_MENU_SCHEMA_DATA = "SET_MENU_SCHEMA_DATA";
export const UNSET_MENU_SCHEMA_DATA = "UNSET_MENU_SCHEMA_DATA";

export const SET_SUBMENU_DATA = "SET_SUBMENU_DATA";
export const ADD_SUBMENU_DATA = "ADD_SUBMENU_DATA";
export const SET_SUBMENU_SCHEMA_DATA = "SET_SUBMENU_SCHEMA_DATA";
export const UNSET_SUBMENU_SCHEMA_DATA = "UNSET_SUBMENU_SCHEMA_DATA";

//exam create
export const SET_EXAM_CREATE_DATA = "SET_EXAM_CREATE_DATA";
export const ADD_EXAM_CREATE_DATA = "ADD_EXAM_CREATE_DATA";
export const SET_EXAM_CREATE_SCHEMA_DATA = "SET_EXAM_CREATE_SCHEMA_DATA";
export const UNSET_EXAM_CREATE_SCHEMA_DATA = "UNSET_EXAM_CREATE_SCHEMA_DATA";

//exam standard Evaluation
export const SET_EXAMINER_STADARD_EVALUATION_DATA =
  "SET_EXAMINER_STADARD_EVALUATION_DATA";
export const ADD_EXAMINER_STANDARD_CREATE_DATA =
  "ADD_EXAMINER_STANDARD_CREATE_DATA";
export const SET_EXAMINER_EVALUATION_SCHEMA_DATA =
  "SET_EXAMINER_EVALUATION_SCHEMA_DATA";
export const UNSET_EXAMINER_EVALUATION_SCHEMA_DATA =
  "UNSET_EXAMINER_EVALUATION_SCHEMA_DATA";

//grace summary
export const SET_GRACE_MARK_SUMMARY_DATA = "SET_GRACE_MARK_SUMMARY_DATA";
export const ADD_GRACE_SUMMARY_DATA = "ADD_GRACE_SUMMARY_DATA";
export const SET_GRACE_SUMMARY_SCHEMA_DATA = "SET_GRACE_SUMMARY_SCHEMA_DATA";
export const UNSET_GRACE_SUMMARY_SCHEMA_DATA =
  "UNSET_GRACE_SUMMARY_SCHEMA_DATA";
export const SET_GRACE_PASS_STD_DATA = "SET_GRACE_PASS_STD_DATA";
export const SET_GRACE_PASS_STD_SCHEMA = "SET_GRACE_PASS_STD_SCHEMA";

//role
export const SET_ROLE_DATA = "SET_ROLE_DATA";
export const ADD_ROLE_FROM_DATA = "ADD_ROLE_FROM_DATA";
export const SET_ROLE_SCHEMA = "SET_ROLE_SCHEMA";
export const UNSET_ROLE_DATA_SCHEMA = "UNSET_ROLE_DATA_SCHEMA";

//role grant
export const SET_ROLE_GRANT_DATA = "SET_ROLE_GRANT_DATA";
export const SET_ROLE_GRANT_SCHEMA = "SET_ROLE_GRANT_SCHEMA";
export const UNSET_ROLE_GRANT_SCHEMA = "UNSET_ROLE_GRANT_SCHEMA";

//role details
export const SET_ROLE_DETAILS_DATA = "SET_ROLE_DETAILS_DATA";
export const ADD_ROLE_DETAILS_DATA = "ADD_ROLE_DETAILS_DATA";
export const SET_ROLE_DETAILS_SCHEMA = "SET_ROLE_DETAILS_SCHEMA";
export const UNSET_ROLE_DETAILS_DATA_SCHEMA = "UNSET_ROLE_DETAILS_DATA_SCHEMA";

// data log
export const SET_LOG_DATA = "SET_LOG_DATA";

export const SET_AUDIT_ACTIONS = "SET_AUDIT_ACTIONS";
export const SET_AUDIT_USERS = "SET_AUDIT_USERS";
export const SET_AUDIT_MODULES = "SET_AUDIT_MODULES";

//exam committee
export const SET_EXAM_COMMITTEE_DATA = "SET_EXAM_COMMITTEE_DATA";
export const SET_EXAM_COMMITTEE_SCHEMA = "SET_EXAM_COMMITTEE_SCHEMA";
export const UNSET_EXAM_COMMITTEE_SCHEMA = "UNSET_EXAM_COMMITTEE_SCHEMA";
export const ADD_EXAM_COMMITTEE_DATA = "ADD_EXAM_COMMITTEE_DATA";
//Exam Initiate
export const SET_EXAM_INITIATE_DATA = "SET_EXAM_INITIATE_DATA";
export const ADD_EXAM_INITIATE_DATA = "ADD_EXAM_INITIATE_DATA";
export const SET_EXAM_INITIATE_SCHEMA = "SET_EXAM_INITIATE_SCHEMA";
export const UNSET_EXAM_INITIATE_SCHEMA = "UNSET_EXAM_INITIATE_SCHEMA";

//user type
export const SET_USER_DATA = "SET_USER_DATA";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const SET_USER_SCHEMA = "SET_USER_SCHEMA";
export const UNSET_USER_SCHEMA = "UNSET_USER_SCHEMA";

export const SET_USER_TYPE_SCHEMA = "SET_USER_TYPE_SCHEMA";

// SET_NOTIFICATION
export const SET_NOTIFICATION = "SET_NOTIFICATION";

// FORMNOTIFY
export const SET_FORMNOTIFY = "SET_FORMNOTIFY";
export const UNSET_FORMNOTIFY = "UNSET_FORMNOTIFY";

//eXAM ROUTINE
export const SET_ROUTINE_DATA = "SET_ROUTINE_DATA";
export const ADD_ROUTINE_DATA = "ADD_ROUTINE_DATA";
export const SET_ROUTINE_SCHEMA_DATA = "SET_ROUTINE_SCHEMA_DATA";
export const UNSET_ROUTINE_SCHEMA_DATA = "UNSET_ROUTINE_SCHEMA_DATA";
export const SET_CLONE_SCHEMA_DATA = "SET_CLONE_SCHEMA_DATA";
export const SET_ROUTINE_DATE_DATA = "SET_ROUTINE_DATE_DATA";
export const SET_ROUTINE_CHANGE_NOTICE_DATA = "SET_ROUTINE_CHANGE_NOTICE_DATA";

//Form FillUp
export const SET_FORM_FILLUP_DATA = "SET_FORM_FILLUP_DATA";
export const UNSET_FORM_FILLUP_DATA = "UNSET_FORM_FILLUP_DATA";
export const SET_FORM_FILLUP_COLLEGEWISE_DATA =
  "SET_FORM_FILLUP_COLLEGEWISE_DATA";
export const UNSET_FORM_FILLUP_COLLEGEWISE_DATA =
  "UNSET_FORM_FILLUP_COLLEGEWISE_DATA";
export const SET_FORM_FILLUP_CURRENT_ITEM_DETAILS =
  "SET_FORM_FILLUP_CURRENT_ITEM_DETAILS";
export const SET_FORM_FILLUP_COLLEGEWISE_DATA_DETAILS =
  "SET_FORM_FILLUP_COLLEGEWISE_DATA_DETAILS";
export const UNSET_FORM_FILLUP_COLLEGEWISE_DATA_DETAILS =
  "UNSET_FORM_FILLUP_COLLEGEWISE_DATA_DETAILS";
export const SET_ROLL_GEN_SUMMARY_DATA = "SET_ROLL_GEN_SUMMARY_DATA";

export const SET_COLLEGEWISE_STD_COUNT_DATA = "SET_COLLEGEWISE_STD_COUNT_DATA";
export const UNSET_COLLEGEWISE_STD_COUNT_DATA = "UNSET_COLLEGEWISE_STD_COUNT_DATA";
export const SET_FF_PAPER_EXCHANGE_PAPER_CODES = "SET_FF_PAPER_EXCHANGE_PAPER_CODES";
export const UNSET_FF_PAPER_EXCHANGE_PAPER_CODES = "UNSET_FF_PAPER_EXCHANGE_PAPER_CODES";
export const SET_REPORTWISE = "SET_REPORTWISE";
export const UNSET_REPORTWISE = "UNSET_REPORTWISE";
export const SET_FORM_FILLUP_REPORT_HTML = "SET_FORM_FILLUP_REPORT_HTML";
export const UNSET_FORM_FILLUP_REPORT_HTML = "UNSET_FORM_FILLUP_REPORT_HTML";
//Center Allocation
export const SET_CENTER_ALLOCATION_DATA = "SET_CENTER_ALLOCATION_DATA";
export const UNSET_CENTER_ALLOCATION_DATA = "UNSET_CENTER_ALLOCATION_DATA";
export const SET_CENTER_ALLOCATION_SCHEMA = "SET_CENTER_ALLOCATION_SCHEMA";
export const UNSET_CENTER_ALLOCATION_SCHEMA = "UNSET_CENTER_ALLOCATION_SCHEMA";
export const ADD_CENTER_ALLOCATION_DATA = "ADD_CENTER_ALLOCATION_DATA";
export const SET_SCHEMA_FOR_CLONE = "SET_SCHEMA_FOR_CLONE";
export const SET_UNALLOCATED_CENTER_DATA = "SET_UNALLOCATED_CENTER_DATA";
export const SET_UNALLOCATED_CENTER_SCHEMA = "SET_UNALLOCATED_CENTER_SCHEMA";
export const UNSET_UNALLOCATED_CENTER_SCHEMA =
  "UNSET_UNALLOCATED_CENTER_SCHEMA";
export const SET_UNALLOCATED_COL_SUB_SUM_DATA =
  "SET_UNALLOCATED_COL_SUB_SUM_DATA";
export const SET_UNALLOCATED_COL_SUB_SUM_SCHEMA =
  "SET_UNALLOCATED_COL_SUB_SUM_SCHEMA";
export const SET_PV_CENTER_ALLOCATION_DATA = "SET_PV_CENTER_ALLOCATION_DATA";
export const SET_PV_CENTER_UNALLOC_COLL_DATA =
  "SET_PV_CENTER_UNALLOC_COLL_DATA";
export const SET_PV_COLL_DATA = "SET_PV_COLL_DATA";

//Question Requisition
export const SET_QUESTION_RECEIVE_DATA = "SET_QUESTION_RECEIVE_DATA";
export const SET_QUESTION_REQUISITION_DATA = "SET_QUESTION_REQUISITION_DATA";
export const ADD_QUESTION_REQUISITION_DATA = "ADD_QUESTION_REQUISITION_DATA";
export const SET_QUESTION_REQUISITION_SCHEMA =
  "SET_QUESTION_REQUISITION_SCHEMA";
export const UNSET_QUESTION_REQUISITION_SCHEMA =
  "UNSET_QUESTION_REQUISITION_SCHEMA";

//Admin Statistics
export const SET_TOTAL_ADMIN_STATUS_IN = "SET_TOTAL_ADMIN_STATUS_IN";

//examiner access
export const SET_EXAMINER_ACCESS_GROUP_DATA = "SET_EXAMINER_ACCESS_GROUP_DATA";
export const SET_EXAMINER_ACCESS_DATA = "SET_EXAMINER_ACCESS_DATA";
export const ADD_EXAMINER_ACCESS_DATA = "ADD_EXAMINER_ACCESS_DATA";
export const SET_EXAMINER_ACCESS_SCHEMA = "SET_EXAMINER_ACCESS_SCHEMA";
export const UNSET_EXAMINER_ACCESS_DATA_SCHEMA =
  "UNSET_EXAMINER_ACCESS_DATA_SCHEMA";

//invigilator access
export const SET_INVIGILATOR_ACCESS_GROUP_DATA =
  "SET_INVIGILATOR_ACCESS_GROUP_DATA";
export const SET_INVIGILATOR_ACCESS_DATA = "SET_INVIGILATOR_ACCESS_DATA";
export const ADD_INVIGILATOR_ACCESS_DATA = "ADD_INVIGILATOR_ACCESS_DATA";
export const SET_INVIGILATOR_ACCESS_SCHEMA = "SET_INVIGILATOR_ACCESS_SCHEMA";
export const UNSET_INVIGILATOR_ACCESS_DATA_SCHEMA =
  "UNSET_INVIGILATOR_ACCESS_DATA_SCHEMA";

//College list
export const SET_COLLEGE_LIST_DATA = "SET_COLLEGE_LIST_DATA";
export const GET_ALL_COLLEGE_LIST_DATA = "GET_ALL_COLLEGE_LIST_DATA";

//inCourse
export const SET_IN_COURSE_DATA = "SET_IN_COURSE_DATA";
export const SET_IN_COURSE_PV_WANTING_DATA = "SET_IN_COURSE_PV_WANTING_DATA";
export const SET_IN_COURSE_PV_SUB_DATA = "SET_IN_COURSE_PV_SUB_DATA";
export const SET_IN_COURSE_PV_PAP_DATA = "SET_IN_COURSE_PV_PAP_DATA";


// exam activities
export const SET_EXAM_ACTIVITIES_DATA = "SET_EXAM_ACTIVITIES_DATA";
export const SET_EXAM_RESULT_ACTIVITIES_DATA =
  "SET_EXAM_RESULT_ACTIVITIES_DATA";
export const SET_EXAM_RESULT_ACTIVITIES_SUMMARY_DATA =
  "SET_EXAM_RESULT_ACTIVITIES_SUMMARY_DATA";

// exam time line
export const SET_EXAM_TIMELINE_DATA = "SET_EXAM_TIMELINE_DATA";
export const SET_RESULT_TIMELINE_DATA = "SET_RESULT_TIMELINE_DATA";
// exam time line setup
export const SET_TIMELINE_SETUP_DATA = "SET_TIMELINE_SETUP_DATA";
export const SET_TIMELINE_SETUP_SCHEMA = "SET_TIMELINE_SETUP_SCHEMA";
export const UNSET_TIMELINE_SETUP_SCHEMA = "UNSET_TIMELINE_SETUP_SCHEMA";

//student list
export const SET_STUDENT_LIST_DATA = "SET_STUDENT_LIST_DATA";
export const SET_STUDENT_LIST_PAGINATION_INFO =
  "SET_STUDENT_LIST_PAGINATION_INFO";

export const SET_FINAL_EXAMINEE_LIST_DATA = "SET_FINAL_EXAMINEE_LIST_DATA";
export const SET_ADMIT_CARD_LIST_DATA = "SET_ADMIT_CARD_LIST_DATA";
export const UNSET_ADMIT_CARD_LIST_DATA = "UNSET_ADMIT_CARD_LIST_DATA";
export const SET_ADMIT_CARD_COLLEGEWISE_DATA =
  "SET_ADMIT_CARD_COLLEGEWISE_DATA";
export const GET_ADMIT_CARD_DATA_FOR_CURRECTION = "GET_ADMIT_CARD_DATA_FOR_CURRECTION";
export const GET_ADMIT_CARD_CURRECTIONED_DATA = "GET_ADMIT_CARD_CURRECTIONED_DATA";
export const SET_ADMIT_CARD_PEP_CORRECTIONED_DATA = "SET_ADMIT_CARD_PEP_CORRECTIONED_DATA";
export const SET_ADMIT_CARD_PEP_CORRECTIONED_DETAILS_DATA = "SET_ADMIT_CARD_PEP_CORRECTIONED_DETAILS_DATA";

// admit card current item
export const SET_ADMIT_CARD_CURRENT_ITEM_DETAILS =
  "SET_ADMIT_CARD_CURRENT_ITEM_DETAILS";
export const UNSET_ADMIT_CARD_CURRENT_ITEM_DETAILS =
  "UNSET_ADMIT_CARD_CURRENT_ITEM_DETAILS";
export const UNSET_ADMIT_CARD_BY_COL_DETAILS =
  "UNSET_ADMIT_CARD_BY_COL_DETAILS";

export const SET_ADMIT_GENNERATE_PROGRESS_DATA =
  "SET_ADMIT_GENNERATE_PROGRESS_DATA";

// admit count
export const SET_ADMIT_CARD_DATA_BY_CENTER = "SET_ADMIT_CARD_DATA_BY_CENTER";
export const SET_ADMIT_CARD_DATA_BY_COLLEGE = "SET_ADMIT_CARD_DATA_BY_COLLEGE";
export const SET_ADMIT_CARD_DATA_BY_SUBJECT = "SET_ADMIT_CARD_DATA_BY_SUBJECT";
export const UNSET_ADMIT_CARD_DATA_BY_SUBJECT = "UNSET_ADMIT_CARD_DATA_BY_SUBJECT";
export const SET_ADMIT_CARD_DATA_BY_PAPER = "SET_ADMIT_CARD_DATA_BY_PAPER";
export const SET_ADMIT_CARD_DATA_BY_INDIVIDUAL = "SET_ADMIT_CARD_DATA_BY_INDIVIDUAL";
export const UNSET_ADMIT_CARD_DATA_BY_INDIVIDUAL = "UNSET_ADMIT_CARD_DATA_BY_INDIVIDUAL";

//registration list
export const SET_REGISTRATION_LIST_DATA = "SET_REGISTRATION_LIST_DATA";

//pdf setup list
export const SET_PDF_SETUP_DATA = "SET_PDF_SETUP_DATA";

// Year
export const SET_ALL_YEARS = "SET_ALL_YEARS";
export const UN_SET_ALL_YEARS = "UN_SET_ALL_YEARS";

//statement
export const SET_COL_STM_DATA_SUB_WISE = "SET_COL_STM_DATA_SUB_WISE";
export const SET_QUES_STATEMENT_DATA = "SET_QUES_STATEMENT_DATA";
export const UNSET_QUES_STATEMENT_DATA = "UNSET_QUES_STATEMENT_DATA";
export const SET_SCRIPT_STATEMENT_DATA = "SET_SCRIPT_STATEMENT_DATA";
export const UNSET_SCRIPT_STATEMENT_DATA = "UNSET_SCRIPT_STATEMENT_DATA";
export const SET_SCRIPT_DISTRIBUTION_DATA = "SET_SCRIPT_DISTRIBUTION_DATA";
export const UNSET_SCRIPT_DISTRIBUTION_DATA = "UNSET_SCRIPT_DISTRIBUTION_DATA";
export const SET_TREASURY_STATEMENT_DATA = "SET_TREASURY_STATEMENT_DATA";

// logged in extra data
export const SET_LOGGEDIN_YEAR = "SET_LOGGEDIN_YEAR";
export const SET_LOGGEDIN_EXAM = "SET_LOGGEDIN_EXAM";
export const SET_LOGGEDIN_DEGREE = "SET_LOGGEDIN_DEGREE";
export const SET_EXAM_INITIATE_ID = "SET_EXAM_INITIATE_ID";
export const SET_LOGGEDIN_EXAM_TYPE = "SET_LOGGEDIN_EXAM_TYPE";
export const SET_ASSIGNED_EXAMS = "SET_ASSIGNED_EXAMS";
export const SET_LOGGEDIN_EXAM_NAME = "SET_LOGGEDIN_EXAM_NAME";

//question paper entry
export const SET_QUES_ENTRY_DATA = "SET_QUES_ENTRY_DATA";
export const ADD_QUES_ENTRY_DATA = "ADD_QUES_ENTRY_DATA";
export const SET_QUES_ENTRY_SCHEMA = "SET_QUES_ENTRY_SCHEMA";
export const UNSET_QUES_ENTRY_SCHEMA = "UNSET_QUES_ENTRY_SCHEMA";

//pdf rules
export const SET_PDF_RULES_DATA = "SET_PDF_RULES_DATA";

//expel rules
export const SET_EXPEL_DATA = "SET_EXPEL_DATA";
export const SET_EXPEL_DATA_SCHEMA = "SET_EXPEL_DATA_SCHEMA";
export const UNSET_EXPEL_DATA_SCHEMA = "UNSET_EXPEL_DATA_SCHEMA";

//certification
export const SET_TRANSCRIPT_DATA = "SET_TRANSCRIPT_DATA";

//result
export const SET_RESULT_DATA = "SET_RESULT_DATA";
export const SET_CONSOLIDATE_RESULT_DATA = "SET_CONSOLIDATE_RESULT_DATA";
export const SET_ALL_MARKS_DATA = "SET_ALL_MARKS_DATA";
export const SET_COLL_WISE_PASS_FAIL_DATA = "SET_COLL_WISE_PASS_FAIL_DATA";
export const SET_SUB_WISE_PASS_FAIL_DATA = "SET_SUB_WISE_PASS_FAIL_DATA";
export const SET_PAP_WISE_PASS_FAIL_DATA = "SET_PAP_WISE_PASS_FAIL_DATA";
export const SET_RESULT_DASHBOARD_DATA = "SET_RESULT_DASHBOARD_DATA";
export const SET_GRACE_CALCULATED_DATA = "SET_GRACE_CALCULATED_DATA";
export const SET_REULT_GENERATE_DATA = "SET_REULT_GENERATE_DATA"; // result
export const SET_RESULT_GENERATE_PROGRESS_DATA =
  "SET_RESULT_GENERATE_PROGRESS_DATA"; 
export const SET_RESULT_PROCESS_SUMMARY_DATA =
         "SET_RESULT_PROCESS_SUMMARY_DATA";
export const SET_RESULT_TYPE_DATA = "SET_RESULT_TYPE_DATA";
export const ADD_RESULT_TYPE_DATA = "ADD_RESULT_TYPE_DATA";
export const SET_RESULT_TYPE_SCHEMA = "SET_RESULT_TYPE_SCHEMA";
export const UNSET_RESULT_TYPE_SCHEMA = "UNSET_RESULT_TYPE_SCHEMA";
export const SET_RESULT_LOGIC_DATA = "SET_RESULT_LOGIC_DATA";
export const ADD_RESULT_LOGIC_DATA = "ADD_RESULT_LOGIC_DATA";
export const SET_RESULT_LOGIC_SCHEMA = "SET_RESULT_LOGIC_SCHEMA";
export const UNSET_RESULT_LOGIC_SCHEMA = "UNSET_RESULT_LOGIC_SCHEMA";
export const SET_E_H_TYPE_MATCH_GEN_DATA = "SET_E_H_TYPE_MATCH_GEN_DATA";
export const SET_E_H_TYPE_MATCH_STATUS_DATA = "SET_E_H_TYPE_MATCH_STATUS_DATA";
export const SET_RESULT_TABULATION_DATA = "SET_RESULT_TABULATION_DATA";
export const SET_TABULATION_RESULT_GEN_DATA = "SET_TABULATION_RESULT_GEN_DATA"; //generate
export const SET_TABULATION_RESULT_GEN_PROGRESS_DATA =
  "SET_TABULATION_RESULT_GEN_PROGRESS_DATA";
export const SET_STUDENT_EXAM_RECORD_DATA = "SET_STUDENT_EXAM_RECORD_DATA";


//regional-centerMap
export const SET_REGIONAL_CENTER_MAP_DATA = "SET_REGIONAL_CENTER_MAP_DATA";
//result statistics
export const SET_SUBJECT_WISE_RESULT_STATISTICS_DATA = "SET_SUBJECT_WISE_RESULT_STATISTICS_DATA";
//missing Script
export const SET_MISSING_SCRIPT_DATA = "SET_MISSING_SCRIPT_DATA";
export const ADD_MISSING_SCRIPT_DATA = "ADD_MISSING_SCRIPT_DATA";
export const SET_MISSING_SCRIPT_SCHEMA = "SET_MISSING_SCRIPT_SCHEMA";
export const UNSET_MISSING_SCRIPT_SCHEMA = "UNSET_MISSING_SCRIPT_SCHEMA";

// SET_ALL_SESSIONS
export const SET_ALL_SESSIONS = "SET_ALL_SESSIONS";

//rescrutiny
export const SET_RESCRUTINY_COUNT_DATA = "SET_RESCRUTINY_COUNT_DATA";
export const SET_RESCRUTINY_DETAILS_DATA = "SET_RESCRUTINY_DETAILS_DATA";
export const SET_RESCRUTINY_MONITORING_DATA = "SET_RESCRUTINY_MONITORING_DATA";
export const SET_RESCRUTINY_STD_DATA = "SET_RESCRUTINY_STD_DATA";
export const UNSET_RESCRUTINY_STD_DATA = "UNSET_RESCRUTINY_STD_DATA";
export const SET_RESCRUTINY_APPLICANT_DATA = "SET_RESCRUTINY_APPLICANT_DATA";
export const SET_RESCRUTINY_NOTICE_LIST_DATA =
  "SET_RESCRUTINY_NOTICE_LIST_DATA"; //notice
export const ADD_RESCRUTINY_NOTICE_DATA = "ADD_RESCRUTINY_NOTICE_DATA"; //notice
export const SET_RESCRUTINY_NOTICE_SCHEMA = "SET_RESCRUTINY_NOTICE_SCHEMA"; //notice
export const UNSET_RESCRUTINY_NOTICE_SCHEMA = "UNSET_RESCRUTINY_NOTICE_SCHEMA"; //notice
export const RESCRUTINY_DASHBOARD_SUM_DATA = "RESCRUTINY_DASHBOARD_SUM_DATA"; 

//amanuensis
export const SET_AMANUENSIS_DATA = "SET_AMANUENSIS_DATA";

//result published
export const SET_RESULT_PUBLISHED_NOTE_DATA = "SET_RESULT_PUBLISHED_NOTE_DATA";
export const UNSET_RESULT_PUBLISHED_NOTE_DATA =
  "UNSET_RESULT_PUBLISHED_NOTE_DATA";
export const SET_USER_TYPE_DATA = "SET_USER_TYPE_DATA";
//published activity status
export const SET_PUBLISHED_ACTIVITY_STATUS_DATA =
  "SET_PUBLISHED_ACTIVITY_STATUS_DATA";

export const SET_QUES_BY_SUB = "SET_QUES_BY_SUB";
export const UNSET_QUES_BY_SUB = "UNSET_QUES_BY_SUB";

//practical Candidate & wanting
export const SET_PRACTICAL_CANDI_DATA = "SET_PRACTICAL_CANDI_DATA";
export const SET_PRACTICAL_WANT_DATA = "SET_PRACTICAL_WANT_DATA";
export const UNSET_PRACTICAL_CANDI_DATA = "UNSET_PRACTICAL_CANDI_DATA";

// practical wanting list
//by center
export const SET_PRACTICAL_WANT_DATA_BY_CENTER =
  "SET_PRACTICAL_WANT_DATA_BY_CENTER";
export const UNSET_PRACTICAL_WANT_DATA_BY_CENTER =
  "UNSET_PRACTICAL_WANT_DATA_BY_CENTER";
export const SET_PRACTICAL_WANT_DATA_BY_CENTER_CURRENT =
  "SET_PRACTICAL_WANT_DATA_BY_CENTER_CURRENT";
//by college
export const SET_PRACTICAL_WANT_DATA_BY_COLLEGE =
  "SET_PRACTICAL_WANT_DATA_BY_COLLEGE";
export const UNSET_PRACTICAL_WANT_DATA_BY_COLLEGE =
  "UNSET_PRACTICAL_WANT_DATA_BY_COLLEGE";
//by Subject
export const SET_PRACTICAL_WANT_DATA_BY_SUBJECT =
  "SET_PRACTICAL_WANT_DATA_BY_SUBJECT";
export const UNSET_PRACTICAL_WANT_DATA_BY_SUBJECT =
  "UNSET_PRACTICAL_WANT_DATA_BY_SUBJECT";
export const SET_PRACTICAL_WANT_DATA_BY_SUBJECT_CURRENT =
  "SET_PRACTICAL_WANT_DATA_BY_SUBJECT_CURRENT";
export const SET_PRACTICAL_WANT_DATA_BY_COLLEGE_CURRENT =
  "SET_PRACTICAL_WANT_DATA_BY_COLLEGE_CURRENT";
//by paper
export const SET_PRACTICAL_WANT_DATA_BY_PAPER =
  "SET_PRACTICAL_WANT_DATA_BY_PAPER";
export const UNSET_PRACTICAL_WANT_DATA_BY_PAPER =
  "UNSET_PRACTICAL_WANT_DATA_BY_PAPER";
export const SET_PRACTICAL_WANT_DATA_BY_PAPER_CURRENT =
  "SET_PRACTICAL_WANT_DATA_BY_PAPER_CURRENT";
//by std
export const SET_PRACTICAL_WANT_DATA_BY_STD = "SET_PRACTICAL_WANT_DATA_BY_STD";
export const UNSET_PRACTICAL_WANT_DATA_BY_STD =
  "UNSET_PRACTICAL_WANT_DATA_BY_STD";
export const SET_PRACTICAL_WANT_DATA_BY_STD_CURRENT =
  "SET_PRACTICAL_WANT_DATA_BY_STD_CURRENT";

//viva Candidate & wanting
export const SET_VIVA_CANDI_DATA = "SET_VIVA_CANDI_DATA";
export const SET_VIVA_WANT_DATA = "SET_VIVA_WANT_DATA";
export const UNSET_VIVA_CANDI_DATA = "UNSET_VIVA_CANDI_DATA";

//Overall want summary
export const SET_OVERALL_WANT_DATA_BY_CENTER =
  "SET_OVERALL_WANT_DATA_BY_CENTER";
export const UNSET_OVERALL_WANT_DATA_BY_CENTER =
  "UNSET_OVERALL_WANT_DATA_BY_CENTER";
export const UNSET_OVERALL_WANT_DATA_BY_COLLEGE =
  "UNSET_OVERALL_WANT_DATA_BY_COLLEGE";
export const SET_OVERALL_WANT_DATA_BY_COLLEGE =
  "SET_OVERALL_WANT_DATA_BY_COLLEGE";
export const SET_OVERALL_WANT_DATA_BY_CENTER_CURRENT =
  "SET_OVERALL_WANT_DATA_BY_CENTER_CURRENT";
export const SET_OVERALL_WANT_DATA_BY_SUBJECT =
  "SET_OVERALL_WANT_DATA_BY_SUBJECT";
export const UNSET_OVERALL_WANT_DATA_BY_SUBJECT =
  "UNSET_OVERALL_WANT_DATA_BY_SUBJECT";
export const SET_OVERALL_WANT_DATA_BY_SUBJECT_CURRENT =
  "SET_OVERALL_WANT_DATA_BY_SUBJECT_CURRENT";
export const UNSET_OVERALL_WANT_DATA_BY_PAPER =
  "UNSET_OVERALL_WANT_DATA_BY_PAPER";
export const SET_OVERALL_WANT_DATA_BY_PAPER_CURRENT =
  "SET_OVERALL_WANT_DATA_BY_PAPER_CURRENT";
export const SET_OVERALL_WANT_DATA_BY_PAPER = "SET_OVERALL_WANT_DATA_BY_PAPER";
export const SET_OVERALL_WANT_DATA_BY_STD_CURRENT =
  "SET_OVERALL_WANT_DATA_BY_STD_CURRENT";

// practical wanting list
//by center
export const SET_VIVA_WANT_DATA_BY_CENTER = "SET_VIVA_WANT_DATA_BY_CENTER";
export const UNSET_VIVA_WANT_DATA_BY_CENTER = "UNSET_VIVA_WANT_DATA_BY_CENTER";
export const SET_VIVA_WANT_DATA_BY_CENTER_CURRENT =
  "SET_VIVA_WANT_DATA_BY_CENTER_CURRENT";
//by college
export const SET_VIVA_WANT_DATA_BY_COLLEGE = "SET_VIVA_WANT_DATA_BY_COLLEGE";
export const UNSET_VIVA_WANT_DATA_BY_COLLEGE =
  "UNSET_VIVA_WANT_DATA_BY_COLLEGE";
export const SET_VIVA_WANT_DATA_BY_COLLEGE_CURRENT =
  "SET_VIVA_WANT_DATA_BY_COLLEGE_CURRENT";
//by paper
export const SET_VIVA_WANT_DATA_BY_PAPER = "SET_VIVA_WANT_DATA_BY_PAPER";
export const UNSET_VIVA_WANT_DATA_BY_PAPER = "UNSET_VIVA_WANT_DATA_BY_PAPER";
export const SET_VIVA_WANT_DATA_BY_PAPER_CURRENT =
  "SET_VIVA_WANT_DATA_BY_PAPER_CURRENT";
//by subject
export const SET_VIVA_WANT_DATA_BY_SUBJECT = "SET_VIVA_WANT_DATA_BY_SUBJECT";
export const UNSET_VIVA_WANT_DATA_BY_SUBJECT =
  "UNSET_VIVA_WANT_DATA_BY_SUBJECT";
export const SET_VIVA_WANT_DATA_BY_SUBJECT_CURRENT =
  "SET_VIVA_WANT_DATA_BY_SUBJECT_CURRENT";
//by std
export const SET_VIVA_WANT_DATA_BY_STD = "SET_VIVA_WANT_DATA_BY_STD";
export const UNSET_VIVA_WANT_DATA_BY_STD = "UNSET_VIVA_WANT_DATA_BY_STD";
export const SET_VIVA_WANT_DATA_BY_STD_CURRENT =
  "SET_VIVA_WANT_DATA_BY_STD_CURRENT";

//cenetr summary
export const SET_SUMM_SUB_PAP_WISE_DATA = "SET_SUMM_SUB_PAP_WISE_DATA";
export const SET_SUMM_ROOM_INVI_WISE_DATA = "SET_SUMM_ROOM_INVI_WISE_DATA";
export const UNSET_SUMM_SUB_PAP_WISE_DATA = "UNSET_SUMM_SUB_PAP_WISE_DATA";
export const UNSET_SUMM_ROOM_INVI_WISE_DATA = "UNSET_SUMM_ROOM_INVI_WISE_DATA";
export const SET_ATT_SUMMARY_CENTER_WISE_DATA =
  "SET_ATT_SUMMARY_CENTER_WISE_DATA";

export const SET_MANUAL_ABSENT_ENTRY_DATA = "SET_MANUAL_ABSENT_ENTRY_DATA";
export const ADD_MANUAL_ABSENT_ENTRY_DATA = "ADD_MANUAL_ABSENT_ENTRY_DATA";
export const SET_MANUAL_ABSENT_ENTRY_SCHEMA = "SET_MANUAL_ABSENT_ENTRY_SCHEMA";
export const UNSET_MANUAL_ABSENT_ENTRY_SCHEMA =
  "UNSET_MANUAL_ABSENT_ENTRY_SCHEMA";

// SET_WITHHELD_REPORT_DATA
export const SET_WITHHELD_REPORT_DATA = "SET_WITHHELD_REPORT_DATA";

export const SET_ADMIT_CARD_SUMMARY_DATA = "SET_ADMIT_CARD_SUMMARY_DATA";

export const SET_EXAMINER_MONITORING_DATA = "SET_EXAMINER_MONITORING_DATA";
export const SET_EXAMINER_MONITORING_SUMM_DATA =
  "SET_EXAMINER_MONITORING_SUMM_DATA"; //summary

//overall
export const SET_MISMATCH_DATA = "SET_MISMATCH_DATA";
export const SET_OVERALL_WANTING_DATA = "SET_OVERALL_WANTING_DATA";
export const SET_ABSENT_REPO_WTHLD = "SET_ABSENT_REPO_WTHLD";
export const SET_ABSENT_REPO_WTHLD_CEN = "SET_ABSENT_REPO_WTHLD_CEN"; // used for center wise
export const SET_ABSENT_REPO_WTHLD_COLL = "SET_ABSENT_REPO_WTHLD_COLL";
export const SET_ABSENT_REPO_WTHLD_SUB = "SET_ABSENT_REPO_WTHLD_SUB";
export const SET_ABSENT_REPO_WTHLD_PAP = "SET_ABSENT_REPO_WTHLD_PAP";

export const SET_SUB_DATA_BY_PRACTICAL = "SET_SUB_DATA_BY_PRACTICAL";
export const SET_SUB_DATA_BY_VIVA = "SET_SUB_DATA_BY_VIVA";
export const SET_SUB_DATA_BY_PV = "SET_SUB_DATA_BY_PV";

export const SET_PAPER_DATA_BY_PRACTICAL = "SET_PAPER_DATA_BY_PRACTICAL";
export const SET_PAPER_DATA_BY_VIVA = "SET_PAPER_DATA_BY_VIVA";

export const SET_MARK_COUNT_BY_HE = "SET_MARK_COUNT_BY_HE";
export const SET_MARK_COUNT_BY_E = "SET_MARK_COUNT_BY_E";
export const UNSET_MARK_COUNT_BY_E = "UNSET_MARK_COUNT_BY_E";
export const SET_MARK_COUNT_BY_STD = "SET_MARK_COUNT_BY_STD";
export const UNSET_MARK_COUNT_BY_STD = "UNSET_MARK_COUNT_BY_STD";

export const SET_PRACTICAL_CENTER_DATA = "SET_PRACTICAL_CENTER_DATA";
export const SET_VIVA_CENTER_DATA = "SET_VIVA_CENTER_DATA";
export const SET_THEORY_CENTER_DATA = "SET_THEORY_CENTER_DATA";

//online Certificate verification
export const SET_ONLINE_CERTIFICATE_ADD_DATA =
  "SET_ONLINE_CERTIFICATE_ADD_DATA";
export const UNSET_ONLINE_CERTIFICATE_ADD_DATA =
  "UNSET_ONLINE_CERTIFICATE_ADD_DATA";
export const SET_ONLINE_CERTIFICATE_DATA = "SET_ONLINE_CERTIFICATE_DATA";
export const UNSET_ONLINE_CERTIFICATE_DATA = "UNSET_ONLINE_CERTIFICATE_DATA";
export const SET_PAID_ORGANIZATION_DATA = "SET_PAID_ORGANIZATION_DATA";
export const SET_PAID_ORGANIZATION_DETAILS_DATA =
  "SET_PAID_ORGANIZATION_DETAILS_DATA";
export const GET_ORG_CV_DATA = "GET_ORG_CV_DATA";

export const SET_BI_COL_BASIC_INFO = "SET_BI_COL_BASIC_INFO";
export const SET_BI_RESULT_DATA = "SET_BI_RESULT_DATA";

export const SET_RC_MAPPING_SUMMARY = "SET_RC_MAPPING_SUMMARY";
export const UNSET_RC_MAPPING_SUMMARY = "UNSET_RC_MAPPING_SUMMARY";
export const SET_RC_MAPPING_CENTER_LIST = "SET_RC_MAPPING_CENTER_LIST";
export const UNSET_RC_MAPPING_CENTER_LIST = "UNSET_RC_MAPPING_CENTER_LIST";

export const SET_RC_WISE_DIST_SUMMARY = "SET_RC_WISE_DIST_SUMMARY";
export const SET_RC_DIST_CENTER_LIST = "SET_RC_DIST_CENTER_LIST";
export const SET_CENTER_WISE_DIST_LIST = "SET_CENTER_WISE_DIST_LIST";
export const UNSET_RC_WISE_DIST_SUMMARY = "UNSET_RC_WISE_DIST_SUMMARY";

export const SET_USED_SCRIPT_DATA = "SET_USED_SCRIPT_DATA";

export const UNSET_USED_SCRIPT_DATA = "UNSET_USED_SCRIPT_DATA";
export const SET_AS_PAPER_DATA_AT_CENTER_RC = "SET_AS_PAPER_DATA_AT_CENTER_RC";
export const UNSET_AS_PAPER_DATA_AT_CENTER_RC =
  "UNSET_AS_PAPER_DATA_AT_CENTER_RC";

export const SET_AS_PAPER_DATA_AT_CENTER_RC_TODAYSCRIPT =
  "SET_AS_PAPER_DATA_AT_CENTER_RC_TODAYSCRIPT";
export const UNSET_AS_PAPER_DATA_AT_CENTER_RC_TODAYSCRIPT =
  "UNSET_AS_PAPER_DATA_AT_CENTER_RC_TODAYSCRIPT";

export const SET_AS_PAPER_DATA_AT_RC_NU = "SET_AS_PAPER_DATA_AT_RC_NU";
export const UNSET_AS_PAPER_DATA_AT_RC_NU = "UNSET_AS_PAPER_DATA_AT_RC_NU";

export const SET_AS_NU_RC_EXAMINER_DATA = "SET_AS_NU_RC_EXAMINER_DATA";
export const UNSET_AS_NU_RC_EXAMINER_DATA = "UNSET_AS_NU_RC_EXAMINER_DATA";

export const SET_AS_NU_RC_EXAMINER_DATA_SUMMARY =
  "SET_AS_NU_RC_EXAMINER_DATA_SUMMARY";

export const UNSET_CENTER_WISE_DIST_LIST = "UNSET_CENTER_WISE_DIST_LIST";

export const SET_QUES_DIST_CENTER_BY_PAPER = "SET_QUES_DIST_CENTER_BY_PAPER";
export const UNSET_QUES_DIST_CENTER_BY_PAPER =
  "UNSET_QUES_DIST_CENTER_BY_PAPER";

export const SET_CURRENT_CENTER_AS_ALLOC = "SET_CURRENT_CENTER_AS_ALLOC";
export const UNSET_CURRENT_CENTER_AS_ALLOC = "UNSET_CURRENT_CENTER_AS_ALLOC";

export const SET_CORRECT_CENTER_STATUS = "SET_CORRECT_CENTER_STATUS";
export const UNSET_CORRECT_CENTER_STATUS = "UNSET_CORRECT_CENTER_STATUS";

//ans reg receive from Nu
export const SET_AS_REG_RECV_FROM_NU = "SET_AS_REG_RECV_FROM_NU";
export const UNSET_AS_REG_RECV_FROM_NU = "UNSET_AS_REG_RECV_FROM_NU";
export const SET_AS_REG_RECV_FROM_NU_SUMM = "SET_AS_REG_RECV_FROM_NU_SUMM";

export const SET_AS_CHALLAN_NUMBER_LIST = "SET_AS_CHALLAN_NUMBER_LIST";

export const SET_AS_NU_RC_CHALLAN_DETAILS = "SET_AS_NU_RC_CHALLAN_DETAILS";
export const UNSET_AS_NU_RC_CHALLAN_DETAILS = "UNSET_AS_NU_RC_CHALLAN_DETAILS";

export const SET_ANS_EXAMINER_LIST = "SET_ANS_EXAMINER_LIST";

export const SET_DELIVERYOPTIONS_DATA = "SET_DELIVERYOPTIONS_DATA";
export const SET_DELIVERYOPTIONS_DATA_EW = "SET_DELIVERYOPTIONS_DATA_EW";

export const SET_CENTER_CHALLAN_LIST = "SET_CENTER_CHALLAN_LIST";

export const SET_CENTER_CHALLAN_DETAILS_LIST =
  "SET_CENTER_CHALLAN_DETAILS_LIST";

export const SET_ROUTINE_DATA_DATEWISE = "SET_ROUTINE_DATA_DATEWISE";

export const SET_BS_CHALLAN_LIST = "SET_BS_CHALLAN_LIST";
export const SET_BS_NUR_CHALLAN_LIST = "SET_BS_NUR_CHALLAN_LIST";

export const SET_BS_NUtoCenter_CHALLAN_LIST = "SET_BS_NUtoCenter_CHALLAN_LIST"

//===================== Bigm ==============================

//form fill-up
export const SET_FORM_FILL_UP_DEGREE_DATA = "SET_FORM_FILL_UP_DEGREE_DATA";
export const SET_FORM_FILL_UP_Exam_DATA = "SET_FORM_FILL_UP_Exam_DATA";

//Registration module
export const REG_STUDENT_DATA = "REG_STUDENT_DATA";
export const GET_STUDENT_INFO = "GET_STUDENT_INFO";
export const REG_STUDENT_SEARCH_PARAM = "REG_STUDENT_SEARCH_PARAM";
export const GET_REG_CORRECTION_DATA = "GET_REG_CORRECTION_DATA";

//college module
export const GET_COLLEGE_INFO_DATA = "GET_COLLEGE_INFO_DATA";
export const SET_COLLEGE_SCHEMA_DATA = "SET_COLLEGE_SCHEMA_DATA";
//college affiliation
export const GET_COLLEGE_AFFILIATION_DATA = "GET_COLLEGE_AFFILIATION_DATA";
export const GET_AFFILIATED_SUBJECT_DATA = "GET_AFFILIATED_SUBJECT_DATA";
export const SET_COURSE_SCHEMA_DATA = "SET_COURSE_SCHEMA_DATA";
// Syllabus and regulation module
export const GET_DEGREE_DATA = "GET_DEGREE_DATA";
export const SET_DEGREE_FORM_DATA = "SET_DEGREE_FORM_DATA";
export const GET_DEGREE_GROUP_DATA = "GET_DEGREE_GROUP_DATA";
export const SET_DEGREE_GROUP_FORM_DATA = "SET_DEGREE_GROUP_FORM_DATA";
export const GET_SUBJECT_DATA = "GET_SUBJECT_DATA";
export const SET_SUBJECT_FORM_DATA = "SET_SUBJECT_FORM_DATA";
export const GET_PAPER_DATA = "GET_PAPER_DATA";

// Exam Committee
export const GET_SCHEMA_DATA = "GET_SCHEMA_DATA";
export const SET_SCHEMA_FORM_DATA = "SET_SCHEMA_FORM_DATA";
export const GET_SCHEMA_GENDER_DATA = "GET_SCHEMA_GENDER_DATA";
export const GET_SCHEMA_ORGANIZATION_DATA = "GET_SCHEMA_ORGANIZATION_DATA";
export const GET_SCHEMA_DESIGNATION_DATA = "GET_SCHEMA_DESIGNATION_DATA";
export const GET_SCHEMA_PROF_DESIGNATION_DATA =
  "GET_SCHEMA_PROF_DESIGNATION_DATA";
export const GET_SCHEMA_REG_TYPE_DATA = "GET_SCHEMA_REG_TYPE_DATA";
export const GET_SCHEMA_REG_ORG_DATA = "GET_SCHEMA_REG_ORG_DATA";
export const GET_EXAM_COMMITTEE_SCHEMA_DATA = "GET_EXAM_COMMITTEE_SCHEMA_DATA";
export const SET_EXM_COMMITTEE_EDIT_DATA = "SET_EXM_COMMITTEE_EDIT_DATA";
export const GET_EXAM_COMMITTEE_MEMBER_DATA = "GET_EXAM_COMMITTEE_MEMBER_DATA";
export const GET_SUBJECT_WISE_MEMBER_DATA = "GET_SUBJECT_WISE_MEMBER_DATA";
export const GET_MEETING_DATA = "GET_MEETING_DATA";
export const SET_SCHEMA_EDIT_DATA = "SET_SCHEMA_EDIT_DATA";
export const GET_EC_WISE_MEMBER_DATA = "GET_EC_WISE_MEMBER_DATA";

//===== NU SERVICE ===
export const GET_CERTIFICATE_VERIFICATION_DATA =
  "GET_CERTIFICATE_VERIFICATION_DATA";
export const GET_CERTIFICATE_VERIFICATION_STUDENTS =
  "GET_CERTIFICATE_VERIFICATION_STUDENTS";
export const GET_ORGANIZATION_DATA = "GET_ORGANIZATION_DATA";

//Admin settings
export const COLLEGE_TYPES = "COLLEGE_TYPES";
export const COLLEGE_TYPES_FORM_DATA = "COLLEGE_TYPES_FORM_DATA";
export const MGT_TYPES = "MGT_TYPES";
export const MGT_TYPES_FORM_DATA = "MGT_TYPES_FORM_DATA";
export const STUDENT_TYPES = "STUDENT_TYPES";
export const STUDENT_TYPES_FORM_DATA = "STUDENT_TYPES_FORM_DATA";
export const EXAM_TYPES = "EXAM_TYPES";
export const EXAM_TYPES_FORM_DATA = "EXAM_TYPES_FORM_DATA";
export const PAPER_TYPES = "PAPER_TYPES";
export const PAPER_TYPE_FORM_DATA = "PAPER_TYPE_FORM_DATA";
export const PAPER_GROUP = "PAPER_GROUP";
export const UNSET_PAPER_GROUP = "UNSET_PAPER_GROUP";
export const PAPER_GROUP_FORM_DATA = "PAPER_GROUP_FORM_DATA";

// Exam Master ===================
export const EXAMINATION_MASTER = "EXAMINATION_MASTER";
export const EXAMINATION_MASTER_DETAILS = "EXAMINATION_MASTER_DETAILS";
export const GET_PROBABLE_SLOT_LIST = "GET_PROBABLE_SLOT_LIST";
export const UNSET_PROBABLE_SLOT_LIST = "UNSET_PROBABLE_SLOT_LIST";
// Masters Final Probable =========
export const SET_PROBABLE_SLOT_SCHEMA = "SET_PROBABLE_SLOT_SCHEMA";
export const GET_SLOT_AC_SESSION = "GET_SLOT_AC_SESSION";
export const GET_RESULT_TYPE = "GET_RESULT_TYPE";
export const GET_EXAM_ACTIVITY_STATUS = "GET_EXAM_ACTIVITY_STATUS";
export const GET_EXAM_YEAR = "GET_EXAM_YEAR";
export const GET_AC_SESSION = "GET_AC_SESSION";
export const SET_MASTERS_FORM_DATA = "SET_MASTERS_FORM_DATA";
export const SET_HONOURS_PART_ONE_FORM_DATA = "SET_HONOURS_PART_ONE_FORM_DATA";
export const SET_HONOURS_PART_TWO_FORM_DATA = "SET_HONOURS_PART_TWO_FORM_DATA";
export const SET_HONOURS_PART_THREE_FORM_DATA =
  "SET_HONOURS_PART_THREE_FORM_DATA";
//Bed professional probable
export const SET_BED_PROF_FIRST_SEMESTER_FORM_DATA =
  "SET_BED_PROF_FIRST_SEMESTER_FORM_DATA";
export const SET_BED_PROF_SECOND_SEMESTER_FORM_DATA =
  "SET_BED_PROF_SECOND_SEMESTER_FORM_DATA";
export const SET_BED_PROF_THIRD_SEMESTER_FORM_DATA =
  "SET_BED_PROF_THIRD_SEMESTER_FORM_DATA";
export const GET_FEE_HEADS = "GET_FEE_HEADS";
//  Form Fillup Details ===========
export const GET_FORM_FILLUP_INFO = "GET_FORM_FILLUP_INFO";
export const GET_FORM_FILLUP_DETAILS = "GET_FORM_FILLUP_DETAILS";
export const GET_FORM_FILLUP_SLOT_DETAILS = "GET_FORM_FILLUP_SLOT_DETAILS";
export const GET_FORM_FILLUP_STUDENT_DETAILS =
  "GET_FORM_FILLUP_STUDENT_DETAILS";
export const GET_PROBABLE_COLLEGE_WISE_DETAILS =
  "GET_PROBABLE_COLLEGE_WISE_DETAILS";
export const GET_PROBABLE_SUBJECT_WISE_DETAILS =
  "GET_PROBABLE_SUBJECT_WISE_DETAILS";
export const GET_PROBABLE_PAPER_WISE_DETAILS =
  "GET_PROBABLE_PAPER_WISE_DETAILS";
export const GET_EXAM_WISE_SUBJECTS = "GET_EXAM_WISE_SUBJECTS";
export const GET_EXAM_WISE_PAPERS = "GET_EXAM_WISE_PAPERS";
export const GET_EXAM_WISE_COLLEGES = "GET_EXAM_WISE_COLLEGES";
export const GET_EXAM_WISE_SESSION = "GET_EXAM_WISE_SESSION";
// Special Form Fillup
export const GET_PBL_STUDENT_INFO = "GET_PBL_STUDENT_INFO";
export const SET_ADMIN_FORM_FILLUP_SUBJECT = "SET_ADMIN_FORM_FILLUP_SUBJECT";
export const UN_SET_ADMIN_FORM_FILLUP_SUBJECT = "UN_SET_ADMIN_FORM_FILLUP_SUBJECT";
export const GET_SPECIAL_FORM_FILL_UP_DETAILS = "GET_SPECIAL_FORM_FILL_UP_DETAILS";
export const UNSET_SPECIAL_FORM_FILL_UP_DETAILS = "UNSET_SPECIAL_FORM_FILL_UP_DETAILS";
export const GET_SPECIAL_FORM_FILL_UP_LIST = "GET_SPECIAL_FORM_FILL_UP_LIST";
export const UNSET_SPECIAL_FORM_FILL_UP_LIST = "UNSET_SPECIAL_FORM_FILL_UP_LIST";
// utility helper =================
export const GET_ALL_COLLEGE = "GET_ALL_COLLEGE";
export const GET_ALL_DEGREE = "GET_ALL_DEGREE";
export const GET_ALL_DEGREE_GROUP = "GET_ALL_DEGREE_GROUP";
export const GET_DEGREE_WISE_DEGREE_GROUP = "GET_DEGREE_WISE_DEGREE_GROUP";
export const GET_ALL_SUBJECT = "GET_ALL_SUBJECT";
export const GET_ALL_GROUP_BY_SUBJECT = "GET_ALL_GROUP_BY_SUBJECT";
export const GET_ALL_PAPER = "GET_ALL_PAPER";
export const GET_ALL_EXAMS = "GET_ALL_EXAMS";
export const GET_PAPER_TYPE = "GET_PAPER_TYPE";
export const GET_PAPER_TYPE_INFO = "GET_PAPER_TYPE_INFO";
export const GET_EXAM_TYPE = "GET_EXAM_TYPE";
export const SET_MGT_TYPE = "SET_MGT_TYPE";
export const SET_COLLEGE_TYPE = "SET_COLLEGE_TYPE";
export const GET_EXAM_CANDIDATE_TYPE = "GET_EXAM_CANDIDATE_TYPE";
export const GET_STUDENT_TYPE = "GET_STUDENT_TYPE";
export const SET_RETURN_MESSAGE = "SET_RETURN_MESSAGE";
export const UNSET_RETURN_MESSAGE = "UNSET_RETURN_MESSAGE";
export const GET_COURSE_EXAM_WISE = "GET_COURSE_EXAM_WISE";
export const UN_SET_COURSE_EXAM_WISE = "UN_SET_COURSE_EXAM_WISE";

// Instruction Part
export const GET_NOTICE_LIST = "GET_NOTICE_LIST";
export const UN_SET_NOTICE_LIST = "UN_SET_NOTICE_LIST";
export const GET_SINGLE_NOTICE = "GET_SINGLE_NOTICE";
export const GET_RULES_LIST = "GET_RULES_LIST";
export const UN_SET_RULES_LIST = "UN_SET_RULES_LIST";
export const GET_SINGLE_RULES = "GET_SINGLE_RULES";
export const GET_FAQ_LIST = "GET_FAQ_LIST";
export const UN_SET_FAQ_LIST = "UN_SET_FAQ_LIST";
export const GET_SINGLE_FAQ = "GET_SINGLE_FAQ";
export const GET_INSTRUCTION_LIST = "GET_INSTRUCTION_LIST";
export const UN_SET_INSTRUCTION_LIST = "UN_SET_INSTRUCTION_LIST";
export const GET_SINGLE_INSTRUCTION = "GET_SINGLE_INSTRUCTION";


//Student Form Fill UP=====================
export const GET_STUDENT_FORM_FILL_UP_OPTIONS =
  "GET_STUDENT_FORM_FILL_UP_OPTIONS";
export const UNSET_STUDENT_FORM_FILL_UP_OPTIONS =
  "UNSET_STUDENT_FORM_FILL_UP_OPTIONS";
export const SET_STUDENT_FORM_FILL_UP_Subject =
  "SET_STUDENT_FORM_FILL_UP_Subject";
export const UNSET_STUDENT_FORM_FILL_UP_Subject =
  "UNSET_STUDENT_FORM_FILL_UP_Subject";
export const GET_PAIR_PAPER_DATA = "GET_PAIR_PAPER_DATA";
export const GET_STUDENT_FORM_FILL_UP_FEES = "GET_STUDENT_FORM_FILL_UP_FEES";
export const UNSET_STUDENT_FORM_FILL_UP_FEES = "UNSET_STUDENT_FORM_FILL_UP_FEES";
export const GET_Form_Fill_Up_Student_Confirmed_Details =
  "GET_Form_Fill_Up_Student_Confirmed_Details";
export const UNSET_Form_Fill_Up_Student_Confirmed_Details =
  "UNSET_Form_Fill_Up_Student_Confirmed_Details";
export const GET_FORM_FILL_UP_FEE_HISTORY = "GET_FORM_FILL_UP_FEE_HISTORY";
export const UNSET_FORM_FILL_UP_FEE_HISTORY = "UNSET_FORM_FILL_UP_FEE_HISTORY";
// Masters Final Probable =========
export const GEN_PROBABLE_MASTERS = "GEN_PROBABLE_MASTERS";
// export const GET_EXAM_YEAR = "GET_EXAM_YEAR";
// export const GET_AC_SESSION = "GET_AC_SESSION";
// export const SET_MASTERS_FORM_DATA = "SET_MASTERS_FORM_DATA";
// export const GET_FEE_HEADS = "GET_FEE_HEADS";
//  Form Fillup Details ===========
// export const GET_FORM_FILLUP_DETAILS = "GET_FORM_FILLUP_DETAILS";
export const GET_FORM_FILLUP_DETAILS_PARAMS = "GET_FORM_FILLUP_DETAILS_PARAMS";
//Student Form Fill UP=====================
// export const GET_STUDENT_FORM_FILL_UP_OPTIONS = "GET_STUDENT_FORM_FILL_UP_OPTIONS";
// export const UNSET_STUDENT_FORM_FILL_UP_OPTIONS = "UNSET_STUDENT_FORM_FILL_UP_OPTIONS";
// export const SET_STUDENT_FORM_FILL_UP_Subject = "SET_STUDENT_FORM_FILL_UP_Subject";
// export const UNSET_STUDENT_FORM_FILL_UP_Subject = "UNSET_STUDENT_FORM_FILL_UP_Subject";
//College Login================================
export const SET_COLLEGE_LOGGED_IN = "SET_COLLEGE_LOGGED_IN";
export const SET_COLLEGE_TOKEN = "SET_COLLEGE_TOKEN";
export const SET_AUTH_COLLEGE = "SET_AUTH_COLLEGE";
export const UNSET_AUTH_COLLEGE = "UNSET_AUTH_COLLEGE";
export const UNSET_COLLEGE_LOGGED_IN = "UNSET_COLLEGE_LOGGED_IN";
export const UNSET_COLLEGE_TOKEN = "UNSET_COLLEGE_TOKEN";
// ===============College User====================
export const SET_ALL_EXAM_MASTER = "SET_ALL_EXAM_MASTER";
export const UNSET_ALL_EXAM_MASTER = "UNSET_ALL_EXAM_MASTER";
export const SET_COLLEGE_USER_SCHEMA = "SET_COLLEGE_USER_SCHEMA";
export const UNSET_COLLEGE_USER_SCHEMA = "UNSET_COLLEGE_USER_SCHEMA";
export const SET_DEGREE_WISE_DEGREE_GROUP = "SET_DEGREE_WISE_DEGREE_GROUP";
export const UNSET_DEGREE_WISE_DEGREE_GROUP = "UNSET_DEGREE_WISE_DEGREE_GROUP";
export const SET_SUBJECT = "SET_SUBJECT";
export const UNSET_SUBJECT = "UNSET_SUBJECT";
export const SET_All_SUBJECT = "SET_All_SUBJECT";
export const UNSET_All_SUBJECT = "UNSET_All_SUBJECT";
export const SET_COLLEGE_USER = "SET_COLLEGE_USER";
export const UNSET_COLLEGE_USER = "UNSET_COLLEGE_USER";
export const UNSET_DATA_ENTRY_SCHEMA = "UNSET_DATA_ENTRY_SCHEMA";
export const UNSET_DATA_ENTRY_DETAILS = "UNSET_DATA_ENTRY_DETAILS";
export const SET_DATA_ENTRY_DETAILS = "SET_DATA_ENTRY_DETAILS";
export const SET_COLLEGE_USER_LOGGED_IN = "SET_COLLEGE_USER_LOGGED_IN";
export const UNSET_COLLEGE_USER_LOGGED_IN = "UNSET_COLLEGE_USER_LOGGED_IN";
export const SET_AUTH_COLLEGE_USER = "SET_AUTH_COLLEGE_USER";
export const UNSET_AUTH_COLLEGE_USER = "UNSET_AUTH_COLLEGE_USER";
export const SET_COLLEGE_USER_TOKEN = "SET_COLLEGE_USER_TOKEN";
export const UNSET_COLLEGE_USER_TOKEN = "UNSET_COLLEGE_USER_TOKEN";
export const SET_COLLEGE_USER_PROFILE = "SET_COLLEGE_USER_PROFILE";
export const UNSET_COLLEGE_USER_PROFILE = "UNSET_COLLEGE_USER_PROFILE";
export const INITIATE_PRIVILEGE = "INITIATE_PRIVILEGE";
export const SET_COLLEGE_USER_PRIVILEGE = "SET_COLLEGE_USER_PRIVILEGE";
export const UNSET_COLLEGE_USER_PRIVILEGE = "UNSET_COLLEGE_USER_PRIVILEGE";
export const SET_COLLEGE_USER_CURRENT_PRIVILEGE =
  "SET_COLLEGE_USER_CURRENT_PRIVILEGE";
export const UNSET_COLLEGE_USER_CURRENT_PRIVILEGE =
  "UNSET_COLLEGE_USER_CURRENT_PRIVILEGE";
export const SET_COLLEGE_USER_WISE_CANDIDATE =
  "SET_COLLEGE_USER_WISE_CANDIDATE";
export const UNSET_COLLEGE_USER_WISE_CANDIDATE =
  "UNSET_COLLEGE_USER_WISE_CANDIDATE";

export const GET_MASTER_EXAM = "GET_MASTER_EXAM";
export const GET_MASTER_EXAM_CONFIRM_DATA = "GET_MASTER_EXAM_CONFIRM_DATA";
export const GET_PROBABLE_LIST = "GET_PROBABLE_LIST";
export const GET_PROBABLE_DATA = "GET_PROBABLE_DATA";
export const UNSET_PROBABLE_DATA = "UNSET_PROBABLE_DATA";
export const SET_FEE_STATEMENT = "SET_FEE_STATEMENT";
export const UNSET_FEE_STATEMENT = "UNSET_FEE_STATEMENT";
export const SET_FEE_PAYMENT_SLIP = "SET_FEE_PAYMENT_SLIP";
export const UNSET_FEE_PAYMENT_SLIP = "UNSET_FEE_PAYMENT_SLIP";
export const SET_COLLEGE_USER_PAYSLIP_DETAILS = "SET_COLLEGE_USER_PAYSLIP_DETAILS";
export const UNSET_COLLEGE_USER_PAYSLIP_DETAILS = "UNSET_COLLEGE_USER_PAYSLIP_DETAILS";
export const GET_CU_ADMIT_CARD = "GET_CU_ADMIT_CARD";
export const UNSET_CU_ADMIT_CARD = "UNSET_CU_ADMIT_CARD";
export const GET_PAYMENT_INSTALLMENT = "GET_PAYMENT_INSTALLMENT";
// Result correciton
export const GET_RESULT_CORRECTION_LIST = "GET_RESULT_CORRECTION_LIST";
export const GET_STUDENT_RESULT = "GET_STUDENT_RESULT";
export const GET_APPLICATION_DETAIL_DATA = "GET_APPLICATION_DETAIL_DATA";
export const CU_GET_FAQ_LIST = "CU_GET_FAQ_LIST";
export const CU_UN_SET_FAQ_LIST = "CU_UN_SET_FAQ_LIST";
export const CU_FAQ_EXAM_LIST = "CU_FAQ_EXAM_LIST";
export const CU_SINGLE_FAQ = "CU_SINGLE_FAQ";
export const CU_GET_ALL_NOTICE = "CU_GET_ALL_NOTICE";
export const CU_ALL_FAQ_LIST = "CU_ALL_FAQ_LIST";
export const CU_GET_GEN_INS = "CU_GET_GEN_INS";


//=============== Student Panel =========================================
export const GET_RESCRUTINY_DATA = "GET_RESCRUTINY_DATA";
export const GET_RESCRUTINY_PAPER_DATA = "GET_RESCRUTINY_PAPER_DATA";
export const UNSET_RESCRUTINY_PAPER_DATA = "UNSET_RESCRUTINY_PAPER_DATA";
export const GET_RESCRUTINY_DETAIL_DATA = "GET_RESCRUTINY_DETAIL_DATA";
// Result correction/revised
export const GET_CORRECTION_EXAMLIST = "GET_CORRECTION_EXAMLIST";
export const GET_CORRECTION_PAPER_CODE = "GET_CORRECTION_PAPER_CODE";
export const GET_CORRECTION_APP_CATEGORY = "GET_CORRECTION_APP_CATEGORY";
export const GET_CORRECTION_APPLIED_LIST = "GET_CORRECTION_APPLIED_LIST";
export const GET_RESULT_CORRECTION_DETAIL = "GET_RESULT_CORRECTION_DETAIL";
// Faq part
export const INS_FAQ_EXAM_LIST = "INS_FAQ_EXAM_LIST";
export const STU_GET_ALL_NOTICE = "STU_GET_ALL_NOTICE";
export const STU_GET_FAQ_LIST = "STU_GET_FAQ_LIST";
export const STU_UN_SET_FAQ_LIST = "STU_UN_SET_FAQ_LIST";
export const STU_GET_GEN_INS = "STU_GET_GEN_INS";

//================== student result ======================================
export const GET_RESULT_DATA = "GET_RESULT_DATA";
export const UNSET_RESULT_DATA = "UNSET_RESULT_DATA";
export const GET_RESULT_DETAIL_DATA = "GET_RESULT_DETAIL_DATA";
export const UNSET_RESULT_DETAIL_DATA = "UNSET_RESULT_DETAIL_DATA";
export const GET_CONSOLIDATED_RESULT = "GET_CONSOLIDATED_RESULT";
export const GET_CONSOLIDATED_DETAIL_RESULT = "GET_CONSOLIDATED_DETAIL_RESULT";

export const GET_STD_RESULT_DATA = "GET_STD_RESULT_DATA";
export const UNSET_STD_RESULT_DATA = "UNSET_STD_RESULT_DATA";
export const GET_STD_RESULT_DETAIL_DATA = "GET_STD_RESULT_DETAIL_DATA";
export const UNSET_STD_RESULT_DETAIL_DATA = "UNSET_STD_RESULT_DETAIL_DATA";

export const GET_STUDENT_RESULT_DATA = "GET_STUDENT_RESULT_DATA";
export const UNSET_STUDENT_RESULT_DATA = "UNSET_STUDENT_RESULT_DATA";
export const GET_STUDENT_RESULT_DETAIL_DATA = "GET_STUDENT_RESULT_DETAIL_DATA";
export const UNSET_STUDENT_RESULT_DETAIL_DATA = "UNSET_STUDENT_RESULT_DETAIL_DATA";
// =====================Core Exam Fee Head============================
export const GET_EXAM_FEE_HEAD_LIST = "GET_EXAM_FEE_HEAD_LIST";
export const SET_EXAM_FEE_HEAD_SCHEMA = "SET_EXAM_FEE_HEAD_SCHEMA";
// ==============CollegePanel/Examination/Exam Master===================
export const SET_EXAM_MASTER_LIST = "SET_EXAM_MASTER_LIST";
export const UNSET_EXAM_MASTER_LIST = "UNSET_EXAM_MASTER_LIST";
export const SET_ALL_OPEN_EXAM_MASTER_LIST = "SET_ALL_OPEN_EXAM_MASTER_LIST";
export const UNSET_ALL_OPEN_EXAM_MASTER_LIST =
  "UNSET_ALL_OPEN_EXAM_MASTER_LIST";
// ==============CollegePanel/Core/DegreeGroup===================
export const SET_DEGREE_GROUP = "SET_DEGREE_GROUP";
export const UNSET_DEGREE_GROUP = "UNSET_DEGREE_GROUP";
export const SET_All_DEGREE_GROUP = "SET_All_DEGREE_GROUP";
export const UNSET_All_DEGREE_GROUP = "UNSET_All_DEGREE_GROUP";
// ==============CollegePanel/Form Fill Up===================
export const UNSET_PROBABLE_LIST_SCHEMA = "UNSET_PROBABLE_LIST_SCHEMA";
export const SET_PROBABLE_LIST = "SET_PROBABLE_LIST";
export const UNSET_PROBABLE_LIST = "UNSET_PROBABLE_LIST";
export const SET_FEE_SUMMARY = "SET_FEE_SUMMARY";
export const UNSET_FEE_SUMMARY = "UNSET_FEE_SUMMARY";
export const SET_PAYMENT_SLIP = "SET_PAYMENT_SLIP";
export const UNSET_PAYMENT_SLIP = "UNSET_PAYMENT_SLIP";
export const SET_PAYMENT_SLIP_DETAILS = "SET_PAYMENT_SLIP_DETAILS";
export const UNSET_PAYMENT_SLIP_DETAILS = "UNSET_PAYMENT_SLIP_DETAILS";
export const UNSET_TC_STD_RECORD = "UNSET_TC_STD_RECORD";
// =====================Organization Panel=====================
export const SET_ORGANIZATION_LOGGED_IN = "SET_ORGANIZATION_LOGGED_IN";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const SET_ORGANIZATION_TOKEN = "SET_ORGANIZATION_TOKEN";
export const UNSET_ORGANIZATION_LOGGED_IN = "UNSET_ORGANIZATION_LOGGED_IN";
export const UNSET_ORGANIZATION = "UNSET_ORGANIZATION";
export const UNSET_ORGANIZATION_TOKEN = "UNSET_ORGANIZATION_TOKEN";

export const SET_ALL_DEGREE = "SET_ALL_DEGREE";
export const SET_ALL_DEGREE_NAME = "SET_ALL_DEGREE_NAME";
export const UNSET_ALL_DEGREE = "UNSET_ALL_DEGREE";
export const SET_CV_STEP = "SET_CV_STEP";
export const UNSET_CV_STEP = "UNSET_CV_STEP";
export const UNSET_CV_STUDENT_SCHEMA = "UNSET_CV_STUDENT_SCHEMA";
export const RESET_SELECTED_FILE = "RESET_SELECTED_FILE";
export const SET_COLLEGE_EXAM_WISE_RESULT = "SET_COLLEGE_EXAM_WISE_RESULT";
export const UNSET_COLLEGE_EXAM_WISE_RESULT = "UNSET_COLLEGE_EXAM_WISE_RESULT";
export const SET_COLLEGE_EXAM_WISE_CONSOLIDATE_RESULT = "SET_COLLEGE_EXAM_WISE_CONSOLIDATE_RESULT";
export const UNSET_COLLEGE_EXAM_WISE_CONSOLIDATE_RESULT = "UNSET_COLLEGE_EXAM_WISE_CONSOLIDATE_RESULT";
export const SET_SUBJECT_WISE_PAPER_GROUP = "SET_SUBJECT_WISE_PAPER_GROUP";
export const UNSET_SUBJECT_WISE_PAPER_GROUP = "UNSET_SUBJECT_WISE_PAPER_GROUP";

export const SET_COL_EXM_WISE_RESULT = "SET_COL_EXM_WISE_RESULT";
export const UN_SET_COL_EXM_WISE_RESULT = "UN_SET_COL_EXM_WISE_RESULT";

export const SET_COLLEGE_EXM_WISE_RESULT = "SET_COLLEGE_EXM_WISE_RESULT";
export const UN_SET_COLLEGE_EXM_WISE_RESULT = "UN_SET_COLLEGE_EXM_WISE_RESULT";

// College HelpDesk
export const GET_COLLEGE_NOTICE = "GET_COLLEGE_NOTICE";
export const GET_COLLEGE_FAQ = "GET_COLLEGE_FAQ";
export const GET_COLLEGE_INSTRUCTION = "GET_COLLEGE_INSTRUCTION";

//dashboard
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";

//Exam Store
export const TOTAL_ITEM_LENGTH = 'TOTAL_ITEM_LENGTH';
export const GET_EXAM_STORE_ITEM = "GET_EXAM_STORE_ITEM";
export const GET_EDIT_EXAM_STORE_ITEM = "GET_EDIT_EXAM_STORE_ITEM";
export const GET_EXAM_STORE_RECEVIED_ITEM_LIST = "GET_EXAM_STORE_RECEVIED_ITEM_LIST";
export const GET_EXAM_STORE_ITEM_UNIT_LIST = 'GET_EXAM_STORE_ITEM_UNIT_LIST';
export const GET_EXAM_STORE_ADD_ITEM_MESSAGE = 'GET_EXAM_STORE_ADD_ITEM_MESSAGE';
export const POST_EXAM_STORE_EDIT_ITEM_MESSAGE = 'POST_EXAM_STORE_EDIT_ITEM_MESSAGE';
export const GET_ITEM_OF_EXAM_STORE_UNIT ='GET_ITEM_OF_EXAM_STORE_UNIT';
export const GER_ITEM_RECEIVED_MESSAGE ='GER_ITEM_RECEIVED_MESSAGE';
export const GET_EXAM_STORE_ISSUE_ITEM_LIST ='GET_EXAM_STORE_ISSUE_ITEM_LIST';
export const GET_ISSUE_ITEM_MESSAGE ='GET_ISSUE_ITEM_MESSAGE';
export const GET_ISSUED_ITEM_RETURN_MESSAGE ='GET_ISSUED_ITEM_RETURN_MESSAGE';
export const GET_FORM_FILLUP_SUMMARY_REPORT = 'GET_FORM_FILLUP_SUMMARY_REPORT';
export const GET_STUDENT_FORM_FILL_UP_DETAILS_BY_ID = 'GET_STUDENT_FORM_FILL_UP_DETAILS_BY_ID';
export const GET_TODAY_EXAM_SUMMARY = 'GET_TODAY_EXAM_SUMMARY';
export const GET_PEPAR_WISE_ABSENT_EXAMINEE_LIST = 'GET_PEPAR_WISE_ABSENT_EXAMINEE_LIST';
export const GET__PEPAR_WISE_ABSENT_EXAMINEE_LIST_PDF = 'GET__PEPAR_WISE_ABSENT_EXAMINEE_LIST_PDF';
export const GET_ADMIN_PEPAR_WISE_ABSENT_EXAMINEE_LIST = 'GET_ADMIN_PEPAR_WISE_ABSENT_EXAMINEE_LIST';
export const GET_DAILY_PRESENT_LIST_ROOM_WISE = "GET_DAILY_PRESENT_LIST_ROOM_WISE";
export const GET_DAILY_PRESENT_LIST_ROOM_WISE_PDF = "GET_DAILY_PRESENT_LIST_ROOM_WISE_PDF";
export const GET_SUBJECT_LIST_FOR_EXAMINER = "GET_SUBJECT_LIST_FOR_EXAMINER";
export const GET_SUBJECT_LIST_FOR_EX = "GET_SUBJECT_LIST_FOR_EX";
export const GET_COURCE_LIST_SUBJECT_WISE_FOR_EXAMINER = "GET_COURCE_LIST_SUBJECT_WISE_FOR_EXAMINER";

// =================Exam Routin at Admin Dashboard========================
export const GET_EXAM_ROUTIN_AT_ADMIN_DASHBOARD = 'GET_EXAM_ROUTIN_AT_ADMIN_DASHBOARD';

// =================Exam Routin at Admin Dashboard========================
export const GET_EXAM_STATUS_AT_EXAM_DASHBOARD =  'GET_EXAM_STATUS_AT_EXAM_DASHBOARD';

//====================Examiner Monitoring===================================
export const GET_EXAMINER_MONITORING = "GET_EXAMINER_MONITORING";
export const SET_PAYMENT_INSTALLMENT_FORM_SCHEMA =  'SET_PAYMENT_INSTALLMENT_FORM_SCHEMA';
export const UNSET_PAYMENT_INSTALLMENT_FORM_SCHEMA =  'UNSET_PAYMENT_INSTALLMENT_FORM_SCHEMA';
export const SET_PAYMENT_INSTALLMENT_DETAILS_SCHEMA =  'SET_PAYMENT_INSTALLMENT_DETAILS_SCHEMA';
export const UNSET_PAYMENT_INSTALLMENT_DETAILS_SCHEMA =  'UNSET_PAYMENT_INSTALLMENT_DETAILS_SCHEMA';
export const SET_PAYMENT_INSTALLMENT =  'SET_PAYMENT_INSTALLMENT';
export const UNSET_PAYMENT_INSTALLMENT =  'UNSET_PAYMENT_INSTALLMENT';

//====================Examiner Monitoring===================================
export const GET_DEGREE_GROUP_DATA_LIST = "GET_DEGREE_GROUP_DATA_LIST";
export const GET_SUBJECT_DATA_LIST = "GET_SUBJECT_DATA_LIST";
export const GET_DEPARTMENT_USER_SUBJECT_DATA = "GET_DEPARTMENT_USER_SUBJECT_DATA";
export const GET_PV_MARKS_LIST = "GET_PV_MARKS_LIST";
export const GET_HOME_NOTICE = "GET_HOME_NOTICE";

//====================Absent Entry===================================
export const GET_MANUAL_ABSENT_LIST  = "GET_MANUAL_ABSENT_LIST";
export const GET_EXAMINEE_FOR_ABSENT = "GET_EXAMINEE_FOR_ABSENT";

//====================Attendance Wanting List===================================
export const GET_DAILY_ATTENDANCE_WANTING_LIST  = "GET_DAILY_ATTENDANCE_WANTING_LIST";
// =================bulkSms========================
export const GET_EMAIL_SMS_RECIPENTS = 'GET_EMAIL_SMS_RECIPENTS';

// =================FileSend========================
export const GET_ATTACHMENTS_DATA = "GET_ATTACHMENTS_DATA";
export const GET_APP_ACCESS_INV_FOR_MONITORING = "GET_APP_ACCESS_INV_FOR_MONITORING";
export const GET_DAILY_PRESENT_ROOM_WISE_SUMMARY = "GET_DAILY_PRESENT_ROOM_WISE_SUMMARY";
export const ROOM_WIS_ABSENT_LIST = "ROOM_WIS_ABSENT_LIST";
export const GET_CENTER_LIST_FOR_ATT_REPORT = "GET_CENTER_LIST_FOR_ATT_REPORT";
export const GET_ROUTIN_DATE_LIST_FOR_ATT_REPORT = "GET_ROUTIN_DATE_LIST_FOR_ATT_REPORT";

// =================cmtPrint========================
export const GET_CMT_REGISTER_DATA = "GET_CMT_REGISTER_DATA";
export const GET_CMT_REGISTER_DATA_GENERATE = "GET_CMT_REGISTER_DATA_GENERATE";

export const GET_CMT_REGISTER_DATA_TRANSCRIPT = "GET_CMT_REGISTER_DATA_TRANSCRIPT";
export const GET_CMT_REGISTER_DATA_TRANSCRIPT_LIST = "GET_CMT_REGISTER_DATA_TRANSCRIPT_LIST";
export const GET_CMT_REGISTER_DATA_TRANSCRIPT_LIST_SL = "GET_CMT_REGISTER_DATA_TRANSCRIPT_LIST_SL";

export const GET_CMT_REGISTER_DATA_CERTIFICATE = "GET_CMT_REGISTER_DATA_CERTIFICATE";
export const GET_CMT_REGISTER_DATA_CERTIFICATE_LIST = "GET_CMT_REGISTER_DATA_CERTIFICATE_LIST";
export const GET_CMT_REGISTER_DATA_CERTIFICATE_LIST_SL = "GET_CMT_REGISTER_DATA_CERTIFICATE_LIST_SL";
export const GET_CMT_REGISTER_DATA_CERTIFICATE_LIST_REG = "GET_CMT_REGISTER_DATA_CERTIFICATE_LIST_REG";

export const GET_CMT_REGISTER_DATA_MARKSHEET = "GET_CMT_REGISTER_DATA_MARKSHEET";
export const GET_CMT_REGISTER_DATA_MARKSHEET_LIST = "GET_CMT_REGISTER_DATA_MARKSHEET_LIST";
export const GET_CMT_REGISTER_DATA_MARKSHEET_LIST_SL = "GET_CMT_REGISTER_DATA_MARKSHEET_LIST_SL";

export const SCRUTINIZER_DATA_LIST = "SCRUTINIZER_DATA_LIST";
export const SCRUTINIZER_DATA_LIST_EDIT = "SCRUTINIZER_DATA_LIST_EDIT";
export const DESIGNATION_LIST_DATA = "DESIGNATION_LIST_DATA";
export const CENTER_CHANGE_DATA = "CENTER_CHANGE_DATA";


// =================center change========================
export const GET_CENTER_CHANGE_EXAMINEE_INFO = "GET_CENTER_CHANGE_EXAMINEE_INFO";
export const GET_CENTER_CHANGE_EXAMINEE_LIST = "GET_CENTER_CHANGE_EXAMINEE_LIST";

// =================Examiner paper change========================
export const GET_HEAD_EXAMINER_WISE_EXAMINER_DATA = "GET_HEAD_EXAMINER_WISE_EXAMINER_DATA";

// =================student summary (ff)========================
export const GET_STUDENT_SUMMERY_DATA = "GET_STUDENT_SUMMERY_DATA";
// =================student ff data(ff payment)========================
export const GET_STUDENT_PAYMENT_SUMMERY_DATA = "GET_STUDENT_PAYMENT_SUMMERY_DATA";