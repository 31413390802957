import Axios from 'axios'
import {ExamStoreItemUnitList} from '../../api'
import {GET_ITEM_OF_EXAM_STORE_UNIT} from '../../mutation-types'

const state={
    unitListTada:[],
};

const getters={
    getExamStoreUnitList(state){
        return state.unitListTada;
      },
    
};

const mutations={
    [GET_ITEM_OF_EXAM_STORE_UNIT](state, response){
        state.unitListTada = response;
      },
};

const actions={
  fetchExamStoreUnit({ commit }, searchParam){
      return Axios.get(ExamStoreItemUnitList)
      .then(function(response){
        var unitlists = response.data.data;
        commit("GET_ITEM_OF_EXAM_STORE_UNIT",unitlists)
        return response.data.message
      })
  },

  postExamStoreUnit({ commit }, searchParam){
    return  Axios.post(ExamStoreItemUnitList,searchParam)
  },
};



export default {
    state,
    getters,
    actions,
    mutations
  };