import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=30d3f132&scoped=true&"
var script = {}
import style0 from "@/assets/css/bootstrap.min.css?vue&type=style&index=0&id=30d3f132&prod&scoped=true&lang=css&"
import style1 from "@/assets/css/font-awesome.min.css?vue&type=style&index=1&id=30d3f132&prod&scoped=true&lang=css&"
import style2 from "@/assets/css/animate.css?vue&type=style&index=2&id=30d3f132&prod&scoped=true&lang=css&"
import style3 from "@/assets/css/rsmenu-main.css?vue&type=style&index=3&id=30d3f132&prod&scoped=true&lang=css&"
import style4 from "@/assets/css/style.css?vue&type=style&index=4&id=30d3f132&prod&scoped=true&lang=css&"
import style5 from "@/assets/css/responsive.css?vue&type=style&index=5&id=30d3f132&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30d3f132",
  null
  
)

export default component.exports