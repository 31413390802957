/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Axios from "axios";
import {
  GET_STD_RESULT_DATA,
  UNSET_STD_RESULT_DATA,
  GET_STD_RESULT_DETAIL_DATA,
  UNSET_STD_RESULT_DETAIL_DATA,
  SET_COL_EXM_WISE_RESULT,
  UN_SET_COL_EXM_WISE_RESULT
} from "../../mutation-types";

import {
  getStdResultList,
  getStdResultDetail,
  downloadStdResultDetail,
  getColExmWiseResult
} from "../../api";
import { saveMsg, updateMsg } from "../../erpUtil";
import { failedMsg } from "../../erpUtil";

const state = {
  schema: [],
  stdResultList: [],
  resultDetailList: [],
  colExmWiseResults: []
};

const getters = {
fetchStdResultList(state) {
    return state.stdResultList;
  },
  getResultDetails(state) {
    return state.resultDetailList;
  },
  colExamWiseResults(state) {
    return state.colExmWiseResults;
  },
};

const mutations = {
  [GET_STD_RESULT_DATA](state, result) {
    state.stdResultList = result;
  },
  [UNSET_STD_RESULT_DATA](state) {
    state.stdResultList = [];
  },
  [GET_STD_RESULT_DETAIL_DATA](state, result) {
    state.resultDetailList = result;
  },
  [UNSET_STD_RESULT_DETAIL_DATA](state) {
    state.resultDetailList = [];
  },
  [SET_COL_EXM_WISE_RESULT](state, data) {
    state.colExmWiseResults = data;
  },
  [UN_SET_COL_EXM_WISE_RESULT](state) {
    state.colExmWiseResults = [];
  },
};

const actions = {
  fetchStdResultList({ commit }) {
    commit("UNSET_STD_RESULT_DATA");
    return new Promise(function (resolve, reject) {
      commit("SET_LOADING", { color: "primary" });
      Axios.get(getStdResultList)
        .then(function (response) {
          commit("UNSET_LOADING");
          let result = response.data.data;
          commit("GET_STD_RESULT_DATA", result);
          resolve(result);
        })
        .catch(function (error) {
          commit("UNSET_LOADING");
          console.log(error);
          reject();
        });
    });
  },

  fetchStdResultDetailList({ commit }, resultId) {
    commit("SET_LOADING", { color: "primary" });
    let results = { result_id: resultId };
    commit("UNSET_STD_RESULT_DETAIL_DATA");
    return new Promise(function (resolve, reject) {
      commit("SET_LOADING", { color: "primary" });
      Axios.get(getStdResultDetail, { params: results })
        .then(function (response) {
          commit("UNSET_LOADING");
          let result = response.data;
          commit("GET_RESULT_DETAIL_DATA", result);
          resolve(result);
        })
        .catch(function (error) {
          commit("UNSET_LOADING");
          console.log(error);
          reject();
        });
    });
  },

  downloadStdResultDetails({ commit, state }, data) {
    commit("SET_LOADING", {
      color: "primary",
    });
    let url = `${downloadStdResultDetail}`;
    return new Promise((resolve, reject) => {
      Axios.get(url, data)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data.params.file_name+".pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {})
        .then((always) => {
          commit("UNSET_LOADING");
        });
    });
  },

  // exportResultDetailList({ commit, state }, schema) {
  //   const search = schema;
  //   return new Promise(function (resolve, reject) {
  //     commit("SET_LOADING", { color: "primary" });
  //     Axios({
  //       url: getResultDetail,
  //       method: "GET",
  //       responseType: "arraybuffer",
  //       params: search,
  //     })
  //       .then((response) => {
  //         commit("UNSET_LOADING"); // loading Closing

  //         const fileURL = window.URL.createObjectURL(new Blob([response.data]));
  //         const fileLink = document.createElement("a");
  //         fileLink.href = fileURL;
  //         fileLink.setAttribute("download", "result_" + Date.now() + ".pdf");
  //         document.body.appendChild(fileLink);
  //         fileLink.click();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   });
  // },

  colExamWiseResults({ commit, state }, data) {
    commit("SET_LOADING", {
      color: "primary"
    });
    return new Promise((resolve, reject) => {
      commit("UN_SET_COL_EXM_WISE_RESULT");
      Axios.get(getColExmWiseResult, data)
        .then(response => {
          var result = response.data.data;
          commit("SET_COL_EXM_WISE_RESULT", result);
          resolve(response.data);
        })
        .catch(error => {
          commit("UN_SET_COL_EXM_WISE_RESULT");
          reject();
        })
        .then(always => {
          commit("UNSET_LOADING");
        });
    });
  },

  colAndExamWiseResultDownload({ commit, state }, data) {
    commit("SET_LOADING", {
      color: "primary"
    });
    return new Promise((resolve, reject) => {
      Axios.get(getColExmWiseResult, data)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "exam_wise_result.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {})
        .then(always => {
          commit("UNSET_LOADING");
        });
    });
  },

 
};

export default {
  state,
  getters,
  actions,
  mutations,
};
