<template>
  <div>
    <Header :fromHome="true"></Header>

    <!--Full width header End-->

    <!-- Main content Start -->
    <div class="main-content">
      <!-- Banner Section Start -->
      <div id="rs-banner" class="rs-banner style6">
        <div class="container">
          <div class="banner-content text-center">
            <h1 class="banner-title white-color"> NU Examination Management System </h1>
          </div>
        </div>
      </div>
      <!-- Banner Section End -->

      <div id="rs-about" class="rs-about style6 pt-10 pb-80 bg4">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-3">
                  <div
                    class="nav flex-column nav-pills"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <router-link to="/login" class="nav-link active navigation__list" aria-selected="true">NU Admin Login</router-link>
                    <router-link to="/login" class="nav-link navigation__list" aria-selected="false">NU User login</router-link>
                    <router-link to="/student-login" class="nav-link navigation__list" aria-selected="false">Student login</router-link>
                    <router-link to="/c-login" class="nav-link navigation__list" aria-selected="false">College login (Admin)</router-link>
                    <router-link to="/cu-login" class="nav-link navigation__list" aria-selected="false">College User login (Department)</router-link>
                    <router-link to="/login" class="nav-link navigation__list" aria-selected="false">Examiner/Head-Examiner Web login</router-link>
                    <router-link to="/login" class="nav-link navigation__list" aria-selected="false">Invigilator Web login</router-link>
                    <router-link to="/login" class="nav-link navigation__list" aria-selected="false">Center/Regional-center login</router-link>
                    <router-link to="/login" class="nav-link navigation__list" aria-selected="false">Center Department User login</router-link>
                    <router-link to="/org-login" class="nav-link navigation__list" aria-selected="false">Organization login</router-link>
                    
                  </div>
                </div>
                <div class="col-lg-9">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div class="information">
                        <!-- <p class="">
                          Development and implementation of Examination
                          Management System (EMS) for National University
                        </p> -->
                        <h2>Introduction:</h2>

                        <p>
                          The broad objective of the envisaged System is to automate the entire process of the Examination Division to provide better services in a paperless environment to serve the Students and Staffs of the University. This system will speed up the information flow among different sectors/sections of Examination Division for better Transparency, reliability and timely execution of the work Process. By this software users can share and get the information optimally in a better way for efficient decision- making with increased accountability. For the EMS it will be better and timely addressing of the grievances of the Students and Colleges. This will be easy to integrate with the existing Enterprise Resource Planning (ERP) and to make it compatible with Mobile Environment.
                        </p>
                        <p>
                          EMS is introducing the state of the art data logging for all event points and maintain intelligent system for assisting NU for the overall process beyond answer sheet tracking and error manageability. To make the process real time at site and to automate the script distribution to reduce current logistics and resources pressure this software will be very helpful. Ems will minimize data loss in legitimate work both by student and examiner. By using this EMS software, it will reduce the result processing time to a minimum level. Ensuring secure data transfer at all points of the EMS with proper encryption techniques will be accurate. Implementation of the result approval and correction system with highest level of security will be possible with this system. 
                        </p>
                        <p>
                          The purpose of introducing the software is to integrate the current payment system and also ensuring options for future charging mechanism and payment environment. Identify the processes running in manual and breakdown those processes into sub- processes in order to optimize the processing times. It will be very easy to identify the stakeholders, tasks and roles and bridging the stakeholders through the product clearly by using this software.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="shape-animate">
            <div class="transparent left">
              <img
                :src="require('@/assets/images/about/home6/line/1.png')"
                alt="images"
              />
            </div>
            <div class="transparent right">
              <img
                :src="require('@/assets/images/about/home6/line/2.png')"
                alt="images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Main content End -->
    <Footer></Footer>
  </div>
</template>

<script>
// import carousel from "@/components/utils/carousel.vue";
import Footer from "@/views/util/footer.vue";
import Header from "@/views/util/header.vue";
export default {
  components: {
    // carousel
    Footer,
    Header,
  },
  mounted() {
    var date =new Date().toLocaleString()
    console.log("current date and time: ",date);
  },
};
</script>

<style scoped src="@/assets/css/bootstrap.min.css"></style>
<style scoped src="@/assets/css/font-awesome.min.css"></style>
<style scoped src="@/assets/css/animate.css"></style>
<style scoped src="@/assets/css/rsmenu-main.css"></style>
<style scoped src="@/assets/css/style.css"></style>
<style scoped src="@/assets/css/responsive.css"></style>
